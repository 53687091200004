import { Drug, UserOrder, UserOrderDetailDTO } from "../types";

export class ProfileModel {
  age: any = "";
  app_version: any = "";
  auto_generated: any = "";
  avatar: null | string = "";
  branch_id: any = "";
  branch_url: any = "";
  cdmp_linkage: any = "";
  city_id = "";
  city_lat: any = "";
  city_lon: any = "";
  city_name = "";
  country_code: any = "";
  country_id: any = "";
  country_name: any = "";
  currency: any = "";
  date_birth: any = "";
  date_of_the_birth = "";
  email = "";
  experience_years: any = "";
  first_name = "";
  fr_unique_id: any = "";
  guest_user: any = "";
  id: any = "";
  id_number: any = "";
  insurance_companies: any = [];
  insurance_company: any = "";
  is_dob_confirmed: any = "";
  last_name = "";
  lat: any = "-";
  latitude: any = "";
  lon: any = "";
  longitude: any = "";
  name_prefix = "";
  online: any = "";
  os_type: any = "";
  phone_code = "";
  phone_number = "";
  photo_id_number_backend: any = null;
  photo_id_number_frontend: any = null;
  reception_phone_numbers: any = [];
  role_id: any = "";
  role_name: any = "";
  sex = "";
  user_insurance_companies: any = "";
  user_status: any = "";
  was_registered: any = "";
}

export class UserOrderModal {
  offset: string = "1";
  limit: string = "10";
  count: number = 0;
  search: string = "";
  toDate: string = "";
  fromDate: string = "";
  body: UserOrder[] = [] as UserOrder[];
}

export class UserOrderDetailModal implements UserOrderDetailDTO {
  order_id: string = "";
  claim_id: null | string = "";
  self_collect: string = "";
  user_id: string = "";
  delivery_men_id: null | string = "";
  image: null | string = "";
  images: any = [];
  status_id: string = "";
  status_name: string = "";
  country_code: string = "";
  paid_prescription: string = "";
  pai_number: string = "";
  partner_id: null | string = "";
  reason_id: string = "";
  chronic: string = "";
  comment: string = "";
  address: string = "";
  address2: string = "";
  landmark: string = "";
  amount_prescription: null | string = "";
  transaction_id: string = "";
  pay_type_id: string = "";
  pay_transaction_status: null | string = "";
  delivery_amount: string = "";
  delivery_paid_by_insurance: string = "";
  system_percent: string = "";
  system_fixed_fee: string = "";
  pay_transaction_id: null | string = "";
  pay_type_name: null | string = "";
  create_date: string = "";
  hospital_id: null | string = "";
  branch_id: null | string = "";
  hospital_name: null | string = "";
  hospital_physical_address: null | string = "";
  hospital_phone: null | string = "";
  hospital_avatar: null | string = "";
  doctor_id: null | string = "";
  doctor_phone: null | string = "";
  is_doctor_order: string = "";
  doctor_avatar: null | string = "";
  office_phone_number: string = "";
  icd10_list: any[] = [];
  icd10_code: null | string = "";
  icd10_code_description: null | string = "";
  can_reorder: string = "";
  from_hospital_portal: number = 0;
  delivery_men_name: string = "";
  partly_win: number = 0;
  customer_latitude: string = "";
  customer_longitude: string = "";
  saving_percentage: number = 0;
  l_saving: number = 0;
  i_saving: number = 0;
  c_saving: number = 0;
  total_price: string = "";
  total_price_drugs: string = "";
  price_with_sys_percent: number = 0;
  count_pharm: number = 0;
  delivery_cost: string = "";
  drugs: Drug[] = [];
  config_delivery_cost: number = 0;
  config_delivery_paid_by_insurance: number = 0;
  reminder_created: number = 0;
  insurance_allowed_to_show_savings: number = 0;
}
