import axios from "axios";
import { getPhoneId, getWebsiteCountryCodeFromLS } from "../../utility/util";
import { baseUrl, urlEndpoints } from "../../api/endpoints";

export class LoginService {
  // to get OTP for verification
  async getOtp(phone_number: string, phone_code: string, countryCode: string) {
    const phone_id = getPhoneId();
    try {
      const response = await axios.post(
        `${baseUrl}${urlEndpoints.AUTH}`,
        {
          phone_id,
          phone_code,
          phone_number,
          user_role: 4,
          os_type: 3,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "LiviaApp-language": "en",
            "LiviaApp-APIVersion": "2.0",
            "LiviaApp-timezone": "330",
            "LiviaApp-country": countryCode,
          },
        }
      );

      return response;
    } catch (error) {
      throw error;
    }
  }

  // to verify user for signup/signin
  async loginWithOtp(
    otp: string,
    phoneCode: string,
    phoneNumber: string,
    countryCode: string
  ) {
    try {
      const response = await axios.put(
        `${baseUrl}${urlEndpoints.AUTH}`,
        {
          phone_id: getPhoneId(),
          phone_code: phoneCode,
          phone_number: phoneNumber,
          auth_code: otp,
          os_type: 3,
          user_role: 4,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "LiviaApp-language": "en",
            "LiviaApp-APIVersion": "2.0",
            "LiviaApp-timezone": "330",
            "LiviaApp-country": countryCode,
          },
        }
      );

      return response && response.data;
    } catch (err) {
      throw err;
    }
  }

  async postUserDetail(payload: any) {
    const phone_id = getPhoneId();
    try {
      const res = await axios.patch(
        `${baseUrl}${urlEndpoints.AUTH}/2`,
        { ...payload, phone_id },
        {
          headers: {
            "Content-Type": "application/json",
            "LiviaApp-language": "en",
            "LiviaApp-APIVersion": "2.0",
            "LiviaApp-timezone": "330",
            "LiviaApp-country": getWebsiteCountryCodeFromLS(),
          },
        }
      );
      return res;
    } catch (err) {
      throw err;
    }
  }

  getTnC(id = 4) {
    return axios.get(`${baseUrl}/page/1?language=en&country=ke&role_id=4 `, {
      headers: {
        "LiviaApp-Token": localStorage.getItem("access_token") as string,
        "LiviaApp-country": localStorage.getItem("country_code") as any,
        "LiviaApp-language": "en",
        "LiviaApp-city": "186301",
        // 'LiviaApp-Url': window.location.origin
        "liviaApp-chemistUrl":
          process.env.NODE_ENV === "production"
            ? (window.location.host as any)
            : process.env.REACT_APP_DOMAIN,
      },
    });
  }
}
