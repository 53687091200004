import axios from "axios";
import { axiosInstance } from "../api/axiosInstance"
import { getAccessTokenFromLS, getIdempotencyKey } from "../utility/util";

const URL = "https://api.paymentsos.com/payments"
const date = new Date();
const payuHeader:any = {
    "x-payments-os-env": process.env.REACT_APP_PAYU_ENV,//"test",
    "api-version":process.env.REACT_APP_PAYU_API_VERSION,//"1.3.0",
    "private_key":process.env.REACT_APP_PAYU_PRIVATE_KEY,//"f61a9910-b0d8-4946-ac28-789da8df945c",
    "public_key": process.env.REACT_APP_PAYU_PUBLIC_KEY,//"10416a41-1981-4d48-9b78-041c66c4fa2e",
    "app_id": process.env.REACT_APP_PAYU_ID,//"com.appinventiv.pay_u_kenya_new",
    "idempotency_key":getIdempotencyKey()||"",
}
export class PayUService{
    
    
    createPayUId= async(payload:any) => {
        try{
            const response = await axios.post(URL, payload, { 
                headers:payuHeader
            })
                 return response;
        }
        catch(error){
            throw error
        }
                
    }
    openPayU = async(id:string, referneceId:any, orderId:any)=>{
        const payload={
            payment_method: {
                
                type: "untokenized",
                source_type: "payment_page",
                additional_details: {
                    supported_payment_methods: "CREDITCARD",
                    order_id: referneceId
                }
            },
            additional_details: {
              order_id: referneceId
            },
            merchant_site_url: `${window.location.origin}/#/user/my-order/detail/${orderId}`//"https://liviapaymentapi.appskeeper.in/payment/v1/payu/merchant-response"
          }
        try{
            const response = await axios.post(`${URL}/${id}/charges`, payload,{
                headers:payuHeader
            })
                return response
        }
        catch(error){
            
        }
       
    } 
}