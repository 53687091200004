export const commonAction = {
  IS_API_LOADING: "IS_API_LOADING",
  SET_API_DATA: "SET_API_DATA",
  SET_API_ERROR: "SET_API_ERROR",
  RESET_REDUCER: "RESET_REDUCER",
};

export const SEARCHMEDICINE = "SEARCH_MEDICINE";
export const TOPSELLINGMEDICINE = "TOP_SELLING_MEDICINE";
export const COUNTRY = "COUNTRY";
export const LOGINWITHOTP = "LOGIN_WITH_OTP";

export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const DELETE_CART = "DELETE_CART";
export const ACTIVE_USER = "ACTIVE_USER";
export const GUEST_USER = "GUEST_USER";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const ADD_PRESCRIPTION = "ADD_PRESCRIPTION";
export const REMOVE_PRESCRIPTION = "REMOVE_PRESCRIPTION";
export const DELETE_PRESCRIPTION_LIST = "DELETE_PRESCRIPTION_LIST";

export const VIEW_ALL_RESULT = "VIEW_ALL_RESULT";

export const GET_ADDRESS = "GET_ADDRESS";

//
const ACTION_NAME = {
  GET_PROFILE: "GET_PROFILE",
  LOADING: "LOADING",
  USER_ORDER: "USER_ORDER",
  USER_ORDER_DETAIL: "USER_ORDER_DETAIL",
};

export default ACTION_NAME;
