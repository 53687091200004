import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import MapToReview from "../../components/Map/MapToReview";
import { globalLoaderEnd, globalLoaderStart } from "../../redux/reducers/globalReducer";
import ImageService from "../../services/ImageService/ImageService";
import TextField from "@mui/material/TextField";

import "./ReviewOrder.scss";
import { placeOrder } from "../../redux/actions/placeOrderAction";
import showAlert from "../../utility/alert";
import PrescriptionInfoDialog from "../../components/PrescriptionDialog/PrescriptionInfoDialog";
import LearnMoreDialog from "../../components/PrescriptionDialog/LearnMoreDialog";
import MoreContentDialog from "../../components/PrescriptionDialog/MoreContentDialog";
import RegisterUser from "../../services/OnBoarding/MyProfileService";
import { deletePrescriptionList } from "../../redux/actions/prescriptionAction";
import PreviewImage from "../../components/PreviewImage/PreviewImage";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const imageService = new ImageService();
const profileSer = new RegisterUser();

export default function ReviewOrder() {
  const dispatch = useDispatch();
  const [openInfoDialog, setInfoDialog] = useState(false);
  const [openLearnMoreDialog, setLearnMoreDialog] = useState(false);
  const [openMoreContentDialog, setMoreContentDialog] = useState(false);
  const [pharmacyName, setPharmacyName] = useState("");

  const deliveryAddress = useLocation().state as any;
  const [coordinates, setCoordinates] = useState<any>();
  const [list, setList] = useState<any[]>([]);
  const [imageUrlList, setImageUrlList] = useState<string[]>([]);
  const [imageUrlString, setImageUrlString] = useState<string>("");
  const [noOfAPIHit, setAPIHit] = useState(0);
  const [orderId, setOrderId] = useState();
  const navigate = useNavigate();
  const [previewImage, setPreviewImg] = useState();
  const [openPreview, setOpenPreview] = useState(false);
  const handleInfoClose = (event: any, reason: any) => {
    if (reason && reason === "backdropClick") return;
    else {
      setInfoDialog(false);

      navigate(`/user/my-order/detail/${orderId}`);
    }
  };

  const handleLearnMoreContinue = (event: any, reason: any) => {
    if (reason && reason === "backdropClick") return;
    else {
      setInfoDialog(true);
      setLearnMoreDialog(false);
    }
  };
  const handleMoreContentClose = (event: any, reason: any) => {
    if (reason && reason === "backdropClick") return;
    else {
      setMoreContentDialog(false);
      setLearnMoreDialog(false);
      setInfoDialog(true);
    }
  };
  const { prescriptionList } = useSelector((state: any) => state.prescriptionReducer);
  const handleClosePreview = () => {
    setOpenPreview(false);
  };
  useEffect(() => {
    const { latitude, longitude } = deliveryAddress;
    setCoordinates({ lat: parseFloat(latitude), lng: parseFloat(longitude) });
  }, [deliveryAddress]);
  useEffect(() => {
    profileSer.bannerDetail().then((res) => {
      if (res?.data?.body?.banner_data) {
        setPharmacyName(res?.data?.body?.chemist_details?.pharmacy_name);
      }
    });
  }, []);
  useEffect(() => {
    setList(prescriptionList);
  }, [prescriptionList]);
  useEffect(() => {
    setImageUrlString(imageUrlList.join(","));
  }, [imageUrlList]);
  useEffect(() => {
    if (noOfAPIHit === list.length && imageUrlList.length === list.length && noOfAPIHit) {
      const payload = {
        address: deliveryAddress?.address,
        address2: deliveryAddress?.address2,
        landmark: deliveryAddress?.landmark,
        address_title: deliveryAddress?.address_title,
        address_type: deliveryAddress?.address_type,
        address_id: deliveryAddress?.id ? deliveryAddress?.id : "",
        is_ethical_substitute: 0,
        latitude: deliveryAddress.latitude,
        longitude: deliveryAddress.longitude,
        self_collect: localStorage.getItem("delivery-type") === "Self Collect" ? 1 : 0,
        image: imageUrlList.join(","),
        pharma_id: "",
        order_from_chemist_user_webiste: 1,
        manual: 1,
      };
      dispatch(globalLoaderStart());
      dispatch(placeOrder(payload))
        .then((response: any) => {
          dispatch(globalLoaderEnd());
          // localStorage.removeItem("prescription");
          dispatch(deletePrescriptionList() as any);
          setOrderId(response?.data?.order_id);
          if (response?.data?.order_from_chemist_website_count === "1") setLearnMoreDialog(true);
          else setInfoDialog(true);
        })
        .catch((error: any) => {
          dispatch(globalLoaderEnd());
          if (error?.response?.data?.messages && error?.response?.data?.messages[0]) {
            showAlert(2, error?.response?.data?.messages[0]);
          }
        });
      setAPIHit(0);
      setImageUrlList([]);
    }
  }, [noOfAPIHit, imageUrlList]);
  const uploadImage = () => {
    for (const imageObj of list) {
      dispatch(globalLoaderStart());
      imageService
        .uploadImage(imageObj.base64.split(",")[1])
        .then((res) => {
          dispatch(globalLoaderEnd());
          setImageUrlList((prev: any) => [...prev, res?.data?.image]);
          setAPIHit((prev: number) => prev + 1);
        })
        .catch((err: any) => {
          dispatch(globalLoaderEnd());
          showAlert(2);
          setImageUrlList([]);
        });
    }
  };
  const placeOrderHandler = () => {
    uploadImage();
  };
  return (
    <div className="page-review-order">
      <div className="review-order-content">
        <div className="left-portion skeu-card">
          <div className="space-between">
            <h2 className="mb-10 semi-bold">
              {" "}
              <span className="justify-start">
                <ArrowBackIcon className="pointer mr-10" titleAccess="Go Back" onClick={() => navigate(-1)} /> Review
                Your Order
              </span>
            </h2>
          </div>
          <hr className="mb-10" />
          <h2 className="mb-20 mt-20">Attached Prescriptions</h2>
          <ul className="uploaded-prescription">
            {list.map((prescription: any, index: number) => (
              <li key={index}>
                <div className="single-prescription">
                  <img
                    style={{ cursor: "pointer" }}
                    src={prescription.base64}
                    onClick={() => {
                      setPreviewImg(prescription.base64);
                      setOpenPreview(true);
                    }}
                  />
                </div>
              </li>
            ))}
          </ul>
          <TextField
            variant="outlined"
            placeholder="Write your comment here..."
            type="text"
            multiline
            maxRows={6}
            rows={4}
          />

          <button className="btn btn-primary w-100 " style={{ margin: "60px auto auto" }} onClick={placeOrderHandler}>
            Place Order
          </button>
        </div>
        <div className="right-portion skeu-card">
          <h2 className="semi-bold mb-10">Location</h2>
          <hr className="mt-10 mb-20" />
          <MapToReview coordinates={coordinates} />
          <hr className=" mb-20" />
          <h2 className="semi-bold mb-10">
            Order Type: <b>{localStorage.getItem("delivery-type")}</b>
          </h2>
          <ul className="upload-terms">
            <li>
              <p>You are required to hand over the physical prescription to the rider upon receiving your order.</p>
            </li>
            <li>
              <p>
                Livia health is a technology platform that facilitates business transactions. By having the order
                delivered, you authorise the delivery personnel to be your agent for delivery of the goods.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <PreviewImage open={openPreview} handleClose={handleClosePreview} image={previewImage as any} />
      <PrescriptionInfoDialog
        openDialog={openInfoDialog ? true : false}
        onClose={handleInfoClose}
        pharmacyName={pharmacyName}
      />
      <LearnMoreDialog
        onContinue={handleLearnMoreContinue}
        openDialog={openLearnMoreDialog ? true : false}
        openMoreContent={() => {
          setMoreContentDialog(true);
        }}
        pharmacyName={pharmacyName}
      />
      <MoreContentDialog
        openDialog={openMoreContentDialog ? true : false}
        onClose={handleMoreContentClose}
        pharmacyName={pharmacyName}
      />
    </div>
  );
}
