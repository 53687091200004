import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { searchMedicineListAction } from "../redux/actions/searchMedicineListAction";
import { useDispatch, useSelector } from "react-redux";
import { IMedicine } from "../pages/Landing/model/searchMedicineModel";
import { useNavigate } from "react-router-dom";

function SearchMedicine(props: any) {
  const [searchKey, setSearchKey] = useState(props.search);
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const searchData = useSelector((state: any) => state.SearchMedicine.apiData);
  const [noOptionText, setNoOptionText] = useState(
    "Searched medicine not found"
  );
  const searchLoading = useSelector(
    (state: any) => state.SearchMedicine.isApiLoading
  );
  const [options, setOption] = useState<IMedicine[]>([]);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    let delayDebounceFn: any;
    if (isMounted && searchKey !== "" && searchKey.length >= 3) {
      setNoOptionText("Loading medicine...");
      delayDebounceFn = setTimeout(() => {
        dispatch(searchMedicineListAction(searchKey) as any);
      }, 1000);
    } else if (searchKey.length < 3 && searchKey !== "") {
      setNoOptionText("Please enter atleast three characters");
      setOption([]);
    } else if (searchKey === "") {
      setOption([]);
      setOpen(false);
      // setNoOptionText("Searched medicine not found");
    }
    return () => clearTimeout(delayDebounceFn);
  }, [searchKey]);

  useEffect(() => {
    if (isMounted) {
      if (searchData?.data?.body && searchKey !== "") {
        if (searchData?.data?.body.length > 4) {
          let dataToShow: IMedicine[] = searchData?.data?.body.slice(0, 4);
          dataToShow[3].lastElement = true;
          setOption(dataToShow);
        } else if (
          searchData?.data?.body.length <= 4 &&
          searchData?.data?.body.length > 0
        ) {
          setOption(searchData?.data?.body);
        } else {
          setOption([]);
          if (searchKey) setNoOptionText("Searched medicine not found");
        }
      }
    }
  }, [searchData]);
  useEffect(() => {
    if (props.search !== "") setSearchKey(props.search);
  }, [props.search]);

  return (
    <Autocomplete
      open={open}
      clearOnBlur={false}
      onOpen={() => {
        if (searchKey !== "") {
          setOpen(true);
        } else {
          setOpen(false);
        }
      }}
      onFocus={() => {
        if (searchKey === "") setOpen(false);
      }}
      onBlur={() => {
        setOpen(false);
      }}
      noOptionsText={<h2 className="center mt-30 mb-30">{noOptionText}</h2>}
      // onClose={() => setOpen(true)}
      autoComplete={false}
      // fullWidth={true}
      inputValue={searchKey}
      // clearOnEscape={true}
      id="combo-box-demo"
      // openOnFocus={false}
      getOptionLabel={(option) => `${option.name + "-" + option.api}`}
      filterOptions={(options, state) => options}
      options={options}
      loading={searchLoading}
      loadingText={<h2 className="center mt-30 mb-30">Loading medicine...</h2>}
      onInputChange={(event, newInputValue) => {
        if (props.search === "") {
          setSearchKey(newInputValue);
        } else {
          if (newInputValue !== "" || count !== 0) {
            setCount(count + 1);
            setSearchKey(newInputValue);
          }
        }
      }}
      onChange={(event, newValue) => {
        // console.log("newValue", newValue);
      }}
      // isOptionEqualToValue={(option, value) => option.name == value.name}
      renderOption={(props, option: any) => {
        // setOptionCount(optionCount + 1);
        return (
          <>
            <li
              key={option.id}
              {...props}
              onClick={() => {
                navigate("/product-detail", {
                  state: {
                    medicine: option,
                  },
                });
              }}
              className="searched-list-item"
            >
              <div>
                <h4 className="semi-bold capitalize">{option.name}</h4>
                <h5 className="color-primary">
                  <i>{option.api}</i>
                </h5>
              </div>
              <h4 className="color-primary bold">
                {option.customer_price?.toFixed(2)} KES
              </h4>
            </li>
            {option.lastElement && (
              <button
                key={option.id}
                className="btn btn-secondary"
                style={{ margin: "10px auto 0px" }}
                onClick={() => {
                  setOpen(false);

                  navigate(`/search-result`, {
                    state: {
                      search: searchKey,
                      medicineList: searchData?.data?.body,
                    },
                  });
                  setOption([]);
                }}
              >
                View All
              </button>
            )}
          </>
        );
      }}
      renderInput={(params) => (
        <TextField {...params} placeholder="Search Medicine Here" />
      )}
    />
  );
}

export default SearchMedicine;
