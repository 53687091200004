import OrderService from "../../services/OrderService";

const orderService = new OrderService();
export function placeOrder(payload: any) {
  return (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await orderService.placeOrder(payload);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export function onOrderSuccess(payload: any) {
  return (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await orderService.onOrderSuccess(payload);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}
