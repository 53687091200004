import { createTheme, responsiveFontSizes } from "@mui/material/styles";

let theme = createTheme({
  palette: {
    primary: {
      main: "#308e99",
    },
    secondary: {
      light: "rgba(82, 130, 240, 0.1)",
      main: "rgba(82, 130, 240, 0.38)",
    },
  },
  spacing: 10,
  typography: {
    h1: {
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "19px",
      fontFamily: "Barlow, sans-serif",
    },
    h2: {
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "24px",
      fontFamily: "Barlow, sans-serif",
    },
    h3: {
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "16px",
      fontFamily: "Barlow, sans-serif",
    },
    h4: {
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      fontFamily: "Barlow, sans-serif",
    },
  },
});

theme = responsiveFontSizes(theme, {
  breakpoints: ["xs", "sm", "md", "lg"],
  factor: 3,
  disableAlign: true,
});

export default theme;
