import React, { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import "./SelectDelivery.scss";
import { useSelector } from "react-redux";
import showAlert from "../../utility/alert";
import { useNavigate } from "react-router-dom";
import AddDetailDialog from "../Checkout/section/AddDetailDialog";
import LoginDialog from "../Onboarding/LoginDialog";
import { getUserStatus } from "../../utility/util";
import PreviewImage from "../../components/PreviewImage/PreviewImage";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import OtpDialog from "../../components/OtpDialog";

function SelectDelivery() {
  const [previewImage, setPreviewImg] = useState();
  const [openPreview, setOpenPreview] = useState(false);
  const [list, setList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openLoginDialog, setLoginDialog] = useState(false);
  const navigate = useNavigate();
  const [openOTPDialog, setOTPDialog] = useState(false);
  const [deliveryType, setDeliveryType] = useState(localStorage.getItem("delivery-type"));
  const [guestPayload, setGuestPayload] = useState();

  const { prescriptionList } = useSelector((state: any) => state.prescriptionReducer);
  const handleClosePreview = () => {
    setOpenPreview(false);
  };
  const handleOTPClose = () => {
    setOTPDialog(false);
  };
  const handleDetailOpen = () => {
    setOpenDialog(true);
  };
  const handleOTPOpen = (payload: any) => {
    setOpenDialog(false);
    setGuestPayload(payload);
    setOTPDialog(true);
  };
  useEffect(() => {
    setList(prescriptionList);
  }, [prescriptionList]);
  const handleDeliverySelect = (event: any) => {
    const { value } = event.target;
    setDeliveryType(value);
    localStorage.setItem("delivery-type", value);
  };
  const handleNext = () => {
    if (!deliveryType) {
      showAlert(2, "Select Delivery Method");
      return;
    }
    if (getUserStatus()) navigate("/select-address");
    else {
      setOpenDialog(true);
    }
  };
  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleLoginOpen = () => [setLoginDialog(true)];
  const handleLoginClose = () => {
    setLoginDialog(false);
  };
  return (
    <div className="page-select-delivery">
      {/* <h3 className="mb-20">
        <span className="color-primary justify-start">
          <ArrowBackIcon
            className="pointer mr-10"
            titleAccess="Go Back"
            onClick={() => navigate(-1)}
          />{" "}
          Select Delivery Method
        </span>
      </h3> */}
      <div className="select-delivery-content">
        <div className="left-portion skeu-card">
          <div>
            <h2 className="mb-10 semi-bold justify-start">
              <ArrowBackIcon className="pointer mr-10" titleAccess="Go Back" onClick={() => navigate(-1)} /> Select
              Delivery Method
            </h2>
            <hr className="mb-20" />
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={handleDeliverySelect}
              value={deliveryType}
            >
              <FormControlLabel className="bold" value="Delivery" control={<Radio />} label="Delivery (Cost 150 KES)" />
              <FormControlLabel className="bold" value="Self Collect" control={<Radio />} label="Self Collect" />
            </RadioGroup>
            <button className="btn btn-primary w-100 mt-20" onClick={handleNext}>
              Next
            </button>
          </div>
        </div>
        <div className="right-portion skeu-card">
          <h2 className="mb-10 semi-bold">Attached Prescriptions</h2>
          <hr className="mb-20" />

          <ul className="uploaded-prescription">
            {list.map((prescription: any, index: number) => (
              <li key={index}>
                <div className="single-prescription">
                  <img
                    src={prescription.base64}
                    alt="Prescription"
                    onClick={() => {
                      setPreviewImg(prescription.base64);
                      setOpenPreview(true);
                    }}
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {/* <div className="select-delivery-content">
        <div className="left-portion bg-white-padding-shadow">
          <h2 className="semi-bold justify-start">
            {" "}
            <ArrowBackIcon
              className="pointer mr-10"
              titleAccess="Go Back"
              onClick={() => navigate(-1)}
            />{" "}
            Select Delivery Method
          </h2>
          <hr className="mt-20 mb-20" />
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={handleDeliverySelect}
            value={deliveryType}
          >
            <FormControlLabel
              className="bold"
              value="Delivery"
              control={<Radio />}
              label="Delivery (Cost 150 KES)"
            />
            <FormControlLabel
              className="bold"
              value="Self Collect"
              control={<Radio />}
              label="Self Collect"
            />
          </RadioGroup>
          <button className="btn btn-primary mt-30 w-100" onClick={handleNext}>
            Next
          </button>
        </div>
        <div className="right-portion bg-white-padding-shadow">
          <h2 className="semi-bold">Attached Prescriptions</h2>
          <hr className="mt-20 mb-20" />
          <ul className="uploaded-prescription">
            {list.map((prescription: any, index: number) => (
              <li key={index}>
                <div className="single-prescription">
                  <img
                    src={prescription.base64}
                    alt="Prescription"
                    onClick={() => {
                      setPreviewImg(prescription.base64);
                      setOpenPreview(true);
                    }}
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div> */}
      <AddDetailDialog
        open={openDialog}
        onClose={handleClose}
        handleLoginOpen={handleLoginOpen}
        handleOTPOpen={handleOTPOpen}
        from="prescription"
      />
      <LoginDialog openDialog={openLoginDialog ? true : false} onClose={handleLoginClose} from="prescription" />
      <PreviewImage open={openPreview} handleClose={handleClosePreview} image={previewImage as any} />
      <OtpDialog
        openDialog={openOTPDialog ? true : false}
        onClose={handleOTPClose}
        guestPayload={guestPayload}
        openDetailDialog={handleDetailOpen}
        from="prescription"
      ></OtpDialog>
    </div>
  );
}

export default SelectDelivery;
