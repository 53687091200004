import moment from "moment";
import { axiosInstance } from "../api/axiosInstance";
import { urlEndpoints } from "../api/endpoints";
import { ConvertSignupPayload } from "../types";
import showAlert from "./alert";
import { OrderStatus } from "./staticData";
import { getPhoneId } from "./util";

export const checkOnline = () => {
  if (!navigator.onLine) return showAlert(3, "PLEASE CHECK INTERNET");
};

export const subtractDate = (year: number) => {
  return moment().subtract(year, "years").format("YYYY-MM-DD");
};

export const getCountryCode = (phoneCode: string) => {
  let countryCode;
  if (phoneCode === "254") countryCode = "ke";
  if (phoneCode === "375") countryCode = "by";
  if (phoneCode === "1") countryCode = "us";
  return countryCode;
};

export const convertDate = (date: string) => {
  return moment(date).format("YYYY-MM-DD");
};

export const currentDate = () => {
  return moment().format("YYYY-MM-DD");
};

export const convertSignupPayload = (payload: ConvertSignupPayload) => {
  const {
    phoneCode,
    phoneNumber,
    title,
    firstName,
    lastName,
    gender,
    city,
    dateOfBirth,
    email,
    avatar,
  } = payload;

  return {
    age: "18",
    city_id: city?.id,
    country_code: getCountryCode(phoneCode),
    date_birth: dateOfBirth,
    email: email,
    first_name: firstName,
    last_name: lastName,
    name_prefix: title,
    os_type: "3",
    phone_code: phoneCode,
    phone_id: getPhoneId(),
    phone_number: phoneNumber,
    sex: gender,
    user_role: 4,
    user_status: "1",
    avatar,
  };
};

export const uploadDocumentImage = (binaryImage: any) => {
  return axiosInstance.patch(urlEndpoints.IMAGE, {
    image: binaryImage,
    type: "users",
  });
};

export const deleteDocumentImage = (image: any) => {
  return axiosInstance.delete(urlEndpoints.IMAGE, {
    data: { image },
  });
};

export const getOrderStatus = (status: string) => {
  let noStatus = Number(status);
  let title = "";
  if (OrderStatus.IN_PROCESS === noStatus) title = "Prescription on moderation";
  else if (OrderStatus.NO_OFFERS === noStatus)
    title = "Payment pending for your order";
  else if (OrderStatus.BEST_OFFER === noStatus)
    title = "Quotation Received for your order";
  else if (OrderStatus.PREPARE_ORDER === noStatus)
    title = "Your order is being prepared";
  else if (OrderStatus.ON_DELIVERY === noStatus) title = "On Delivery";
  else if (OrderStatus.CANCELLED === noStatus)
    title = "Your have cancelled the order";
  else if (OrderStatus.RECEIVED === noStatus) title = "Order Delivered";
  else if (OrderStatus.NOT_RECEIVED === noStatus) title = "Not Received";
  else if (OrderStatus.REFUNDED === noStatus) title = "Refunded";
  else if (OrderStatus.IN_PAYMENT === noStatus)
    title = "Your payment is under process";
  else if (OrderStatus.PAYMENT_UNSUCCESSFUL === noStatus)
    title = "Payment Unsuccessful for your order";
  else if (OrderStatus.TAKEAWAY === noStatus)
    title = "Your order is ready to be collected";
  else if (OrderStatus.PARTIALLY_CLOSED === noStatus)
    title = "Partially Closed";
  else if (OrderStatus.ON_MODERATION === noStatus) title = "On Moderation";
  else if (OrderStatus.ALTERNATIVE_ITEMS === noStatus)
    title = "Alternative Item";
  else if (OrderStatus.BEST_OFFER_WITH_ALTERNATIVE_ITEMS === noStatus)
    title = "Best offer with alternative item";
  return title;
};

export const getOrderStatusBgColor = (status: string) => {
  let noStatus = Number(status);
  let color = "";
  if (OrderStatus.IN_PROCESS === noStatus)
    color = "#dca709"; //"var(--warning)";
  else if (OrderStatus.NO_OFFERS === noStatus) color = "#dca709"; //"No Offers";
  else if (OrderStatus.BEST_OFFER === noStatus)
    color = "#dca709"; //"var(--light-green)";
  else if (OrderStatus.PREPARE_ORDER === noStatus)
    color = "#dca709"; //"var(--warning)";
  else if (OrderStatus.ON_DELIVERY === noStatus)
    color = "#06834d"; //"var(--light-green)";
  else if (OrderStatus.CANCELLED === noStatus)
    color = "var(--status-red)"; //"Cancelled";
  else if (OrderStatus.RECEIVED === noStatus)
    color = "#06834d"; //"var(--light-green)";
  else if (OrderStatus.NOT_RECEIVED === noStatus) color = "Not Received";
  else if (OrderStatus.REFUNDED === noStatus) color = "Refunded";
  else if (OrderStatus.IN_PAYMENT === noStatus) color = "#dca709";
  else if (OrderStatus.PAYMENT_UNSUCCESSFUL === noStatus)
    color = "var(--status-red)";
  else if (OrderStatus.TAKEAWAY === noStatus) color = "#06834d"; //"Takeaway";
  else if (OrderStatus.PARTIALLY_CLOSED === noStatus)
    color = "Partially Closed";
  else if (OrderStatus.ON_MODERATION === noStatus) color = "On Moderation";
  else if (OrderStatus.ALTERNATIVE_ITEMS === noStatus)
    color = "Alternative Item";
  else if (OrderStatus.BEST_OFFER_WITH_ALTERNATIVE_ITEMS === noStatus)
    color = "Best offer with alternative item";
  return color;
};

export const getOrderDate = (date: string) => {
  return moment(date).format("DD-MM-YYYY");
};

export const getTableOffset = (page: string, limit: string) => {
  return +page * +limit - +limit + "";
};
