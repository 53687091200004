import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter as Router } from "react-router-dom";
import swDev from "./swDev";
import RegisterUser from "./services/OnBoarding/MyProfileService";
const pharmacyDetail = new RegisterUser();

// navigator.serviceWorker.register("/sw.js").then(function () {
//   console.log("SW registered");
// });
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
/******-----------code to capture A2HS PWA event---------***/

window.addEventListener("appinstalled", function () {
  console.log("Thank you for installing our app!");
  pharmacyDetail.bannerDetail().then((res: any) => {
    if (res?.data?.body) {
      console.log("banner response", res?.data?.body?.chemist_details?.id);
      pharmacyDetail
        .trackPWAInstall(
          res?.data?.body?.chemist_details?.id,
          res?.data?.body?.chemist_details?.role_id
        )
        .then((res: any) => {
          console.log(res);
        });
    }
  });
});
/****-----------------------------------------------------*****/
root.render(
  // <React.StrictMode>
  <Router>
    <App />
  </Router>
  // </React.StrictMode>
);
// swDev();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
