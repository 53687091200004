import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";

type IconButtonWrapperProps = {
  children: React.ReactNode;
} & IconButtonProps;

const IconButtonCustomize = styled(IconButton)<IconButtonWrapperProps>(
  ({}) => ({})
);

function IconButtonWrapper({
  children,
  ...otherProps
}: IconButtonWrapperProps) {
  return (
    <IconButtonCustomize
      {...otherProps}
      size="small"
      sx={{
        bottom: "-7%",
        right: "-3%",
        border: (theme) => `1px solid ${theme.palette.primary.main}`,
        position: "absolute",
        bgcolor: (theme) => theme.palette.common.white,
        "&:focus": {
          bgcolor: (theme) => theme.palette.common.white,
        },
        "&:hover": {
          bgcolor: (theme) => theme.palette.common.white,
        },
      }}
    >
      {children}
    </IconButtonCustomize>
  );
}

export default IconButtonWrapper;
