import FormLabel, {
  FormLabelProps,
  formLabelClasses,
} from "@mui/material/FormLabel";
import { styled } from "@mui/material/styles";

type FormLabelWrapperProps = {
  children: string;
} & FormLabelProps;

const StyledFormLabelWrapper = styled(FormLabel)<FormLabelWrapperProps>(
  ({ theme }) => ({
    color: "var(--grey-black)",
    font: `normal ${theme.typography.fontWeightRegular} ${theme.spacing(
      1.6
    )} Barlow, sans-serif`,
    [`& .${formLabelClasses.asterisk}`]: {
      color: theme.palette.error.light,
    },
  })
);

function FormLabelWrapper({ children, ...otherProps }: FormLabelWrapperProps) {
  return (
    <StyledFormLabelWrapper {...otherProps}>{children}</StyledFormLabelWrapper>
  );
}

export default FormLabelWrapper;
