import RegisterUser from "../../services/OnBoarding/MyProfileService";

const myProfileService = new RegisterUser();
export function registerGuestUserAction(payload: any) {
  return (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await myProfileService.registerGuestUser(payload);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export function getUserDetailByPhoneAction(payload: any) {
  return (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await myProfileService.fetchUserDetail(payload);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}
