import axios from "axios";
import { baseUrl, urlEndpoints } from "../../api/endpoints";
import { getWebsiteCountryCodeFromLS } from "../../utility/util";

export default class RegisterUser {
  registerGuestUser = async (payload: any) => {
    try {
      return axios.patch(`${baseUrl}${urlEndpoints.HOSPITAL_USER}`, payload, {
        headers: {
          "Content-Type": "application/json",
          "LiviaApp-language": "en",
          "LiviaApp-APIVersion": "4.0",
          "LiviaApp-timezone": "330",
          "LiviaApp-country": getWebsiteCountryCodeFromLS(),
        },
      });
    } catch (err) {
      throw err;
    }
  };

  fetchUserDetail = async (payload: any) => {
    try {
      const response = await axios.patch(
        `${baseUrl}${urlEndpoints.HOSPITAL_USER}?param=get-name`,
        payload,
        {
          headers: {
            "LiviaApp-Token": localStorage.getItem(
              "website_access_token"
            ) as any,
            "LiviaApp-country":
              localStorage.getItem("website_country_code") ?? "ke",
            "LiviaApp-language": "en",
            "LiviaApp-city": "186301", // as per discussion with sudhir
            // 'LiviaApp-Url': window.location.host,
            // "LiviaApp-Url": "Vinayak.liviaapp.com",
            "LiviaApp-Url":
              process.env.NODE_ENV === "production"
                ? window.location.host
                : (process.env.REACT_APP_DOMAIN as any), //"starpharmacy-livia.liviahealth.com", //"starpharmacy-livia.appskeeper.in",
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  bannerDetail = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}${urlEndpoints.CHEMIST_HOME}/1`,
        {
          headers: {
            "LiviaApp-language": "en",
            "LiviaApp-chemistUrl":
              process.env.NODE_ENV === "production"
                ? window.location.host
                : (process.env.REACT_APP_DOMAIN as string), //"starpharmacy-livia.appskeeper.in", //"starpharmacy-livia.liviahealth.com",
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };
  trackPWAInstall = async (user_id: any, role_id: any) => {
    try {
      const response = await axios.post(
        `${baseUrl}${urlEndpoints.TRACK_INSTALL}`,
        { user_id, role_id },
        {
          headers: {
            "LiviaApp-language": "en",
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };
}
