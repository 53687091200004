import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { DialogContent, DialogTitle, Grid } from "@mui/material";
import OtpInput from "react-otp-input";
import { useDispatch } from "react-redux";
import {
  globalLoaderEnd,
  globalLoaderStart,
} from "../redux/reducers/globalReducer";
import { getOtpAction, loginWithOtpAction } from "../redux/actions/loginAction";
import {
  removeGuestUserSession,
  setAccessToken,
  setCountryCodeInLS,
  setGuestUser,
  setGuestUserData,
  setPhoneCodeLS,
  setPhoneNumberLS,
  setRefreshToken,
  setUserCity,
  setUserRoleIdInLS,
  setUserStatus,
} from "../utility/util";
import showAlert from "../utility/alert";
import { registerGuestUserAction } from "../redux/actions/MyProfileAction";
import { useNavigate } from "react-router-dom";
import { makeStyles, createStyles } from "@mui/styles";
const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: "flex-start",
  },
  topPaperScrollBody: {
    verticalAlign: "top",
  },
});

function OtpDialog(props: any) {
  const classes = useStyles();
  const { openDialog, onClose, guestPayload, openDetailDialog, from } = props;
  const [timerInterval, settimerInterval] = useState<any>(null);
  const [timer, settimer] = useState<any>(45);
  const [logCode, setlogCode] = useState("");
  const [errorState, setErrorState] = useState({
    otp: false,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const startTimer = () => {
    settimerInterval(null);
    settimer(45);
    settimerInterval(
      setInterval(() => {
        settimer((time: any) => {
          return time - 1;
        });
      }, 1000)
    );
  };
  useEffect(() => {
    setlogCode("");
  }, []);

  const clearTimer = () => {
    clearInterval(timerInterval);
    settimer(45);
  };
  useEffect(() => {
    if (openDialog) startTimer();
    if (!openDialog) clearTimer();
  }, [openDialog]);

  function apiHandler() {
    if (/^\d{4}$/.test(logCode)) {
      if (logCode.length !== 4) return false;
      dispatch(globalLoaderStart());
      dispatch(
        loginWithOtpAction(
          logCode,
          guestPayload.phone_code,
          guestPayload.phone_number,
          guestPayload.country_code
        ) as any
      )
        .then((res: any) => {
          removeGuestUserSession();
          dispatch(globalLoaderEnd());
          dispatch(globalLoaderStart());
          dispatch(registerGuestUserAction(guestPayload) as any)
            .then((res: any) => {
              dispatch(globalLoaderEnd());
              setGuestUser(res?.data?.guest_user === 0 ? false : true);
              setGuestUserData(res?.data?.user);
              setUserCity(res?.data?.user?.city_id);
              setUserStatus(res?.data?.user?.user_status);
              setAccessToken(res?.data?.access_token);
              setRefreshToken(res?.data?.refresh_token);
              // setUserIdInLS(res?.data?.user?.id);
              setUserRoleIdInLS(res?.data?.user?.role_id);
              setPhoneNumberLS(res?.data?.user?.phone_number);
              setPhoneCodeLS(res?.data?.user?.phone_code);
              setCountryCodeInLS(res?.data?.user?.country_code);
              showAlert(1, res?.data?.messages[0]);
              if (from === "cart") navigate("/checkout");
              else navigate("/select-address");
              onClose();
            })
            .catch((error: any) => {
              dispatch(globalLoaderEnd());
              if (
                error?.response?.data?.messages &&
                error?.response?.data?.messages[0]
              ) {
                showAlert(2, error?.response?.data?.messages[0]);
                onClose();
                openDetailDialog();
              }
            });
          // setAccessToken(res.access_token);
          // setRefreshToken(res.refresh_token);
          // setUserStatus(res.user_status);
          // setPhoneNumberLS(
          //   allowedLength === 10 ? phoneNumber.substring(1) : phoneNumber
          // );
          // setPhoneCodeLS(phoneCode);
          // setUserCity(res.city_id);
          // dispatch(showLogin());
        })
        .catch((error: any) => {
          dispatch(globalLoaderEnd());
          if (error && error.response) {
            showAlert(2, error.response.data.messages[0]);
            setErrorState((prev: any) => ({ otp: true }));
            return false;
          }
        });
    }
  }
  const resendOTPHandler = () => {
    clearTimer();

    dispatch(
      getOtpAction(
        guestPayload.phone_number,
        guestPayload.phone_code,
        guestPayload.country_code
      ) as any
    )
      .then((res: any) => {
        showAlert(1, "A Code has been sent to you via SMS");
        startTimer();
      })
      .catch((err: any) => {
        showAlert(2, err?.response?.data?.messages[0]);
      });
  };
  return (
    <Dialog
      open={openDialog}
      onClose={onClose}
      maxWidth="xs"
      classes={{
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
      }}
    >
      <DialogTitle>
        <h1 className="bold mt-10">Verify OTP</h1>
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12}>
          <h3 className="subtext mb-20">{`Please enter the 4 digit code sent to you via SMS on +${guestPayload?.phone_code} ${guestPayload?.phone_number}`}</h3>
          <OtpInput
            value={logCode}
            onChange={(value: any) => {
              setlogCode(value);
            }}
            className="single-otp-input"
            numInputs={4}
            isInputNum={true}
            // separator={<span>-</span>}
            hasErrored={errorState.otp}
          />
        </Grid>
        <div className="space-between mt-20">
          {/* <a
            onClick={() => {
              setOtpScreen(false);
              setlogCode("");
            }}
            className="semi-bold color-primary pointer hover-underline"
          >
            Go Back
          </a> */}

          {timer <= 0 ? (
            <a
              className="semi-bold pointer hover-underline"
              onClick={resendOTPHandler}
            >
              Resend
            </a>
          ) : (
            <span>Resend OTP in {timer} sec</span>
          )}
        </div>
        <button
          className="btn btn-primary w-100 mb-30 mt-30"
          onClick={() => {
            apiHandler();
          }}
        >
          Submit
        </button>
      </DialogContent>
    </Dialog>
  );
}

export default OtpDialog;
