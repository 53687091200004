export const baseUrl = `${process.env.REACT_APP_BASE_URL}`;
export const API_URL = `${process.env.REACT_APP_API_URL}`;
export const httpEndpoints = {
  GET: "get",
  POST: "post",
  PUT: "put",
  PATCH: "patch",
  DELETE: "delete",
};
export const urlEndpoints = {
  SETTINGS: "/settings",
  AUTH: "/auth",
  CITY: "/city",
  HOSPITAL_USER: "/hospital-user",
  USER: "/user",
  IMAGE: "/image",
  ORDER: "/chemist-website-order",
  USER_ADDRESS: "userAddress",
  ORDER_DETAIL: "order",
  REFRESH_TOKEN: "/auth/1",
  CHEMIST_HOME: "/chemist-home",
  TRACK_INSTALL: "/track-pwa-install",
  ORDER_STATUS: "/order-status",
};
