import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MapAddress from "../Checkout/section/MapAddress";
import "./SelectAddress.scss";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch } from "react-redux";
import { getUserProfile } from "../Profile/action";

function SelectAddress() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserProfile());
  }, []);

  return (
    <div className="page-select-address">
      <div className="select-address-content">
        <div className="skeu-card mb-30 w-100 map-card" style={{ padding: "20px", borderRadius: "5px" }}>
          <h2 className="mb-20 semi-bold">
            <span className=" justify-start">
              <ArrowBackIcon className="pointer mr-10" titleAccess="Go Back" onClick={() => navigate(-1)} /> Select
              Address
            </span>
          </h2>
          <MapAddress
            dialogHandler={(deliveryAddress: any) => {
              console.log("order placement", deliveryAddress);
              navigate("/review-order", {
                state: {
                  ...deliveryAddress,
                },
              });
            }}
            addressFromCheckout={{
              address: "",
              landmark: "",
              address_title: "Work",
              city: "",
              latitude: 0,
              longitude: 0,
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default SelectAddress;
