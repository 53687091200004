import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  addToCart,
  deleteFromCart,
  removeFromCart,
} from "../../redux/actions/cartActions";
import { IMedicine, IMedicineList } from "../Landing/model/searchMedicineModel";
import {
  productImg,
  emptyCart,
  plusIcon,
  minusIcon,
  dustbinIcon,
} from "./../../utility/images";
import "./Cart.scss";
import { useNavigate } from "react-router-dom";
import showAlert from "../../utility/alert";
import AddDetailDialog from "../Checkout/section/AddDetailDialog";
import LoginDialog from "../Onboarding/LoginDialog";
import { getUserStatus } from "../../utility/util";
import OtpDialog from "../../components/OtpDialog";
import CartTable from "../../components/table/CartTable";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function Cart() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state: any) => state.cart.cart);
  const [cartItemList, setCartItemList] = useState<IMedicineList[]>([]);
  const [itemTotal, setItemTotal] = useState(0);
  const [deliveryFee, setDeliveryFee] = useState(250);
  const [totalToPay, setTotalToPay] = useState(0);
  const [deliveryType, setDeliveryType] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openLoginDialog, setLoginDialog] = useState(false);
  const [openOTPDialog, setOTPDialog] = useState(false);
  const [guestPayload, setGuestPayload] = useState();
  const handleClose = () => {
    setOpenDialog(false);
    // setSelectedValue(value);
  };
  const handleDetailOpen = () => {
    setOpenDialog(true);
  };
  const handleLoginClose = () => {
    setLoginDialog(false);
  };
  const handleLoginOpen = () => [setLoginDialog(true)];
  const handleOTPOpen = (payload: any) => {
    setOpenDialog(false);
    setGuestPayload(payload);
    setOTPDialog(true);
  };
  const handleOTPClose = () => {
    setOTPDialog(false);
  };
  function changeCartQuantity(type: string, medicine: IMedicine) {
    if (type === "increment") {
      dispatch(addToCart(medicine) as any);
    }
    if (type === "decrement") {
      const index = cart.findIndex((ele: any) => ele.id === medicine.id);
      if (index !== -1) dispatch(removeFromCart(medicine) as any);
    }
  }
  useEffect(() => {
    let listToDisplay = cart.filter(
      (medicineInCart: IMedicine) => medicineInCart.quantity !== 0
    );
    setCartItemList(listToDisplay);
    let totalCostOfItem = 0;
    cart.forEach((medicine: any) => {
      totalCostOfItem +=
        parseFloat(medicine.customer_price) * medicine.quantity;
    });
    setItemTotal(totalCostOfItem);
    // setItemTotal(Math.ceil(totalCostOfItem));
  }, [cart]);
  useEffect(() => {
    setTotalToPay(itemTotal + deliveryFee);
  }, [itemTotal, deliveryFee]);
  useEffect(() => {
    localStorage.setItem("delivery-type", deliveryType);
    if (deliveryType === "Delivery") setDeliveryFee(150);
    else setDeliveryFee(0);
  }, [deliveryType]);
  const handleCheckout = () => {
    if (cartItemList.length === 0) {
      showAlert(2, "Please add medicine in cart");
      return;
    }
    if (deliveryType === "") {
      showAlert(2, "Select Delivery Method");
      return;
    }

    if (getUserStatus()) navigate("/checkout");
    else {
      setOpenDialog(true);
    }
  };
  return (
    <div className="page-cart">
      <h3 className="mb-20">
        <span className="color-primary justify-start">
          <ArrowBackIcon
            className="pointer mr-10"
            titleAccess="Go Back"
            onClick={() => navigate(-1)}
          />{" "}
          My Cart
        </span>
      </h3>
      <div className="cart-content">
        {cartItemList.length > 0 && (
          <div className="upper-portion">
            <div
              className="left-portion"
              style={{
                width: cartItemList.length === 0 ? "100%" : "",
              }}
            >
              {cartItemList.length > 0 && (
                <div className="select-delivery-type">
                  <h1 className="mb-10 semi-bold">Select Delivery Method</h1>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={deliveryType ? deliveryType : ""}
                    // defaultValue={deliveryType ? deliveryType : ""}
                    onChange={(e: any) => {
                      setDeliveryType(e.target.value);
                    }}
                  >
                    <FormControlLabel
                      className="bold"
                      value="Delivery"
                      control={<Radio />}
                      label="Delivery (Cost 150 KES)"
                    />
                    <FormControlLabel
                      className="bold"
                      value="Self Collect"
                      control={<Radio />}
                      label="Self Collect"
                    />
                  </RadioGroup>
                </div>
              )}
            </div>
            {cartItemList.length > 0 && (
              <div className="price-card">
                <h1 className="mb-10 semi-bold">Cart Breakdown</h1>
                <ul>
                  <li>
                    <h2>Item (s) Total</h2>
                    <h2>{itemTotal?.toFixed(2)} KES</h2>
                  </li>
                  <li>
                    <h2>Delivery Fee</h2>
                    <h2>{deliveryFee} KES</h2>
                  </li>
                  <li>
                    <h2>To Pay</h2>
                    <h2>{totalToPay?.toFixed(2)} KES</h2>
                  </li>
                </ul>
                <button
                  className="btn btn-white w-100 mt-10"
                  onClick={handleCheckout}
                >
                  Checkout
                </button>
              </div>
            )}
          </div>
        )}
        <div className="skeu-card mb-30 w-100" style={{ padding: "20px 0px" }}>
          {cartItemList.length > 0 && (
            <>
              <h1 className="justify-start mb-20 ml-30 bold">Items in Cart</h1>

              <CartTable page="my-cart" />
            </>
          )}
          {cartItemList.length === 0 && (
            <div className="empty-cart center">
              <img
                style={{ width: "80px", margin: "30px auto 20px" }}
                src={emptyCart}
                alt="Empty Cart"
              />
              <h2>Nothing in Cart</h2>
              <button
                className="btn btn-primary"
                style={{ margin: "20px auto 20px" }}
                onClick={() => {
                  navigate("/home");
                }}
              >
                Continue Your Shopping
              </button>
            </div>
          )}
        </div>
      </div>
      <AddDetailDialog
        open={openDialog}
        onClose={handleClose}
        handleLoginOpen={handleLoginOpen}
        handleOTPOpen={handleOTPOpen}
      />
      <LoginDialog
        openDialog={openLoginDialog ? true : false}
        onClose={handleLoginClose}
      />
      <OtpDialog
        openDialog={openOTPDialog ? true : false}
        onClose={handleOTPClose}
        guestPayload={guestPayload}
        openDetailDialog={handleDetailOpen}
        from="cart"
      ></OtpDialog>
    </div>
  );
}

export default Cart;
