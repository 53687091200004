import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import Cropper from "react-cropper";
import Resizer from "react-image-file-resizer";
import ModalWrapper from "../ModalWrapper/ModalWrapper";
import "cropperjs/dist/cropper.css";

type ImageCroperProps = {
  src: any;
  open: boolean;
  handleClose: () => void;
  uploadImage: (files: any) => Promise<unknown>;
};

function ImageCroper({
  src,
  open,
  handleClose,
  uploadImage,
}: ImageCroperProps) {
  const [imageProcessing, setImageProcessing] = useState(false);
  const [cropper, setCropper] = useState<any>("");
  const [image, setImage] = useState<any>("");

  useEffect(() => {
    if (src) {
      let file = src?.fileList[0];
      let reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  }, [src]);

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setImageProcessing(true);
      const newFileName = new Date().getTime();
      const urlimg = () => {
        return fetch(cropper.getCroppedCanvas().toDataURL())
          .then(function (res) {
            return res.arrayBuffer();
          })
          .then(function (buf) {
            return new File([buf], `${newFileName}.jpeg`, {
              type: "image/jpeg",
            });
          });
      };
      urlimg().then((file) => {
        try {
          Resizer.imageFileResizer(
            file,
            1000,
            1000,
            "JPEG",
            100,
            0,
            (url: any) => {
              uploadImage(url)
                .then((_response: any) => {
                  setImageProcessing(false);
                  setImage("");
                  handleClose();
                })
                .catch((_error: any) => {
                  setImageProcessing(false);
                });
            },
            "base64",
            200,
            200
          );
        } catch (err) {
          setImageProcessing(false);
        }
      });
    }
  };

  return (
    <ModalWrapper
      open={open}
      handleClose={handleClose}
      sx={{
        width: { sm: 500 },
        borderRadius: (theme) => theme.spacing(0.8),
        boxShadow: "var(--link-box-shadow)",
      }}
    >
      <>
        <Cropper
          style={{ height: 400, width: "100%" }}
          initialAspectRatio={1 / 1}
          aspectRatio={1 / 1}
          preview=".img-preview"
          src={image && image}
          viewMode={2}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false}
          onInitialized={(instance) => {
            setCropper(instance);
          }}
          guides={true}
        />
        <Box sx={{ my: 0.5, textAlign: "center" }}>
          <Button
            size="small"
            variant="contained"
            sx={{
              color: "var(--white)",
              fontWeight: "bold",
            }}
            onClick={() => {
              setImage("");
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={imageProcessing}
            size="small"
            variant="contained"
            sx={{
              color: "var(--white)",
              fontWeight: "bold",
              ml: 1.5,
            }}
            onClick={getCropData}
          >
            Upload
          </Button>
        </Box>
      </>
    </ModalWrapper>
  );
}

export default ImageCroper;
