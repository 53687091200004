import { LoginService } from "../../services/OnBoarding/loginService";
import {
  ACTIVE_USER,
  commonAction,
  GUEST_USER,
  LOGINWITHOTP,
} from "../../utility/actionConstant";
import { getAccessTokenFromLS } from "../../utility/util";
import { commonActionCreator } from "./commonActionCreator";
const loginService = new LoginService();
// to get otp for verification
export function getOtpAction(
  phoneNo: string,
  phoneCode: string,
  countryCode: string
) {
  return () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await loginService.getOtp(
          phoneNo,
          phoneCode,
          countryCode
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}

// to verify OTP for signin/signup
export function loginWithOtpAction(
  otp: string,
  phoneCode: string,
  phoneNumber: string,
  countryCode: string
) {
  return (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      dispatch(
        commonActionCreator(LOGINWITHOTP)(commonAction.IS_API_LOADING, true)
      );
      try {
        const response = await loginService.loginWithOtp(
          otp,
          phoneCode,
          phoneNumber,
          countryCode
        );
        dispatch(
          commonActionCreator(LOGINWITHOTP)(
            commonAction.SET_API_DATA,
            response?.data
          )
        );
        resolve(response);
      } catch (error) {
        reject(error);
        dispatch(
          commonActionCreator(LOGINWITHOTP)(commonAction.SET_API_ERROR, error)
        );
      }
    });
  };
}

// to complete signup
export function postUserDetailAction(payload: any) {
  return () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await new LoginService().postUserDetail(payload);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export const showLogin = () => async (dispatch: any) => {
  const isGuestUser = localStorage.getItem("guest_user");
  const token = getAccessTokenFromLS();
  // if (isGuestUser || !userStatus) {
  //   dispatch({
  //     type: GUEST_USER,
  //     payload: { showLogin: true, showProfile: false },
  //   });
  // } else {
  //   dispatch({
  //     type: ACTIVE_USER,
  //     payload: { showLogin: false, showProfile: true },
  //   });
  // }
  // if not on signup page
  if (token && !window.location.hash.includes("sign-up")) {
    dispatch({
      type: ACTIVE_USER,
      payload: { showLogin: false, showProfile: true },
    });
  } else {
    dispatch({
      type: GUEST_USER,
      payload: { showLogin: true, showProfile: false },
    });
  }
};
