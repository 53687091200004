import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  IMedicine,
  IMedicineList,
} from "../../pages/Landing/model/searchMedicineModel";
import {
  addToCart,
  deleteFromCart,
  removeFromCart,
} from "../../redux/actions/cartActions";
import {
  productImg,
  emptyCart,
  plusIcon,
  minusIcon,
  dustbinIcon,
} from "../../utility/images";

function CartTable(props: any) {
  const { page } = props;
  const dispatch = useDispatch();
  const [cartItemList, setCartItemList] = useState<IMedicineList[]>([]);
  const cart = useSelector((state: any) => state.cart.cart);
  useEffect(() => {
    let listToDisplay = cart.filter(
      (medicineInCart: IMedicine) => medicineInCart.quantity !== 0
    );
    setCartItemList(listToDisplay);
    // let totalCostOfItem = 0;
    // cart.forEach((medicine: any) => {
    //   totalCostOfItem +=
    //     parseFloat(medicine.customer_price) * medicine.quantity;
    // });
    // setItemTotal(totalCostOfItem);
    // setItemTotal(Math.ceil(totalCostOfItem));
  }, [cart]);

  function changeCartQuantity(type: string, medicine: IMedicine) {
    if (type === "increment") {
      dispatch(addToCart(medicine) as any);
    }
    if (type === "decrement") {
      const index = cart.findIndex((ele: any) => ele.id === medicine.id);
      if (index !== -1) dispatch(removeFromCart(medicine) as any);
    }
  }
  return (
    <div className="table-cover">
      <table>
        <thead>
          <tr>
            <th>Item</th>
            <th>Price</th>
            <th className="for-desktop">Quantity</th>
            {page === "my-cart" && (
              <th className="for-desktop center">Action</th>
            )}
          </tr>
        </thead>
        <tbody>
          {cartItemList.map((medicine: any) => (
            <tr key={medicine.id}>
              <td>
                <div className="flex-start">
                  <img
                    src={
                      medicine?.image
                        ? process.env.REACT_APP_API_URL + medicine?.image
                        : productImg
                    }
                    title="Product"
                    alt="MedicineImg"
                    className="item-img"
                  />
                  <h3 className="ml-20">
                    {medicine.name}-{medicine.api}
                    <div className="for-mobile">
                      {page === "my-cart" ? (
                        <div className="btn-cover">
                          <img
                            src={minusIcon}
                            alt=""
                            onClick={() => {
                              changeCartQuantity("decrement", medicine);
                            }}
                          />
                          <span>{medicine.quantity}</span>
                          <img
                            src={plusIcon}
                            alt=""
                            onClick={() => {
                              changeCartQuantity("increment", medicine);
                            }}
                          />
                        </div>
                      ) : (
                        <h2 className="bold">Qty. {medicine.quantity}</h2>
                      )}
                    </div>
                  </h3>
                </div>
              </td>
              <td>
                <h2 className="bold color-primary">
                  {(medicine.customer_price * medicine.quantity).toFixed(2)}{" "}
                  {/* {Math.ceil(medicine.customer_price * medicine.quantity)}{" "} */}
                  KES
                </h2>
                {page === "my-cart" ? (
                  <div className="for-mobile">
                    <img
                      src={dustbinIcon}
                      className="del-icon pointer"
                      alt=""
                      onClick={() => {
                        dispatch(deleteFromCart(medicine));
                      }}
                      style={{
                        margin: "auto",
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}
              </td>
              {page === "my-cart" ? (
                <td className="for-desktop">
                  <div className="btn-cover">
                    <img
                      src={minusIcon}
                      alt=""
                      onClick={() => {
                        changeCartQuantity("decrement", medicine);
                      }}
                    />
                    <span>{medicine.quantity}</span>
                    <img
                      src={plusIcon}
                      alt=""
                      onClick={() => {
                        changeCartQuantity("increment", medicine);
                      }}
                    />
                  </div>
                </td>
              ) : (
                <td className="for-desktop">
                  <h2 className="bold" style={{ marginLeft: "22%" }}>
                    {medicine.quantity}
                  </h2>
                </td>
              )}
              {page === "my-cart" && (
                <td className="for-desktop">
                  <img
                    src={dustbinIcon}
                    className="del-icon pointer"
                    alt=""
                    onClick={() => {
                      dispatch(deleteFromCart(medicine));
                    }}
                    style={{
                      margin: "auto",
                    }}
                  />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default CartTable;
