import { Box, Table, TableBody, TableContainer, TableHead, Typography } from "@mui/material";
import TableCellWrapper from "../../components/table/TableCellWrapper";
import TableRowWrapper from "../../components/table/TableRowWrapper";
import { Drug } from "../../types";

type ItemListProps = {
  data: Drug[];
};

function ItemList({ data }: ItemListProps) {
  return (
    <Box sx={{ my: 1.5, pb: 1.5 }} className="skeu-card">
      <h2 className="mb-10">Items</h2>
      <TableContainer>
        <Table sx={{ minWidth: 250 }}>
          <TableHead>
            <TableRowWrapper>
              <TableCellWrapper>Drug</TableCellWrapper>
              <TableCellWrapper>Price</TableCellWrapper>
              <TableCellWrapper>Quantity</TableCellWrapper>
              <TableCellWrapper>Total Price</TableCellWrapper>
            </TableRowWrapper>
          </TableHead>
          <TableBody>
            {data?.length > 0 ? (
              data.map((item, index) => (
                <TableRowWrapper key={index}>
                  <TableCellWrapper>{item?.drug_name ?? "N/A"}</TableCellWrapper>
                  <TableCellWrapper>{(item?.price / parseInt(item?.quantity)).toFixed(2) ?? "N/A"}</TableCellWrapper>
                  <TableCellWrapper>{item?.quantity ?? "N/A"}</TableCellWrapper>
                  <TableCellWrapper>{item?.price ?? "N/A"}</TableCellWrapper>
                </TableRowWrapper>
              ))
            ) : (
              <TableRowWrapper>
                <TableCellWrapper colSpan={11}>No data available</TableCellWrapper>
              </TableRowWrapper>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default ItemList;
