import React from "react";
import Dialog from "@mui/material/Dialog";
import { DialogContent, DialogTitle } from "@mui/material";

function LearnMoreDialog(props: any) {
  const { openDialog, onContinue, openMoreContent, pharmacyName } = props;
  console.log("openDialog", openDialog);
  return (
    <Dialog open={openDialog} fullWidth maxWidth="xs">
      <DialogTitle>
        <h2>Thank you for using {pharmacyName}.</h2>
      </DialogTitle>
      <DialogContent>
        <h3>
          Please note that some brands may be substituted as per or drug
          formulary guidelines (all substitutions done adhere to principle of
          ethical substitution).{" "}
        </h3>
        <div className="flex-center mt-30">
          <button className="btn btn-primary" onClick={onContinue}>
            Continue
          </button>
          &nbsp;&nbsp; &nbsp;&nbsp;
          <button className="btn btn-primary" onClick={openMoreContent}>
            Learn More
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default LearnMoreDialog;
