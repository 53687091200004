import axios from "axios";
import { baseUrl, urlEndpoints } from "../../api/endpoints";
export default class CountryService {
  getCountryDetails = async () => {
    try {
      const response = await axios.get(`${baseUrl}${urlEndpoints.SETTINGS}`);
      return response && response.data;
    } catch (err) {
      throw err;
    }
  };
}
