import { Grid } from "@mui/material";
import { Formik, Form, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ReducersModal } from "../../../modal";
import { currentDate } from "../../../utility/commonFuntion";
import FORM_FIELD from "../../../validation/formFiled";
import SCHEMA from "../../../validation/schema";
import InputFiled from "../InputFiled/InputFiled";

type FilterDateProps = {
  handleSubmit: (values: typeof FORM_FIELD.FILTER_DATE, action: FormikHelpers<typeof FORM_FIELD.FILTER_DATE>) => void;
  handleClear: () => void;
};

function FilterDate({ handleSubmit, handleClear }: FilterDateProps) {
  const [initialValues, setInitialValues] = useState(FORM_FIELD.FILTER_DATE);
  const { fromDate, toDate } = useSelector((state: ReducersModal) => state.userOrderReducer);

  useEffect(() => {
    setInitialValues({
      fromDate,
      toDate,
    });
  }, [fromDate, toDate]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SCHEMA.FILTER_DATE}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, isSubmitting }) => (
        <Form>
          <Grid container spacing={{ sm: 2, xs: 1 }}>
            <Grid item xs={12} md={6} lg={4}>
              <h4 className="mb-5">From Date</h4>
              <InputFiled
                placeholder="From Date"
                type="date"
                fullWidth
                name="fromDate"
                size="small"
                minDate={values.toDate || currentDate()}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <h4 className="mb-5">To Date</h4>
              <InputFiled
                placeholder="To Date"
                type="date"
                fullWidth
                name="toDate"
                size="small"
                minDate={currentDate()}
                // maxDate={values.fromDate}
              />
            </Grid>
            <Grid item md={12} lg={4}>
              <div className="justify-end">
                <button
                  type="button"
                  onClick={handleClear}
                  className="btn btn-secondary mr-10 small btn-margin"
                  disabled={isSubmitting}
                >
                  Clear
                </button>
                <button className="btn btn-primary small btn-margin" type="submit" disabled={isSubmitting}>
                  {isSubmitting ? "Loading..." : "Apply"}
                </button>
              </div>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default FilterDate;
