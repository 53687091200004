import FormCard from "./FormCard";
import { loginBanner } from "./../../../utility/images";
import "./../Login.scss";

function Signup() {
  return (
    <div className="page-login">
      {/* {isLoading ? <Loader /> : ""} */}
      <div className="left-portion">
        <img src={loginBanner} />
      </div>
      <div className="right-portion">
        <div className="right-portion-content">
          <h1 className="bold mb-10">Sign Up</h1>
          <h3 className="subtext mb-20">
            Please enter your details here to proceed
          </h3>
          <FormCard />
        </div>
      </div>
    </div>
  );
}

export default Signup;
