import * as Yup from "yup";

const SIGN_UP = () => {
  return Yup.object().shape({
    phoneCode: Yup.string().required("Please enter phone code"),
    phoneNumber: Yup.string().required("Please enter phone no."),
    title: Yup.string().required("Please enter title"),
    firstName: Yup.string().trim().required("Please enter first name").nullable(),
    lastName: Yup.string().trim().required("Please enter last name").nullable(),
    gender: Yup.string().trim().required("Please select gender").nullable(),
    city: Yup.object().required("Please enter city").nullable(),
    dateOfBirth: Yup.date().required("Please enter date of birth").nullable().typeError("Invaild!"),
    email: Yup.string().trim().email().required("Please enter correct email address"),
  });
};
const MY_PROFILE = () => {
  return Yup.object().shape({
    phoneCode: Yup.string().required("Please enter phome code"),
    phoneNumber: Yup.string().required("Please enter phone no."),
    title: Yup.string().required("Please enter title"),
    firstName: Yup.string().trim().required("Please enter first name").nullable(),
    lastName: Yup.string().trim().required("Please enter last name").nullable(),
    gender: Yup.string().trim().required("Please select gender").nullable(),
    city: Yup.object().required("Please enter city").nullable(),
    dateOfBirth: Yup.date().required("Please enter date of birth").nullable().typeError("Invaild!"),
    email: Yup.string().trim().email().required("Please enter correct email address"),
  });
};
const CHANGE_PHONE_NUMBER = () => {
  return Yup.object().shape({
    phoneCode: Yup.string().required("Please enter phome code"),
    phoneNumber: Yup.string().required("Please enter phone no."),
  });
};

const CHECKOUT_DETAIL = (phoneLength: number) => {
  return Yup.object().shape({
    title: Yup.string().required("Please enter title"),
    name: Yup.string().required("Please enter first name"),
    lastName: Yup.string().required("Please enter last name"),
    phoneNumber: Yup.string()
      .required(`Please enter phone number`)
      .min(phoneLength, `Phone number should contain ${phoneLength} digit`),
    // .max(phoneLength),
    email: Yup.string().trim().email().required("Please enter correct email address"),
    gender: Yup.string().trim().required("Please select gender").nullable(),
    dateOfBirth: Yup.date().required("Please enter date of birth").nullable().typeError("Invalid Date!"),
    city: Yup.object().required("Please enter city").nullable(),
  });
};

const FILTER_DATE = () =>
  Yup.object().shape({
    fromDate: Yup.date().required("").nullable().typeError(""),
    toDate: Yup.date().required("").nullable().typeError(""),
  });

const SCHEMA = {
  SIGN_UP,
  CHECKOUT_DETAIL,
  MY_PROFILE,
  CHANGE_PHONE_NUMBER,
  FILTER_DATE,
};

export default SCHEMA;
