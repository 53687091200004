import { axiosInstance } from "../api/axiosInstance";
import { urlEndpoints } from "../api/endpoints";
import { getLocalStorageKeyData } from "../utility/util";

export class PaymentService {
  payment = async (payload: any) => {
    const { amount, pay_type, orderId } = payload;
    return axiosInstance.patch(
      `${urlEndpoints.ORDER}/${orderId}`,
      { amount, pay_type },
      {
        headers: { "LiviaApp-city": getLocalStorageKeyData("user_city") || "" },
      }
    );
  };
}
