import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import {
  getAuthHeader,
  getLocalStorageKeyData,
  getWebsiteCountryCodeFromLS,
  setAccessToken,
  setRefreshToken,
} from "../utility/util";
import { baseUrl, urlEndpoints } from "./endpoints";

export const axiosInstance = axios.create({
  baseURL: baseUrl,
  timeout: 45000,
  headers: {
    "Content-Type": "application/json",
    "LiviaApp-language": "en",
    "LiviaApp-APIVersion": "2.0",
    "LiviaApp-timezone": "330",
    "LiviaApp-country": getWebsiteCountryCodeFromLS(),
  },
});

const requestConfig = (config: AxiosRequestConfig) => {
  if (config?.headers) config.headers["LiviaApp-Token"] = getAuthHeader();
  return config;
};

axiosInstance.interceptors.request.use((config) => requestConfig(config));

const responseConfig = (config: AxiosResponse) => {
  return config;
};

// response interceptor

const refreshTokenHandler = async () => {
  const params = {
    refresh_token: getLocalStorageKeyData("refresh_token"),
    phone_code: getLocalStorageKeyData("user_phoneCode"),
    phone_number: getLocalStorageKeyData("user_phoneNo"),
    os_type: 3,
    phone_id: getLocalStorageKeyData("phone_id"),
    user_role: 3,
  };
  return axiosInstance.put(urlEndpoints.REFRESH_TOKEN, params);
};

const responseError = async (error: AxiosError) => {
  const originalRequest = error.config;
  if (error.response?.status === 401) {
    const refreshToken = await refreshTokenHandler();
    if (refreshToken.status === 200) {
      setAccessToken(refreshToken?.data?.access_token);
      setRefreshToken(refreshToken?.data?.refresh_token);
      return axiosInstance(originalRequest);
    }
  }
  return Promise.reject(error);
};

axiosInstance.interceptors.response.use(
  (config) => responseConfig(config),
  (error) => responseError(error)
);
