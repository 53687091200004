import { Box } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import ROUTES_NAME from "../../utility/routesName";
import LOCAL_IMAGES from "../../utility/local.images";
import "./Profile.scss";
import { useEffect } from "react";

const useStyles = makeStyles(() =>
  createStyles({
    link: {
      display: "inline-block",
      width: "100%",
      marginTop: 10,
      borderRadius: 5,
      boxShadow: "var(--link-box-shadow)",
      "&.active": {
        backgroundColor: "var(--primary)",
        "& .activeText": {
          color: "var(--white)",
        },
        "& .active-img": {
          display: "block",
        },
        "& .img": {
          display: "none",
        },
      },
      "& .active-img": {
        display: "none",
      },
    },
    linkBox: {
      display: "flex",
      alignItems: "center",
      padding: 15,
      backgroundColor: "inherit",
      borderRadius: 5,
    },
    linkText: {
      "&.MuiTypography-root": {
        font: `normal bold 16px Barlow, sans-serif`,
        cursor: "pointer",
        color: "var(--primary)",
        marginLeft: 15,
      },
    },
  })
);

function Profile() {
  const classes = useStyles();
  const location = useLocation();

  const reuseLinkContent = (linkName: string, icon: string, activeIcon: string) => {
    return (
      <Box className={classes.linkBox}>
        <h4 className={`${classes.linkText} activeText`}>{linkName}</h4>
      </Box>
    );
  };
  useEffect(() => {}, [location]);
  return (
    <div className="page-my-profile">
      <div className="profile-content">
        <div className="left-portion skeu-card" >
          <h3 className="semi-bold">My Profile</h3>
          <hr className="mt-15 mb-10" />
          <Box sx={{ my: 1.5 }}>
            <NavLink to={ROUTES_NAME.PERSONAL_INFO} className={classes.link}>
              {reuseLinkContent("Personal Info", LOCAL_IMAGES.PROFILE_INACTIVE, LOCAL_IMAGES.PROFILE_ACTIVE)}
            </NavLink>
            <NavLink to={ROUTES_NAME.MY_ORDER} className={classes.link}>
              {reuseLinkContent("My Orders", LOCAL_IMAGES.PROFILE_INACTIVE, LOCAL_IMAGES.PROFILE_ACTIVE)}
            </NavLink>
          </Box>
        </div>
        <div className="right-portion">
          {/* <h3 className="semi-bold">
            {location.pathname.includes("detail")
              ? "Order Details"
              : location.pathname.includes("my-order")
              ? "My Orders"
              : "Personal Info"}
          </h3>
          <hr className="mt-15 mb-10" /> */}
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Profile;
