import React, { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import {
  Autocomplete,
  GoogleMap,
  Marker,
  useLoadScript,
  InfoBox,
} from "@react-google-maps/api";
import Geocode from "react-geocode";
import showAlert from "../../../utility/alert";
import { getCityList } from "../../Onboarding/Signup/action";
import { setUserCity } from "../../../utility/util";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { useSelector } from "react-redux";
import { getAddress } from "../../../redux/actions/addressAction";
import { useDispatch } from "react-redux";
import { addressType } from "../../../utility/staticData";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { FormHelperText } from "@mui/material";

const mapStyles = {
  height: "350px",
  width: "100%",
};
interface IAddress {
  id?: string;
  user_id?: string;
  latitude: string;
  longitude: string;
  address: string;
  address2: string;
  create_date?: string;
  landmark: string;
  address_type: string;
  address_title: string;
}
function MapAddress(props: any) {
  const [addError, setAddError] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const dispatch = useDispatch();
  const [deliveryAddress, setDeliveryAddress] = useState<any>();
  const [addressList, setAddressList] = useState<IAddress[]>([]);
  const [errorAtDelivery, setErrorAtDelivery] = useState<any>(null);
  const { dialogHandler, addressFromCheckout } = props;
  const [savedAddress, setSavedAddress] = useState<any>({});
  const [glow, setGlow] = useState(false);
  const [markerLabel, setMarkerLabel] = useState("");
  const [marker, setMarker] = useState<any>();
  const [addressExist, setAddressExist] = useState(false);
  const [addressFromMap, setAddressFromMap] = useState({
    address: "",
    address_title: "",
    city: "",
    landmark: "",
    latitude: 0,
    longitude: 0,
  });
  const [currentPosition, setCurrentPosition] = useState<any>({});
  const data = useSelector((state: any) => state.addressDetailReducer.apiData);
  const [type, setType] = useState<any>();
  const [showMarker, setShowMarker] = useState(false);
  const [cityOptions, setCityOptions] = useState<any[]>([]);
  const [addresssTypeSelected, setAddressTypeSelected] = useState(false);
  const [currentLocProvided, setCurLocProvided] = useState(false);
  const [showAddressField, setShowAddressField] = useState(false);
  const [deliveryButtonText, setButtonText] = useState(
    "Deliver at my current location"
  );
  const [isMapDrag, setMapDrag] = useState(false);
  const [libraries] = useState<
    ["places" | "drawing" | "geometry" | "localContext" | "visualization"]
  >(["places"]);
  const mapkey = "AIzaSyDbc6vdnB49ZMPq1RjxfsbMFrOLihSH5zw";

  Geocode.setApiKey(mapkey);
  const { isLoaded } = useLoadScript({
    region: "Ken",
    googleMapsApiKey: mapkey,
    libraries, // ,
  });
  const [addressField, setAddressField] = useState("");
  const [titleField, setTitleField] = useState("");
  const [autocomplete, setAutoComplete] = useState<any>({});
  const [mapRef, setMapRef] = React.useState<any>(null);
  const [mapCenter, setMapCenter] = useState<any>({});
  const defaultLocation = { lat: -4.04374, lng: 39.658871 };

  useEffect(() => {
    dispatch(getAddress());
    navigator.geolocation.getCurrentPosition(success, errorCallback);
  }, []);
  const errorCallback = () => {
    setCurLocProvided(false);
    // setButtonText("Deliver Here");
    setCurrentPosition({
      lat: -4.04374,
      lng: 39.658871,
    });

    Geocode.fromLatLng("-4.043740", "39.658871").then((response) => {
      const address = response.results[0].formatted_address;
      setDeliveryAddress((prev: any) => ({
        latitude: "-4.043740",
        longitude: "39.658871",
        address: address,
        address2: "",
        address_type: prev?.address_type ? prev?.address_type : "",
        address_title: prev?.address_title ? prev?.address_title : "",
        landmark: "",
      }));
    });
  };
  useEffect(() => {
    setAddressList(
      data?.data?.body.filter((address: any) => address.title !== "Other")
    );
  }, [data]);
  useEffect(() => {}, [addressList]);
  const handleRadioChange = (e: any) => {
    const { value } = e.target;

    if (addressList.length > 0) {
      const typeID = addressList.find((address) => address.id === value); //value === "Home" ? "1" : value === "Work" ? "2" : "3";
      setType({
        address_title: typeID?.address_title,
        address_type: typeID?.address_type,
        id: typeID?.id,
      });
      setAddressTypeSelected(true);
      const index = addressList?.findIndex(
        (address: IAddress) => address?.id === typeID?.id
      );
      if (index !== -1 && !showAddressField) {
        setDeliveryAddress(addressList[index]);
        setCurrentPosition({
          lat: parseFloat(addressList[index].latitude),
          lng: parseFloat(addressList[index].longitude),
        });
        setShowAddressField(false);
        setButtonText(`Deliver at ${addressList[index].address_title} `);
        setAddressExist(true);

        // setErrorAtDelivery(false);
      } else {
        if (!showAddressField)
          navigator.geolocation.getCurrentPosition(success, errorCallback);
        // setDeliveryAddress((prev: any) => ({
        //   ...prev,
        //   address_title: value,
        //   address_type: typeID,
        // }));
        setAddressExist(false);
        // setErrorAtDelivery(true);
      }
    }
  };
  const newAddressSelect = (e: any) => {
    const { value } = e.target;
    const typeID = value === "Home" ? "1" : value === "Work" ? "2" : "3";
    setType({ address_title: value, address_type: typeID });
    setAddressTypeSelected(true);
  };
  useEffect(() => {
    setDeliveryAddress((prev: any) => ({
      ...prev,
      address_type: type?.address_type,
      address_title: type?.address_title,
      id: type?.id,
    }));
  }, [type]);

  useEffect(() => {
    getCityList().then((res: any) => {
      setCityOptions(res?.data?.body);
    });
  }, []);
  useEffect(() => {
    // setMarker(new google.maps.Marker({ label: { text: markerLabel } }));
  }, [markerLabel]);
  useEffect(() => {
    setSavedAddress({ ...addressFromCheckout });
    if (addressFromCheckout.latitude) setShowMarker(true);
  }, [addressFromCheckout]);
  useEffect(() => {
    // console.log("deliveryAddress", deliveryAddress);
  }, [deliveryAddress]);

  useEffect(() => {
    if (savedAddress.latitude !== 0 && savedAddress.latitude !== 0) {
      setCurrentPosition({
        lat: savedAddress.latitude,
        lng: savedAddress.longitude,
      });
      setAddressFromMap((prev: any) => ({
        ...savedAddress,
      }));
    } else {
      // setCurrentPosition({
      //   lat: -4.043740,
      //   lng: 39.658871,
      // });
    }
  }, [savedAddress]);
  const retriveAddress = (formattedAddress: any, addressComponent: any) => {
    const addressArray = formattedAddress?.split(", ") || [];
    const addressLength = addressArray.length;
    let address = "";
    if (addressLength === 3) {
      address = addressArray[0];
    }
    if (addressLength > 3) {
      for (let i = 0; i <= addressLength - 3; i++) {
        if (i < addressLength - 3) address += `${addressArray[i]}, `;

        if (i === addressLength - 3) address += addressArray[i];
      }
    }
    const city = addressComponent?.find(
      (component: any) => component?.types[0] === "locality"
    )?.long_name;
    return { address, city, formattedAddress };
  };

  useEffect(() => {
    if (currentPosition.lat && currentPosition.lng) {
      setErrorAtDelivery(false);
      const latAsString = currentPosition.lat.toString();
      const lngAsString = currentPosition.lng.toString();

      Geocode.fromLatLng(latAsString, lngAsString).then(
        (response) => {
          const { address, city, formattedAddress } = retriveAddress(
            response.results[0].formatted_address,
            response.results[0].address_components
          );
          setMarker(response.results[0].formatted_address);
          setAddressFromMap((prev) => ({
            address_title: prev.address_title,
            address: address,
            longitude: currentPosition.lng,
            latitude: currentPosition.lat,
            city: city,
            landmark: "",
            full_address: formattedAddress,
          }));

          setShowMarker(true);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, [currentPosition]);
  useEffect(() => {
    if (mapCenter.lat && mapCenter.lng) {
      setErrorAtDelivery(false);
      const latAsString = mapCenter.lat.toString();
      const lngAsString = mapCenter.lng.toString();

      Geocode.fromLatLng(latAsString, lngAsString).then(
        (response) => {
          const { address, city, formattedAddress } = retriveAddress(
            response.results[0].formatted_address,
            response.results[0].address_components
          );
          setMarker(response.results[0].formatted_address);
          setAddressFromMap((prev) => ({
            address_title: prev.address_title,
            address: address,
            longitude: mapCenter.lng,
            latitude: mapCenter.lat,
            city: city,
            landmark: "",
            full_address: formattedAddress,
          }));
          setDeliveryAddress((prev: any) => ({
            ...prev,
            latitude: mapCenter.lat,
            longitude: mapCenter.lng,
            address: formattedAddress,
          }));
          setShowMarker(true);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, [mapCenter]);
  useEffect(() => {
    if (
      addressFromMap.latitude === 0 ||
      addressFromMap.address_title === "" ||
      addressFromMap.address_title === undefined ||
      !showMarker
    ) {
      setGlow(false);
    } else setGlow(true);
  }, [addressFromMap]);

  const onMarkerDragEnd = (e: any) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    if (lat && lng) {
      Geocode.fromLatLng(lat, lng).then(
        (response: any) => {
          const address = response.results[0].formatted_address;
          setDeliveryAddress((prev: any) => ({
            latitude: lat.toString(),
            longitude: lng.toString(),
            address: response.results[0].formatted_address,
            address2: prev.address2,
            address_type: prev.address_type,
            address_title: prev.address_title,
            landmark: "",
          }));
          setButtonText("Deliver Here");
        },
        (error: any) => {
          console.error(error);
        }
      );
      setCurrentPosition({ lat, lng });
    }
  };
  const onLoad = (autocomplete: any) => {
    setAutoComplete(autocomplete);
  };
  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const getPlace = autocomplete.getPlace();
      const lat = getPlace.geometry.location.lat();
      const lng = getPlace.geometry.location.lng();
      if (type?.address_type) setType({});
      setAddressTypeSelected(false);
      // setFieldValue('physical_address', getPlace.formatted_address);
      setCurrentPosition({ lat, lng });
      setDeliveryAddress((prev: any) => ({
        latitude: lat.toString(),
        longitude: lng.toString(),
        address: getPlace.formatted_address,
        address2: "",
        address_type: prev?.address_type,
        address_title: prev?.address_title,
        landmark: "",
      }));
      setButtonText("Deliver Here");
      // setAddressFromMap((prev) => ({
      //   address_title: prev.address_title,
      //   address: getPlace.formatted_address,
      // }));
    } else {
      // console.log("Autocomplete is not loaded yet!");
    }
  };
  const success = (position: any) => {
    setCurLocProvided(true);
    setType({});
    setAddressTypeSelected(false);
    setButtonText("Deliver at my current location");
    const currentPosition = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };

    if (currentPosition.lat && currentPosition.lng) {
      Geocode.fromLatLng(currentPosition.lat, currentPosition.lng).then(
        (response) => {
          if (
            response.results[0].address_components.find(
              (comp: any) => comp.types[0] === "country"
            ).long_name !== "Kenya"
          ) {
            setCurrentPosition({ lat: -4.04374, lng: 39.658871 });
          } else {
            setCurrentPosition(currentPosition);
            const address = response.results[0].formatted_address;
            setDeliveryAddress((prev: any) => ({
              latitude: position.coords.latitude.toString(),
              longitude: position.coords.longitude.toString(),
              address: address,
              address2: "",
              address_type: prev?.address_type ? prev?.address_type : "",
              address_title: prev?.address_title ? prev?.address_title : "",
              landmark: "",
            }));
          }

          // setAddressFromMap((prev) => ({
          //   address_title: prev.address_title,
          //   address: address,
          // }));
        },
        (error) => {
          console.error(error);
        }
      );
    }
  };
  const sendMapAddressToLocal = () => {
    if (currentPosition?.lat === 0 || currentPosition.lat === undefined) {
      showAlert(2, "Please select address");
      setErrorAtDelivery(true);
      return;
    }
    if (!addresssTypeSelected) {
      showAlert(2, "Please select address type");
      return;
    }
    // setCurrentPosition(currentPosition);
    if (addressFromMap.address.length) {
      const cityIdObj = cityOptions.find(
        (city) => city.name === addressFromMap.city
      );
      setUserCity(cityIdObj?.id);
      localStorage.setItem(
        "adressFromMap",
        JSON.stringify({
          ...deliveryAddress,
        })
      );
      dialogHandler(deliveryAddress);
    } else {
      showAlert(2, "Please Select Address");
      return false;
    }
  };
  const showAddressFieldHandler = () => {
    if (addressList.length > 0 && type?.address_title) {
      const index = addressList?.findIndex(
        (address: IAddress) => address.address_title === type?.address_title
      );
      if (index !== -1) {
        setShowAddressField(false);
        dialogHandler(deliveryAddress);
      } else {
        if (mapCenter.lat !== -4.04374 && mapCenter.lng !== 39.658871) {
          setShowAddressField(true);
        } else {
          setShowAddressField(true);
          // setShowAddressField(false);
          // showAlert(2, "Please search address");
        }
      }
    } else if (mapCenter.lat !== -4.04374 && mapCenter.lng !== 39.658871) {
      setShowAddressField(true);
    } else {
      setShowAddressField(true);
      // setShowAddressField(false);
      // showAlert(2, "Please search address");
    }
  };
  const sendAddressHandler = () => {
    if (
      !addressField.trim() &&
      !titleField.trim() &&
      type?.address_title === "Other"
    ) {
      setAddError(true);
      setTitleError(true);
      return;
    }
    if (!addressField.trim()) {
      setAddError(true);
      return;
    }
    if (!titleField.trim() && type?.address_title === "Other") {
      setTitleError(true);
      return;
    }
    if (!addresssTypeSelected || !deliveryAddress.address_title) {
      showAlert(2, "Please select address type");
      return;
    }

    console.log("address for delivery", deliveryAddress);
    if (addAddressValidation()) dialogHandler(deliveryAddress);
  };
  const buttonTextHandler = () => {
    if (
      showAddressField ||
      addresssTypeSelected ||
      (isMapDrag && mapCenter.lat !== -4.04374 && mapCenter.lng !== 39.658871)
    )
      return "Proceed";
    else return "Set Your Location";
  };
  const addAddressValidation = () => {
    if (addressExist) return true;
    else if (
      !addressExist &&
      addresssTypeSelected &&
      addressField.trim() &&
      currentPosition.lat &&
      currentPosition.lat !== 0
    )
      return true;

    return false;
  };
  const buttonGlow = () => {
    if (
      (currentPosition.lat &&
        currentPosition.lat !== -4.04374 &&
        currentPosition.lng !== 39.658871) ||
      (mapCenter.lat &&
        mapCenter.lat !== -4.04374 &&
        mapCenter.lng !== 39.658871) ||
      addresssTypeSelected
    )
      return true;
    return false;
  };
  const options = { closeBoxURL: "", enableEventPropagation: true };

  const handleCenterChanged = () => {
    if (mapRef) {
      const newCenter = mapRef?.getCenter();
      setMapCenter({ lat: newCenter.lat(), lng: newCenter.lng() });
      setMapDrag(true);
    }
  };
  const handleOnLoad = (map: any) => {
    setMapRef(map);
  };
  return (
    <div>
      {isLoaded ? (
        <div
          style={{
            boxShadow: "0px 1px 2px 1px rgba(0,0,0,0.2)",
            position: "relative",
          }}
        >
          <GoogleMap
            ref={mapRef}
            mapContainerStyle={mapStyles}
            zoom={14}
            center={currentPosition}
            onCenterChanged={handleCenterChanged}
            onDragStart={() => {
              setType({});
              setButtonText("Deliver Here");
            }}
            onLoad={handleOnLoad}
            options={{ gestureHandling: "greedy" }}
          >
            <InfoBox onLoad={onLoad} options={options} position={mapCenter}>
              <div className="info-box">{marker}</div>
            </InfoBox>
            {showMarker && (
              <Marker
                key={"h"}
                // draggable={true}
                position={mapCenter}
                // onDragEnd={(e) => onMarkerDragEnd(e)}
                animation={google.maps.Animation.BOUNCE}
                onLoad={() => {
                  setMapCenter(currentPosition);
                }}
              ></Marker>
            )}
            {
              <Autocomplete
                onLoad={onLoad}
                restrictions={{ country: "Ken" }}
                onPlaceChanged={onPlaceChanged}
              >
                <input
                  type="text"
                  placeholder="Search Address"
                  style={{
                    boxSizing: `border-box`,
                    border: `1px solid transparent`,
                    width: `calc(100% - 75px)`,
                    height: `40px`,
                    padding: `0 12px`,
                    borderRadius: `2px`,
                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                    fontSize: `14px`,
                    fontWeight: "800",
                    outline: `none`,
                    textOverflow: `ellipses`,
                    position: "absolute",
                    left: "12px",
                    top: "10px",
                  }}
                />
              </Autocomplete>
            }
          </GoogleMap>
          <button
            className="btn btn-secondary small"
            style={{
              position: "absolute",
              bottom: "30px",
              minWidth: "55px",
              left: "10px",
            }}
            title="Set Current Location"
            onClick={() => {
              navigator.geolocation.getCurrentPosition(success);
            }}
          >
            <MyLocationIcon />
          </button>
        </div>
      ) : (
        <></>
      )}
      {showAddressField && (
        <>
          <br />
          <h3 className="mb-20 semi-bold">Address</h3>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                size="small"
                id="search-add"
                name="mobile"
                variant="outlined"
                placeholder="House/Flat/Block no."
                required
                error={addError}
                onChange={(e) => {
                  setDeliveryAddress((prev: any) => ({
                    ...prev,
                    address2: e.target.value,
                  }));
                  setAddressField(e.target.value);
                  setAddError(false);
                }}
              />
              <FormHelperText style={{ color: "red", fontSize: "12px" }}>
                {addError ? "Address is required" : ""}
              </FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                name="mobile"
                variant="outlined"
                placeholder="Landmark"
                onChange={(e) => {
                  setDeliveryAddress((prev: any) => ({
                    ...prev,
                    landmark: e.target.value,
                  }));
                }}
              />
            </Grid>
            {type?.address_title === "Other" && (
              <Grid item xs={12}>
                <TextField
                  size="small"
                  id="search-add"
                  name="title"
                  variant="outlined"
                  placeholder="Enter address type (eg. Office / Shop / Workplace etc.)"
                  required
                  error={titleError}
                  onChange={(e) => {
                    setDeliveryAddress((prev: any) => ({
                      ...prev,
                      address_title: e.target.value,
                    }));
                    setTitleField(e.target.value);
                    setTitleError(false);
                  }}
                />
                <FormHelperText style={{ color: "red", fontSize: "12px" }}>
                  {titleError ? "Title is required" : ""}
                </FormHelperText>
              </Grid>
            )}
          </Grid>
        </>
      )}
      <FormControl
        className="space-between flex-wrap"
        style={{ flexDirection: "row" }}
      >
        {!showAddressField && (
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            // defaultValue={addressFromMap.address_title}
            name="radio-buttons-group"
            onClick={handleRadioChange}
          >
            {addressList?.length
              ? addressList?.map(
                  (address: any) =>
                    address.address_title !== "Other" && (
                      <FormControlLabel
                        key={address.id}
                        value={address.id}
                        className={`btn small ${
                          address.id === type?.id
                            ? "btn-secondary"
                            : "btn-secondary-border"
                        }`}
                        control={<Radio />}
                        label={address.address_title}
                      />
                    )
                )
              : ""}
          </RadioGroup>
        )}
        {showAddressField && (
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            // defaultValue={addressFromMap.address_title}
            name="radio-buttons-group"
            onChange={newAddressSelect}
          >
            {addressType?.length
              ? addressType?.map((address: any) => (
                  <FormControlLabel
                    key={address.address_type}
                    value={address.address_title}
                    className={`btn small ${
                      address.address_title === type?.address_title
                        ? "btn-secondary"
                        : "btn-secondary-border"
                    }`}
                    control={<Radio />}
                    label={address.address_title}
                  />
                ))
              : ""}
          </RadioGroup>
        )}

        <button
          className="btn btn-glowing mt-20"
          style={{
            animation: buttonGlow() ? "glowing 2500ms infinite" : "",
            color: "white",
            background: "#b20000",
            border: "none",
          }}
          onClick={() => {
            // sendMapAddressToLocal();
            showAddressField ? sendAddressHandler() : showAddressFieldHandler();
          }}
        >
          {localStorage.getItem("delivery-type") === "Self Collect"
            ? buttonTextHandler()
            : deliveryButtonText}
        </button>
      </FormControl>
    </div>
  );
}

export default MapAddress;
