import CloseIcon from "@mui/icons-material/Close";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Fragment } from "react";

import ModalWrapper from "../ModalWrapper/ModalWrapper";

type PreviewImageProps = {
  open: boolean;
  image: string;
  handleClose: () => void;
};

function PreviewImage({ open, handleClose, image }: PreviewImageProps) {
  return (
    <ModalWrapper
      open={open}
      handleClose={handleClose}
      sx={{
        width: "fit-content",
        height: "auto",
        boxShadow: "none",
        outline: "none",
        background: "transparent",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <CloseIcon
        onClick={handleClose}
        fontSize="medium"
        sx={{
          position: "absolute",
          top: "-30px",
          right: "-30px",
          cursor: "pointer",
          zIndex: 1,
          color: "white",
          backgroundColor: "red",
          borderRadius: "5px",
        }}
      />
      <TransformWrapper>
        {({ zoomIn, zoomOut, resetTransform }) => {
          return (
            <Fragment>
              {/* <Box sx={{ textAlign: "center" }}>
                    <IconButton
                      sx={{ py: 0 }}
                      color="primary"
                      size="small"
                      onClick={() => zoomIn()}
                    >
                      <AddIcon />
                    </IconButton>
                    <IconButton
                      sx={{ py: 0 }}
                      color="primary"
                      size="small"
                      onClick={() => zoomOut()}
                    >
                      <RemoveIcon />
                    </IconButton>
                    <IconButton
                      sx={{ py: 0 }}
                      color="primary"
                      size="small"
                      onClick={() => resetTransform()}
                    >
                      <RotateLeftIcon />
                    </IconButton>
                  </Box> */}
              {/* <Box> */}
              <TransformComponent>
                <img
                  src={image}
                  alt="test"
                  style={{
                    width: "auto",
                    height: "auto",
                    maxWidth: "calc(100vw - 80px)",
                    maxHeight: "calc(100vh - 80px)",
                    objectFit: "cover",
                  }}
                />
              </TransformComponent>
              {/* </Box> */}
            </Fragment>
          );
        }}
      </TransformWrapper>
    </ModalWrapper>
  );
}

export default PreviewImage;
