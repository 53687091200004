import Dialog from "@mui/material/Dialog";
import { FormHelperText, Grid, Tooltip, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Formik, Form } from "formik";
import SCHEMA from "../../../validation/schema";
import { useEffect, useState } from "react";
import FORM_FIELD from "../../../validation/formFiled";
import { gender, salutations } from "../../../utility/staticData";
import InputFiled from "../../../components/formUI/InputFiled/InputFiled";
import SelectWrapper from "../../../components/formUI/Select/SelectWrapper";
import { useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import {
  getAge,
  getPhoneId,
  setAccessToken,
  setCountryCodeInLS,
  setGuestUser,
  setGuestUserData,
  setPhoneCodeLS,
  setPhoneNumberLS,
  setRefreshToken,
  setUserCity,
  setUserRoleIdInLS,
  setUserStatus,
} from "../../../utility/util";
import {
  getUserDetailByPhoneAction,
  registerGuestUserAction,
} from "../../../redux/actions/MyProfileAction";
import { useDispatch } from "react-redux";
import showAlert from "../../../utility/alert";
import AutoCompleteWrapper from "../../../components/formUI/autoCompleteWrapper/AutoCompleteWrapper";
import { getCityList } from "../../Onboarding/Signup/action";
import { subtractDate } from "../../../utility/commonFuntion";
import moment from "moment";
import { getOtpAction } from "../../../redux/actions/loginAction";
import {
  globalLoaderStart,
  globalLoaderEnd,
} from "../../../redux/reducers/globalReducer";
function AddDetailDialog(props: any) {
  const { open, onClose, handleLoginOpen, from, handleOTPOpen } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [initialValues] = useState(FORM_FIELD.CHECKOUT_DETAIL);
  const [cityOptions, setCityOptions] = useState<any[]>([]);
  const [disableContinue, setDisableContinue] = useState(false);
  const [isUserExist, setIsUserExist] = useState(false);
  const [phoneLength, setPhoneLength] = useState(9);
  const handleClose = () => {
    onClose();
  };
  useEffect(() => {
    getCityList().then((res: any) => {
      setCityOptions(res?.data?.body);
    });
  }, []);
  const handleSubmit = (values: any) => {
    console.log(values);
    setUserCity(values.city?.id);
    // const address = JSON.parse(localStorage.getItem("adressFromMap") as string);
    const guestUserPayload = {
      latitutde: "", //address.latitude.toString(),
      longitude: "", //address.longitude.toString(),
      age: getAge(new Date(values.dateOfBirth)),
      city_id: values.city?.id,
      country_code: "ke",
      date_birth: values.dateOfBirth ? values.dateOfBirth : "",
      email: values.email ? values.email.trim() : "",
      first_name: values.name ? values.name.trim() : "",
      last_name: values.lastName ? values.lastName.trim() : "",
      os_type: "3",
      phone_code: "254",
      phone_number:
        phoneLength === 10
          ? values.phoneNumber.substring(1)
          : values.phoneNumber,
      sex: values.gender,
      user_role: 4,
      phone_id: getPhoneId(),
      name_prefix: values.title,
    };
    console.log("guestUserPayload", guestUserPayload);
    dispatch(globalLoaderStart());
    dispatch(
      getOtpAction(
        phoneLength === 10
          ? values.phoneNumber.substring(1)
          : values.phoneNumber,
        "254",
        "ke"
      ) as any
    )
      .then((res: any) => {
        dispatch(globalLoaderEnd());
        handleOTPOpen(guestUserPayload);
      })
      .catch((err: any) => {
        dispatch(globalLoaderEnd());
      });

    // dispatch(registerGuestUserAction(guestUserPayload) as any)
    //   .then((res: any) => {
    //     console.log(res);
    //     setGuestUser(res?.data?.guest_user === 0 ? false : true);
    //     setGuestUserData(res?.data?.user);
    //     setUserCity(res?.data?.user?.city_id);
    //     setUserStatus(res?.data?.user?.user_status);
    //     setAccessToken(res?.data?.access_token);
    //     setRefreshToken(res?.data?.refresh_token);
    //     // setUserIdInLS(res?.data?.user?.id);
    //     setUserRoleIdInLS(res?.data?.user?.role_id);
    //     setPhoneNumberLS(res?.data?.user?.phone_number);
    //     setPhoneCodeLS(res?.data?.user?.phone_code);
    //     setCountryCodeInLS(res?.data?.user?.country_code);
    //     showAlert(1, res?.data?.messages[0]);
    //     if (from === "prescription") navigate("/select-address");
    //     else navigate("/checkout");
    //     handleClose();
    //   })
    //   .catch((error: any) => {
    //     if (
    //       error?.response?.data?.messages &&
    //       error?.response?.data?.messages[0]
    //     ) {
    //       showAlert(2, error?.response?.data?.messages[0]);
    //     }
    //   });
  };
  const phoneNumberValidator = (phone: string) => {
    if (phone[0] === "0") setPhoneLength(10);
    else setPhoneLength(9);
  };
  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <div className="dialog-content" style={{ padding: "30px 5%" }}>
          <h3 className="mb-20 center">
            The Pharmacy may like to contact you regarding the order. Please
            provide following details
          </h3>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={SCHEMA.CHECKOUT_DETAIL(phoneLength)}
            enableReinitialize
          >
            {({
              values,
              setFieldValue,
              setErrors,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              touched,
            }) => (
              <Form>
                <Grid
                  container
                  rowSpacing={{ xs: 1, md: 1, lg: 2 }}
                  columnSpacing={{ xs: 1, md: 2 }}
                >
                  <Grid item xs={4} md={3}>
                    <h4 className="subtext mb-5">Code</h4>
                    <InputFiled
                      fullWidth
                      placeholder="Code"
                      name="code"
                      size="small"
                      value={"+254"}
                      readOnly
                    />
                  </Grid>
                  <Grid item xs={8} md={9}>
                    <h4 className="subtext mb-5 justify-start">
                      Phone Number &nbsp;
                      <Tooltip
                        enterTouchDelay={0}
                        leaveTouchDelay={7000}
                        title="We will use your phone number to send you alerts when your medicines are ready"
                        placement="top-start"
                        disableFocusListener
                      >
                        <InfoIcon color="primary" className="info-icon" />
                      </Tooltip>
                    </h4>

                    <TextField
                      size="small"
                      name="phoneNumber"
                      placeholder="Enter Phone Number"
                      value={values.phoneNumber}
                      error={
                        errors.phoneNumber && touched.phoneNumber ? true : false
                      }
                      onChange={(e) => {
                        // phoneNumberValidator(e.target.value);
                        const { value } = e.target;
                        const pattern = new RegExp("^0?[0-9]{0,10}$");
                        console.log(value);
                        if (value[0] === "0") setPhoneLength(10);
                        else setPhoneLength(9);
                        if (
                          value[0] === "0" &&
                          value.substring(1).length === 9 &&
                          pattern.test(value)
                        ) {
                          setFieldValue("phoneNumber", value);
                        }

                        if (
                          pattern.test(e.target.value) &&
                          e.target.value.length <= phoneLength
                        ) {
                          setFieldValue("phoneNumber", e.target.value);
                          setDisableContinue(true);
                          dispatch(
                            getUserDetailByPhoneAction({
                              phone_code: "254",
                              phone_number:
                                value[0] === "0"
                                  ? e.target.value.trim().substring(1)
                                  : e.target.value.trim(),
                            })
                          )
                            .then((res: any) => {
                              setDisableContinue(false);
                              console.log("res", res);
                              if (res?.data?.is_user_exist === 1) {
                                setIsUserExist(true);
                              } else setIsUserExist(false);
                              if (res?.data?.city_id) {
                                setFieldValue("title", res?.data?.name_prefix);
                                setFieldValue("name", res?.data?.first_name);
                                setFieldValue("lastName", res?.data?.last_name);
                                setFieldValue("gender", res?.data?.sex);
                                setFieldValue("email", res?.data?.email);
                                setFieldValue(
                                  "city",
                                  cityOptions.find(
                                    (city) => city.name === res?.data?.city_name
                                  )
                                );
                                setFieldValue(
                                  "dateOfBirth",
                                  res?.data.date_birth === "0000-00-00"
                                    ? null
                                    : moment(res?.data.date_birth).format(
                                        "YYYY-MM-DD"
                                      )
                                );
                              } else {
                                setDisableContinue(false);
                                setFieldValue("title", "");
                                setFieldValue("name", "");
                                setFieldValue("lastName", "");
                                setFieldValue("gender", "");
                                setFieldValue("email", "");
                                setFieldValue("city", {
                                  id: "",
                                  name: "",
                                  country: "",
                                  country_id: "",
                                  country_code: "",
                                });
                                setFieldValue("dateOfBirth", "");
                              }
                            })
                            .catch((err: any) => {
                              setDisableContinue(false);
                              setFieldValue("title", "");
                              setFieldValue("name", "");
                              setFieldValue("lastName", "");
                              setFieldValue("gender", "");
                              setFieldValue("email", "");
                              setFieldValue("city", {
                                id: "",
                                name: "",
                                country: "",
                                country_id: "",
                                country_code: "",
                              });
                              setFieldValue("dateOfBirth", "");
                            });
                        }
                      }}
                      onBlur={handleBlur}
                    />
                    <FormHelperText error>
                      {touched.phoneNumber && errors.phoneNumber}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={4} md={3}>
                    <h4 className="subtext mb-5">Title</h4>
                    <SelectWrapper
                      name="title"
                      size="small"
                      placeholder="Title"
                      disabled={values.phoneNumber.length < 9 || isUserExist}
                    >
                      {salutations.map(({ key, name }) => {
                        return (
                          <MenuItem value={name} key={key}>
                            {name}
                          </MenuItem>
                        );
                      })}
                    </SelectWrapper>
                  </Grid>
                  <Grid item xs={8} md={4.5}>
                    <h4 className="subtext mb-5">First Name</h4>
                    <InputFiled
                      fullWidth
                      placeholder="Enter First Name"
                      name="name"
                      size="small"
                      disabled={values.phoneNumber.length < 9 || isUserExist}
                    />
                  </Grid>
                  <Grid item xs={12} md={4.5}>
                    <h4 className="subtext mb-5">Last Name</h4>

                    <InputFiled
                      fullWidth
                      placeholder="Enter Last Name"
                      name="lastName"
                      size="small"
                      disabled={values.phoneNumber.length < 9 || isUserExist}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <h4 className="subtext mb-5">Gender</h4>
                    <SelectWrapper
                      name="gender"
                      size="small"
                      placeholder="Select Gender"
                      disabled={values.phoneNumber.length < 9 || isUserExist}
                    >
                      {gender.map(({ value, name }) => {
                        return (
                          <MenuItem value={name} key={value}>
                            {name}
                          </MenuItem>
                        );
                      })}
                    </SelectWrapper>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <h4 className="subtext mb-5">City</h4>
                    <AutoCompleteWrapper
                      name="city"
                      options={cityOptions}
                      disabled={values.phoneNumber.length < 9 || isUserExist}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <h4 className="subtext mb-5 justify-start">
                      Email Address &nbsp;
                      <Tooltip
                        title="We will use your email as well to send you alerts when your medicines are ready"
                        placement="top-start"
                        enterTouchDelay={0}
                        leaveTouchDelay={7000}
                      >
                        <InfoIcon color="primary" className="info-icon" />
                      </Tooltip>
                    </h4>

                    <InputFiled
                      type="email"
                      fullWidth
                      placeholder="Enter Email"
                      name="email"
                      size="small"
                      isEndIcon
                      disabled={values.phoneNumber.length < 9 || isUserExist}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <h4 className="subtext mb-5 justify-start left">
                      Date of Birth &nbsp;
                      <Tooltip
                        title="We use the DOB to calculate your age which helps the pharmacies to give you the correct doses of the medicines"
                        placement="top-start"
                        enterTouchDelay={0}
                        leaveTouchDelay={7000}
                      >
                        <InfoIcon color="primary" className="info-icon" />
                      </Tooltip>
                    </h4>
                    <InputFiled
                      type="date"
                      fullWidth
                      placeholder="Date of birth"
                      name="dateOfBirth"
                      size="small"
                      minDate={subtractDate(18)}
                      disabled={values.phoneNumber.length < 9 || isUserExist}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      color="primary"
                      sx={{ fontSize: (theme) => theme.spacing(1.4) }}
                    >
                      By Signing up, you agree to Livia{" "}
                      {/* <Box
                  component="span"
                  sx={{
                    fontWeight: (theme) => theme.typography.fontWeightBold,
                    color: (theme) => theme.palette.common.black,
                  }}
                > */}
                      <a
                        className="bold"
                        href="/?p=1#/terms-and-condition"
                        target="_blank"
                      >
                        Terms and Conditions
                      </a>
                     {" "} and that you are over 18 years of age.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <button
                      className="btn btn-primary w-100 mb-10 mt-10"
                      type="submit"
                      disabled={disableContinue}
                    >
                      Continue
                    </button>
                    <p className="center">
                      Already registered? Click here to{" "}
                      <a
                        onClick={() => {
                          console.log("login button clicked");
                          handleLoginOpen();
                          handleClose();
                        }}
                        className="bold hover-underline pointer"
                      >
                        LOGIN
                      </a>
                    </p>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </Dialog>
    </>
  );
}

export default AddDetailDialog;
