const SIGN_UP = {
  phoneCode: "",
  phoneNumber: "",
  title: "Mr.",
  firstName: "",
  lastName: "",
  gender: "",
  city: null,
  dateOfBirth: "",
  email: "",
};
const CHECKOUT_DETAIL = {
  name: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  gender: "",
  dateOfBirth: "",
  code: "254",
  title: "",
  city: null,
};

const MY_PROFILE = {
  phoneCode: "",
  phoneNumber: "",
  title: "",
  firstName: "",
  lastName: "",
  gender: "",
  city: null,
  dateOfBirth: "",
  email: "",
};

const CHANGE_PHONE_NUMBER = {
  preFiledPhoneCode: "",
  preFiledPhoneNumber: "",
  phoneCode: "",
  phoneNumber: "",
};

const FILTER_DATE = {
  fromDate: "",
  toDate: "",
};

const FORM_FIELD = {
  SIGN_UP,
  CHECKOUT_DETAIL,
  MY_PROFILE,
  CHANGE_PHONE_NUMBER,
  FILTER_DATE,
};

export default FORM_FIELD;
