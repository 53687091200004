import React from "react";
import GlobalLoader from "../components/globalLoader/GlobalLoader";

type RequireAuthProps = {
  children: React.ReactNode | any;
};
function AuthGuard({ children }: RequireAuthProps) {
  return (
    <>
      <GlobalLoader />
      {children}
    </>
  );
}

export default AuthGuard;
