import pharmacyImg from "../assets/images/pharmacy.png";
export function getPhoneIdFromLS() {
  return localStorage.getItem("phone_id");
}

export const getPhoneId = () => {
  if (getPhoneIdFromLS()) {
    return getPhoneIdFromLS();
  } else {
    let phoneId = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      (c) => {
        const r = (Math.random() * 16) | 0;
        const v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
    localStorage.setItem("phone_id", phoneId);

    return phoneId;
  }
};

export const getTabId = () => {
  if (getTabIdBySesionStorage()) {
    return getTabIdBySesionStorage();
  } else {
    let tabId = "xxxxxxxx-4xxx".replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0;
      const v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
    sessionStorage.setItem("tabId", tabId);

    return tabId;
  }
};
export const getIdempotencyKey = ()=>{
  let phoneId = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    (c) => {
      const r = (Math.random() * 16) | 0;
      const v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    }
  );
  return phoneId;
}
export function setRegisteredUser() {
  localStorage.setItem("registered_user", "true");
}
export function getRegisteredUser() {
  return localStorage.getItem("registered_user");
}
export function getTabIdBySesionStorage() {
  return sessionStorage.getItem("tabId");
}
export function setWebsiteCountryCodeInLS(country_code: any) {
  localStorage.setItem("website_country_code", country_code);
}
export function getWebsiteCountryCodeFromLS() {
  return localStorage.getItem("website_country_code") ?? "ke";
}

export function setUserStatus(userStatus: string) {
  localStorage.setItem("user_status", userStatus);
}

export function getUserStatus() {
  return localStorage.getItem("user_status");
}

export function setAccessToken(accesstoken: string) {
  localStorage.setItem("access_token", accesstoken);
}
export function getAccessTokenFromLS() {
  return localStorage.getItem("access_token");
}
export function getRefreshTokenFromLS() {
  localStorage.getItem("refresh_token");
}
export function setRefreshToken(rtoken: string) {
  localStorage.setItem("refresh_token", rtoken);
}
export function setPhoneNumberLS(phoneNumber: string) {
  localStorage.setItem("user_phoneNo", phoneNumber);
}
export function setPhoneCodeLS(phoneCode: string) {
  localStorage.setItem("user_phoneCode", phoneCode);
}
export function setUserLogggedIn() {
  localStorage.setItem("userLoggedIn", "true");
}

export function getUserLogggedIn() {
  return localStorage.getItem("userLoggedIn");
}

// ? harshit functions
export function setUserCity(cityCode: string) {
  localStorage.setItem("user_city", cityCode);
}

export function getUserCity() {
  return localStorage.getItem("user_city");
}
export const getLocalStorageKeyData = (key: string) => {
  return localStorage.getItem(key);
};

export function setGuestUser(payload: any) {
  localStorage.setItem("guest_user", payload);
}

export function getGuestUser() {
  return localStorage.getItem("guest_user");
}
export function setGuestUserData(data: any) {
  localStorage.setItem("guest_user_data", JSON.stringify(data));
}

export function getGuestUserData() {
  return localStorage.getItem("guest_user_data")
    ? JSON.parse(localStorage.getItem("guest_user_data") as string)
    : null;
}

export function setUserIdInLS(userId: any) {
  localStorage.setItem("user_id", userId);
}

export function getUserIdFromLS() {
  return localStorage.getItem("user_id");
}

export function setUserRoleIdInLS(roleId: any) {
  localStorage.setItem("role_id", roleId);
}

export function getUserRoleIdFromLS() {
  return localStorage.getItem("role_id");
}

export function setWebsitePhoneNoInLS(phoneNo: any) {
  localStorage.setItem("phone_number", phoneNo);
}

export function getWebsitePhoneNoFromLS() {
  return localStorage.getItem("phone_number");
}

export function setWebsitePhoneCodeInLS(phoneCode: any) {
  localStorage.setItem("phone_code", phoneCode);
}

export function getWebsitePhoneCodeFromLS() {
  return localStorage.getItem("phone_code");
}
export function setCountryCodeInLS(payload: any) {
  localStorage.setItem("country_code", payload);
}

export function getCountryCodeInLS() {
  return localStorage.getItem("country_code");
}

export const getAge = (dateString: any) => {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const displayDate = (dt: any) => {
  if (dt)
    return (
      dt.getFullYear() +
      "-" +
      ("0" + (dt.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + dt.getDate()).slice(-2)
    );
  else return null;
};
export const getAuthHeader = () => localStorage.getItem("access_token") || "";

export const cartToSend = () => {
  const cart = localStorage.getItem("cart")
    ? JSON.parse(localStorage.getItem("cart") as string)
    : [];
  const cartInPayload = [];
  for (const medicineObj of cart) {
    if (medicineObj.quantity > 0) {
      const { id, name, quantity, type, customer_price } = medicineObj;
      const payloadObj = {
        drug_id: parseInt(id),
        drug_name: name,
        quantity: quantity,
        route_of_administration: 1,
        type: type.toString(),
        price: (customer_price * quantity).toString(),
      };
      cartInPayload.push(payloadObj);
    }
  }
  return cartInPayload;
};

export const addressInPayload = () => {
  const addressFromMap = JSON.parse(
    localStorage.getItem("adressFromMap") as string
  );
  const { address_title, address, longitude, latitude, full_address } =
    addressFromMap;
  const payload = {
    address: full_address,
    address2: address,
    address_id: "149",
    address_title,
    address_type: 2342,
    latitude,
    longitude,
    self_collect:
      localStorage.getItem("delivery-type") === "Self Collect" ? 1 : 0,
  };
  return payload;
};

export const removeUserSession = () => {
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("access_token");
  localStorage.removeItem("user_status");
  localStorage.removeItem("user_phoneCode");
  localStorage.removeItem("userLoggedIn");
  localStorage.removeItem("user_city");
  localStorage.removeItem("user_phoneNo");
  // localStorage.removeItem("phone_id");
};

export const ClearLocalStorage = () => {
  new Promise<void>((resolve) => {
    removeUserSession();
    resolve();
  });
};

export const removeGuestUserSession = () => {
  removeUserSession();
  localStorage.removeItem("guest_user_data");
  localStorage.removeItem("guest_user");
};

export let toastIdsConstant = {
  paymentSuccess: "paymentSuccess",
};

export function getFaviconUrl(imgUrl: string) {
  return process.env.REACT_APP_API_URL + imgUrl;
}

export function getFaviconElement() {
  return document.getElementById("favicon");
}

export function imageIcon(image: any) {
  let uploadType = image?.split(".");
  if (uploadType && uploadType instanceof Array && uploadType.length > 0) {
    let imgType = uploadType[1];
    switch (imgType) {
      case "jpg":
      case "jpeg":
      case "gif":
      case "png":
        return `${process.env.REACT_APP_API_URL}${image}`;
      // case "pdf": {
      // 	return pdfIcon;
      // }
      // case "docx":
      // case "doc":
      // 	return wordIcon;
      // case "xls":
      // case "xlsx": {
      // 	return docIcon;
      // }
      default:
        return pharmacyImg;
    }
  }
}

export function setPayURoute(payload:string) {
  localStorage.setItem("payU_Route", payload);
}
export function getPayURoute() {
  return localStorage.getItem("payU_Route");
}
export function setOrderIdInLS(payload:any) {
  localStorage.setItem("order_id", payload);
}
export function get_OrderIdInLS() {
  return localStorage.getItem("order_id");
}
