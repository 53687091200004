import Grid from "@mui/material/Grid";
import bannerImg from "./../../assets/images/dummy-banner4.png";
import "./Home.scss";
import SearchMedicine from "../../components/SearchMedicine";
import { useNavigate } from "react-router-dom";
import ProductCard from "../ProductCard/ProductCard";
import { TablePagination } from "@mui/material";
import { useEffect, useState } from "react";
import {
  searchMedicineListAction,
  topSellingMedicineListAction,
} from "../../redux/actions/searchMedicineListAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  globalLoaderEnd,
  globalLoaderStart,
} from "../../redux/reducers/globalReducer";
import RegisterUser from "../../services/OnBoarding/MyProfileService";
import { API_URL } from "../../api/endpoints";
// import { searchMedi, uploadPres } from "../../utility/images";
import uploadPres from "./../../assets/images/upload-pres.svg";
import searchMedi from "./../../assets/images/search-medi.svg";
import Pagination from "@mui/material/Pagination";
import bannerPlaceHolder from "../../../src/assets/images/placeholder.svg"; //"./../../../src/assets/images/placeholder.svg";
import { PayUService } from "../../services/PayUService";

const profileSer = new RegisterUser();
const payUService = new PayUService()

function Home() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [offset, setOffset] = useState(0);
  const [limit] = useState(10);
  const [bannner, setBanner] = useState("");
  const [medicineData, setMedicineData] = useState<any>();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState<number>(0);
  const searchData = useSelector(
    (state: any) => state.TopSellingMedicineReducer.apiData
  );
  const isApiLoading = useSelector(
    (state: any) => state.SearchMedicine.isApiLoading
  );
  const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");
  const [headingColor, setHeadingColor] = useState("");
  const [descriptionColor, setDescriptionColor] = useState("");
  const [headingFont, setHeadingFont] = useState("");
  const [descFont, setDescFont] = useState("");
  const [bannerbgn, setbannnerbgn] = useState("");

  useEffect(() => {
    // if (isApiLoading) dispatch(globalLoaderStart());
    // else dispatch(globalLoaderEnd());
  }, [isApiLoading]);
  useEffect(() => {
    // const startOffset = (offset * limit) % medicineList.length;
    // const endOffset = startOffset + limit;
    // let newMedicine = medicineList.slice(startOffset, endOffset);
    dispatch(topSellingMedicineListAction("", offset, limit) as any);
    // setMedicineData(newMedicine);
  }, [offset, limit]);
  useEffect(() => {
    if (searchData?.data) {
      const listWithQuantity = searchData?.data?.body.map((medicine: any) => {
        return { ...medicine, quantity: 0 };
      });
      setMedicineData([...listWithQuantity]);
      setCount(Math.floor(searchData?.data?.count / limit));
    }
  }, [searchData]);
  useEffect(() => {
    profileSer.bannerDetail().then((res) => {
      if (res?.data?.body?.banner_data) {
        setBanner(res?.data?.body?.banner_data?.banner_bg_image);
        setHeading(res?.data?.body?.banner_data?.heading);
        setHeadingColor(res?.data?.body?.banner_data?.heading_color);
        setDescription(res?.data?.body?.banner_data?.description);
        setDescriptionColor(res?.data?.body?.banner_data?.sub_heading_color);
        setHeadingFont(res?.data?.body?.banner_data?.heading_fz);
        setDescFont(res?.data?.body?.banner_data?.sub_heading_fz);
        setbannnerbgn(res?.data?.body?.banner_data?.bg_color);
      }
    });
  }, []);

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    setOffset(newPage * limit);
  };
  // const usePayu = async ()=>{
  //   const res = await payUService.createPayUId({"amount":1000, "currency":"KES"})
  //   console.log("response from payu", res)
  //   // if(res.status === 201){
  //   //   const response = await payUService.openPayU(res?.data?.id)
  //   //   console.log("response from changres",response)
  //   //   if(response?.status === 201){
  //   //     window.open(response?.data?.redirection?.url)
  //   //   }
  //   // }
  //   // payUService.createPayUId(1000,"KES")
  //   // payUService.openPayU()
    
  // }

  return (
    <div className="home-page">
      <section className="banner">
        <img
          src={
            bannner !== ""
              ? `${API_URL}${bannner}`
              : bannerbgn
              ? "   "
              : bannerPlaceHolder
          }
          style={{ backgroundColor: bannerbgn }}
        />
        <div className="banner-content">
          <h1
            style={{
              color: headingColor ? headingColor : "#ffffff",
              fontSize: headingFont,
            }}
            className="h1-desktop"
          >
            {heading}
          </h1>
          <h1 className="h1-mobile">{heading}</h1>
          <h2
            style={{
              color: descriptionColor ? descriptionColor : "#ffffff",
              fontSize: descFont,
            }}
            className="h1-desktop"
          >
            {description}
          </h2>
          <h2 className="h1-mobile">{description}</h2>
        </div>
      </section>
      <section className="upload-prescription">
        <div className="upload-prescription-content">
          <h1>How you would like to order your medicine?</h1>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <div className="card">
                <img src={searchMedi} className="for-desktop" />
                <h2 className="flex-center">
                  <img src={searchMedi} /> I know the medicines I want to order
                </h2>
                <div className="search-cover">
                  <SearchMedicine search="" />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="card">
                <img src={uploadPres} className="for-desktop" />
                <h2 className="flex-center">
                  <img src={uploadPres} /> I have a prescription
                </h2>
                <button
                  className="btn btn-white w-100"
                  onClick={() => {
                    navigate("/upload-prescription");
                  }}
                >
                  Upload Your Prescription Here
                </button>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
      <section className="top-selling-medicine">
      {/* <button className="btn btn-secondary" onClick={usePayu}>PayU Payment</button> */}
        {searchData?.data?.count ? (

          <h1 className="mb-30 center bold">Top Selling</h1>
        ) : (
          ""
        )}
        {/* <div className="space-between">
          <button className="btn btn-primary-border mb-30">
            View All Medicines
          </button>
        </div> */}
        <Grid
          container
          rowSpacing={{ xs: 3, sm: 4, md: 6 }}
          columnSpacing={{ xs: 2, sm: 4, md: 6 }}
        >
          {medicineData?.length > 0 &&
            medicineData.map((medicine: any, index: number) => (
              <Grid key={index} item xs={6} sm={4} md={3} lg={2.4}>
                <ProductCard medicine={medicine} />
              </Grid>
            ))}
        </Grid>

        {searchData?.data?.count > 10 && (
          <Pagination
            style={{ marginTop: "40px" }}
            count={count}
            siblingCount={0}
            color="primary"
            variant="outlined"
            shape="rounded"
            page={page}
            onChange={(event, newPage) => {
              setPage(newPage);
              setOffset(newPage * limit);
            }}
          />
        )}
        {/* {count > 0 && (
          <TablePagination
            sx={{
              mt: 2,
              "& .MuiTablePagination-selectLabel": {
                display: "none",
              },
              "& .MuiTablePagination-selectRoot": {
                display: "none",
              },
              "& .MuiTablePagination-select": {
                display: "none",
              },
              "& .MuiTablePagination-selectIcon": {
                display: "none",
              },
            }}
            component="div"
            count={count}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={limit}
            rowsPerPageOptions={[limit]}
          />
        )} */}
      </section>
      {/* <section className="section-features">
        <h1 className="center mb-30 bold">Key Features</h1>
        <div className="feature-grid">
          <Grid container rowSpacing={2} columnSpacing={6}>
            <Grid item xs={12} sm={6}>
              <div className="single-feature">
                <div className="img-cover">
                  <img src={syringeImg} alt="Feature" />
                </div>
                <h3>Order Medicine Online From</h3>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="single-feature">
                <div className="img-cover">
                  <img src={syringeImg} alt="Feature" />
                </div>
                <h3>Most Trusted Medicine Chain</h3>
              </div>
            </Grid>
          </Grid>
        </div>
      </section> */}
    </div>
  );
}

export default Home;
