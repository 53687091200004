import { Grid, Pagination } from "@mui/material";
import OrderProgress from "../../components/OrderProgress/OrderProgress";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { getUserOrder } from "./action";
import { ReducersModal } from "../../modal";
import { getOrderDate, getOrderStatus, getTableOffset } from "../../utility/commonFuntion";
import { UserOrder } from "../../types";
import SearchTextfield from "../../components/formUI/SearchTextfield/SearchTextfield";
import useDebounce from "../../hooks/useDebounce";
import TablePagination from "@mui/material/TablePagination";
import ACTION_NAME from "../../utility/actionConstant";
import { OrderStatus } from "../../utility/staticData";
import FilterDate from "../../components/formUI/FilterDate/FilterDate";
import FORM_FIELD from "../../validation/formFiled";
import { FormikHelpers } from "formik";
import "./Profile.scss";

function MyOrder() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const debouncedValue = useDebounce(inputValue, 1000);
  const [page, setPage] = useState(1);
  const scrollRef = useRef<any>(null);

  const { offset, limit, search, toDate, fromDate, body, count } = useSelector(
    (state: ReducersModal) => state.userOrderReducer
  );
  const executeScroll = () => scrollRef.current.scrollIntoView();
  useEffect(() => {
    executeScroll();
  }, []);
  useEffect(() => {
    // setPage(offset);
    dispatch(
      getUserOrder({
        offset: getTableOffset(offset, limit),
        limit,
        search: debouncedValue ? debouncedValue : "",
        ...(fromDate && toDate ? { fromDate, toDate } : { fromDate: "", toDate: "" }),
      })
    );
  }, [debouncedValue, dispatch, fromDate, limit, offset, search, toDate]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value;
    setInputValue(keyword);
  };

  const handleSearchSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      searchInput: { value: string };
    };
    const keyword = target.searchInput.value;
    setInputValue(keyword);
  };

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    dispatch({
      type: ACTION_NAME.USER_ORDER,
      payload: { offset: newPage + 1 },
    });
  };

  const handleFilterDateSubmit = (
    values: typeof FORM_FIELD.FILTER_DATE,
    { setSubmitting }: FormikHelpers<typeof FORM_FIELD.FILTER_DATE>
  ) => {
    dispatch({
      type: ACTION_NAME.USER_ORDER,
      payload: { fromDate: values.fromDate, toDate: values.toDate },
    });
    setSubmitting(false);
  };

  const handleFilterDateClear = () => {
    dispatch({
      type: ACTION_NAME.USER_ORDER,
      payload: { fromDate: "", toDate: "" },
    });
  };

  const handleActionClick = () => {
    /* TODO document why this arrow function is empty */
  };

  const handleDetailClick = (id: string) => {
    navigate(`detail/${id}`);
  };

  return (
    <div ref={scrollRef}>
      <div className="skeu-card" style={{ marginBottom: "20px" }}>
        <h3 className="semi-bold">My Orders</h3>
        <hr className="mt-15 mb-10" />
        <div className="filter-layout">
          {/* <Grid container spacing={{ sm: 2, xs: 1 }}> */}
          {/* <Grid item sm={12} md={4}> */}
          <div className="search-field">
            <h4 className="mb-5">Search Date</h4>
            <SearchTextfield
              placeholder={"Search by Order Id"}
              inputValue={inputValue}
              handleSearchChange={handleSearchChange}
              handleSearchSubmit={handleSearchSubmit}
              name="searchInput"
            />
          </div>

          {/* </Grid> */}
          {/* <Grid item sm={12} md={8}> */}
          <div className="date-filter">
            <FilterDate handleSubmit={handleFilterDateSubmit} handleClear={handleFilterDateClear} />
          </div>
        </div>
        {/* </Grid> */}
        {/* </Grid> */}
      </div>

      <>
        {body?.length > 0 ? (
          body.map((item: UserOrder) => {
            return (
              <OrderProgress
                key={item.order_id}
                status={
                  item.user_order_status === "3" &&
                  item.prescription_order == "0"
                    ? "Payment Pending For your Order"
                    : getOrderStatus(item.user_order_status)
                }
                status_id={item.user_order_status}
                id={item.order_id}
                orderOn={getOrderDate(item.create_date)}
                deliveredOn={
                  Number(item.order_id) === OrderStatus.ON_DELIVERY ? getOrderDate(item?.completed_date) : ""
                }
                orderAction={Number(item.order_id) === OrderStatus.ON_DELIVERY ? "Reorder" : "Cancel Order"}
                handleActionClick={handleActionClick}
                handleDetailClick={handleDetailClick}
              />
            );
          })
        ) : (
          <h1 className="center">No Order Available</h1>
        )}
      </>
      {count > 10 && (
        <Pagination
          style={{ float: "right" }}
          count={Math.ceil(count / limit)}
          siblingCount={0}
          color="primary"
          variant="outlined"
          shape="rounded"
          page={page}
          onChange={(event, newPage) => {
            console.log(newPage);
            setPage(newPage);
            // setOffset(newPage * limit);
            dispatch({
              type: ACTION_NAME.USER_ORDER,
              payload: { offset: newPage },
            });
          }}
        />
      )}

      {/* {body?.length > 0 && (
        <TablePagination
          sx={{
            "& .MuiTablePagination-selectLabel": {
              display: "none",
            },
            "& .MuiTablePagination-selectRoot": {
              display: "none",
            },
            "& .MuiTablePagination-select": {
              display: "none",
            },
            "& .MuiTablePagination-selectIcon": {
              display: "none",
            },
          }}
          component="div"
          count={count}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={+limit}
        />
      )} */}
    </div>
  );
}

export default MyOrder;
