import { ProfileModel, UserOrderDetailModal, UserOrderModal } from "./profile";

class GlobalLoaderModal {
  isLoading = false;
}

class ReducersModal {
  profileReducer: ProfileModel = new ProfileModel();
  globalLoaderReducer: GlobalLoaderModal = new GlobalLoaderModal();
  userOrderReducer: UserOrderModal = new UserOrderModal();
  userOrderDetailReducer: UserOrderDetailModal = new UserOrderDetailModal();
}

export {
  ReducersModal,
  GlobalLoaderModal,
  ProfileModel,
  UserOrderModal,
  UserOrderDetailModal,
};
