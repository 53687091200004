import { axiosInstance } from "../api/axiosInstance";
import { urlEndpoints } from "../api/endpoints";
import { getLocalStorageKeyData } from "../utility/util";

export default class OrderService {
  placeOrder = async (payload: any) => {
    return axiosInstance.post(`${urlEndpoints.ORDER}`, payload, {
      headers: {
        // "liviaApp-chemistUrl": process.env.REACT_APP_DOMAIN || "",
        "liviaApp-chemistUrl":
          process.env.NODE_ENV === "production"
            ? (window.location.host as any)
            : process.env.REACT_APP_DOMAIN,
        "LiviaApp-city": getLocalStorageKeyData("user_city") || "",
      },
    });
  };

  onOrderSuccess = async (payload: any) => {
    return axiosInstance.put(
      `${urlEndpoints.ORDER}/${payload}`,
      {},
      {
        headers: {
          // "liviaApp-chemistUrl": process.env.REACT_APP_DOMAIN || "",
          "liviaApp-chemistUrl":
            process.env.NODE_ENV === "production"
              ? (window.location.host as any)
              : process.env.REACT_APP_DOMAIN,
          "LiviaApp-city": getLocalStorageKeyData("user_city") || "",
        },
      }
    );
  };
  ethicalSub = async () => {
    return axiosInstance.patch(
      `${urlEndpoints.USER}/1`,
      { is_ethical_substitute: 1 },
      {
        headers: {
          "LiviaApp-city": getLocalStorageKeyData("user_city") || "",
        },
      }
    );
  };

  orderStatus = async (payload: any, orderId: any) => {
    return axiosInstance.put(
      `${urlEndpoints.ORDER_STATUS}/${orderId}`,
      payload,
      {
        headers: {
          "LiviaApp-city": getLocalStorageKeyData("user_city") || "",
        },
      }
    );
  };
}
