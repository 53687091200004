import { axiosInstance } from "../../api/axiosInstance";
import { urlEndpoints } from "../../api/endpoints";

export default class ImageService {
  uploadImage = async (binaryImage: any) => {
    return axiosInstance.post(`${urlEndpoints.IMAGE}`, {
      image: binaryImage,
      type: "orders",
    });
  };
}
