import { Box, IconButton, InputBase } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";

type SearchTextfieldProps = {
  name: "searchInput";
  placeholder: string;
  inputValue: string;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSearchSubmit: (event: React.SyntheticEvent) => void;
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      border: "1px solid var(--light-gray)",
      borderRadius: 6,
      display: "flex",
      backgroundColor: "var(--white)",
    },
    inputBase: {
      "&.MuiInputBase-root": {
        font: `normal 400 16px Barlow, sans-serif`,
      },
    },
  })
);

function SearchTextfield({
  name,
  placeholder,
  inputValue,
  handleSearchSubmit,
  handleSearchChange,
}: SearchTextfieldProps) {
  const classes = useStyles();
  return (
    <Box
      className={classes.root}
      component="form"
      onSubmit={handleSearchSubmit}
    >
      <IconButton type="submit" aria-label="search" color="primary">
        <SearchIcon />
      </IconButton>
      <InputBase
        name={name}
        onChange={handleSearchChange}
        value={inputValue}
        className={classes.inputBase}
        placeholder={placeholder}
        inputProps={{ "aria-label": `${placeholder}` }}
      />
    </Box>
  );
}

export default SearchTextfield;
