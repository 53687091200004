import TextField, { TextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { useField } from "formik";
import { InputAdornment } from "@mui/material";

type InputFiledProps = {
  name: string;
  isStartIcon?: true;
  isEndIcon?: true;
  Icon?: JSX.Element;
  readOnly?: boolean;
  minDate?: string;
  maxDate?: string;
} & TextFieldProps;

const StyledTextField = styled(TextField)<InputFiledProps>(() => ({}));

function InputFiled({
  name,
  isStartIcon,
  isEndIcon,
  Icon,
  readOnly,
  minDate,
  maxDate,
  ...otherProps
}: InputFiledProps) {
  const [field, meta] = useField(name);

  const configTextField = {
    ...field,
    ...otherProps,
  };

  if (name === "fromDate" || name === "toDate") {
    if (meta && meta.touched && !field.value) {
      configTextField.error = true;
      configTextField.helperText = meta.error;
    }
  } else {
    if (meta && meta.touched && meta.error) {
      configTextField.error = true;
      configTextField.helperText = meta.error;
    }
  }

  return (
    <StyledTextField
      {...configTextField}
      InputProps={
        (isStartIcon || isEndIcon) && Icon
          ? {
              endAdornment: (
                <InputAdornment
                  position={
                    (isStartIcon && "start") || (isEndIcon && "end") || "start"
                  }
                >
                  {Icon}
                </InputAdornment>
              ),
            }
          : {}
      }
      inputProps={{ readOnly, max: minDate, min: maxDate }}
    />
  );
}

export default InputFiled;
