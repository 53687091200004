import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IMedicine, IMedicineList } from "../Landing/model/searchMedicineModel";
import { productImg } from "./../../utility/images";
import "./Checkout.scss";
import AddDetailDialog from "./section/AddDetailDialog";
import MapAddress from "./section/MapAddress";
import LoginDialog from "../Onboarding/LoginDialog";
import PaymentDialog from "../../components/paymentDialog/PaymentDialog";
import { cartToSend, getGuestUser, getGuestUserData, getPayURoute, get_OrderIdInLS, setOrderIdInLS } from "../../utility/util";
import { onOrderSuccess, placeOrder } from "../../redux/actions/placeOrderAction";
import { globalLoaderEnd, globalLoaderStart } from "../../redux/reducers/globalReducer";
import showAlert from "../../utility/alert";
import { ReducersModal } from "../../modal";
import { getUserProfile } from "../Profile/action";
import CartTable from "../../components/table/CartTable";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import paymentSuccessDialog from "../../components/paymentDialog/paymentSuccessDialog";
import PaymentSuccessDialog from "../../components/paymentDialog/paymentSuccessDialog";
import { PayUService } from "../../services/PayUService";
const payUService = new PayUService()

function Checkout() {
  const { phone_number, first_name, last_name, email } = useSelector((state: ReducersModal) => state.profileReducer);
  const [openDialog, setOpenDialog] = useState(false);
  const cart = useSelector((state: any) => state.cart.cart);
  const [cartItemList, setCartItemList] = useState<IMedicineList[]>([]);
  const [itemTotal, setItemTotal] = useState(0);
  const [deliveryFee, setDeliveryFee] = useState(150);
  const [totalToPay, setTotalToPay] = useState(0);
  const [openLoginDialog, setLoginDialog] = useState(false);
  const [deliveryType, setDeliveryType] = useState(localStorage.getItem("delivery-type"));
  const navigate = useNavigate();
  // const [showFlutterWave, setshowFlutterWave] = useState(false);
  const dispatch = useDispatch();
  const [addressinCheckout, setAddress] = useState({
    address: "",
    landmark: "",
    address_title: "Work",
    city: "",
    latitude: 0,
    longitude: 0,
  });
  const [openPaymentDialog, setPaymentDialog] = useState(false);
  const [orderId, setOrderId] = useState();
  useEffect(() => {
    console.log(!getPayURoute())
    if(getPayURoute()){
      navigate(`/user/my-order/detail/${get_OrderIdInLS()}`);
    }
    dispatch(getUserProfile());
  }, []);
  useEffect(() => {
    let listToDisplay = cart.filter((medicineInCart: IMedicine) => medicineInCart.quantity !== 0);
    setCartItemList(listToDisplay);
    let totalCostOfItem = 0;
    cart.forEach((medicine: any) => {
      totalCostOfItem += parseFloat(medicine.customer_price) * medicine.quantity;
    });
    setItemTotal(totalCostOfItem);
    // setItemTotal(Math.ceil(totalCostOfItem));
  }, [cart]);
  useEffect(() => {
    setTotalToPay(itemTotal + deliveryFee);
  }, [itemTotal, deliveryFee]);

  useEffect(() => {
    const type = localStorage.getItem("delivery-type");
    if (type === "Delivery") setDeliveryFee(150);
    else setDeliveryFee(0);
  }, [deliveryType]);

  const handleClose = () => {
    setOpenDialog(false);
    // setSelectedValue(value);
  };
  const handleLoginClose = () => {
    setLoginDialog(false);
  };
  // const handlePaymentClose = () => {
  //   setPaymentDialog(false);
  // };
  const handlePaymentClose = (event: any, reason: any) => {
    if (reason && reason === "backdropClick") return;
    else {
      setPaymentDialog(false);
    }
  };
  const handleLoginOpen = () => [setLoginDialog(true)];
  const handleAddressChange = (address: any) => {
    setAddress({ ...address });
  };
  useEffect(() => {}, [openPaymentDialog]);
  const handlePlaceOrder = (deliveryAddress: any) => {
    const addressFromMap = JSON.parse(localStorage.getItem("adressFromMap") as string);
    const payload = {
      address: deliveryAddress?.address,
      address2: deliveryAddress?.address2,
      landmark: deliveryAddress?.landmark,
      address_title: deliveryAddress?.address_title,
      address_type: deliveryAddress?.address_type,
      address_id: deliveryAddress?.id ? deliveryAddress?.id : "",
      is_ethical_substitute: 0,
      latitude: deliveryAddress.latitude,
      longitude: deliveryAddress.longitude,
      self_collect: localStorage.getItem("delivery-type") === "Self Collect" ? 1 : 0,
      drugs: cartToSend(),
      manual: 1,
      pharma_id: "",
      order_from_chemist_user_webiste: 1,
    };
    dispatch(globalLoaderStart());
    dispatch(placeOrder(payload) as any)
      .then((res: any) => {
        console.log("res of order", res?.data?.order_id);
        setOrderId(res?.data?.order_id);
        setOrderIdInLS(res?.data?.order_id)
        dispatch(onOrderSuccess(res?.data?.order_id) as any)
          .then((res: any) => {
            dispatch(globalLoaderEnd());
            setPaymentDialog(true);
          })
          .catch((err: any) => {
            dispatch(globalLoaderEnd());
            if (err?.response?.data?.messages && err?.response?.data?.messages[0]) {
              showAlert(2, err?.response?.data?.messages[0]);
            } else showAlert(2);
          });
      })
      .catch((error: any) => {
        console.log("error", error);
        dispatch(globalLoaderEnd());
        if (error?.response?.data?.messages && error?.response?.data?.messages[0]) {
          showAlert(2, error?.response?.data?.messages[0]);
        }
      });
  };
  const usePayu = ()=>{
    payUService.createPayUId({"amount":1000, "currency":"KES"}).then((response)=>{
      console.log(response)
    }).catch((err)=>console.log(err))
    
  }
  return (
    <div className="page-checkout">
      <h3 className="mb-20">
        {/* <button onClick={usePayu}>use payu</button> */}
        <span className="color-primary justify-start">
          <ArrowBackIcon className="pointer mr-10" titleAccess="Go Back" onClick={() => navigate(-1)} /> Checkout
        </span>
      </h3>
      <div className="checkout-content">
        <div className="upper-portion">
          <div className="left-portion">
            <div className="select-delivery-type">
              <h1 className="mb-10 semi-bold">Order Type</h1>
              <h2>{deliveryType}</h2>
            </div>
          </div>
          <div className="price-card">
            <h1 className="mb-10 semi-bold">Payable Amount</h1>
            <ul>
              <li>
                <h2>Item (s) Total</h2>
                <h2>{itemTotal?.toFixed(2)} KES</h2>
              </li>
              <li>
                <h2>Delivery Fee</h2>
                <h2>{deliveryFee} KES</h2>
              </li>
              <li>
                <h2>To Pay</h2>
                <h2>{totalToPay?.toFixed(2)} KES</h2>
              </li>
            </ul>
          </div>
        </div>

        <div className="skeu-card mb-30 w-100 map-card" style={{ padding: "20px", borderRadius: "5px" }}>
          <MapAddress dialogHandler={handlePlaceOrder} addressFromCheckout={addressinCheckout} />
        </div>

        <div className="skeu-card mb-30 w-100" style={{ padding: "20px 0px" }}>
          <h1 className="justify-start mb-20 ml-30 bold">Items in Cart</h1>
          <CartTable page="checkout" />

          {/* <ul className="items-list">
            {cartItemList.map((medicine: any) => (
              <li key={medicine.id}>
                <div
                  className="product-info flex-start w-100"
                  style={{ wordBreak: "break-all" }}
                >
                  <div className="product-image">
                    <img
                      src={
                        medicine?.image
                          ? process.env.REACT_APP_API_URL + medicine?.image
                          : productImg
                      }
                      title="Product"
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <h4>
                      {medicine.name}-{medicine.api}
                    </h4>
                    <h4 className="color-primary semi-bold nowrap">
                      Quantity- {medicine.quantity}
                    </h4>
                  </div>
                  <h4 className="color-primary semi-bold nowrap ml-10">
                    {(medicine.customer_price * medicine.quantity).toFixed(2)}{" "}
                    KES
                  </h4>
                </div>
              </li>
            ))}
          </ul> */}
        </div>
      </div>

      <AddDetailDialog open={openDialog} onClose={handleClose} handleLoginOpen={handleLoginOpen} />
      <LoginDialog openDialog={openLoginDialog ? true : false} onClose={handleLoginClose} />
      <PaymentDialog
        fromCheckout={true}
        openDialog={openPaymentDialog ? true : false}
        onClose={handlePaymentClose}
        config={{
          public_key: process.env.REACT_APP_FLUTTERWAVE_KEY,
          tx_ref: Date.now(),
          amount: totalToPay,
          currency: "KES",
          payment_options: "card",
          customer: {
            email: getGuestUser() === "true" ? getGuestUserData()?.email : email,
            phonenumber: getGuestUser() === "true" ? getGuestUserData()?.phone_number : phone_number,
            name:
              getGuestUser() === "true"
                ? `${getGuestUserData()?.first_name} ${getGuestUserData()?.last_name}`
                : `${first_name} ${last_name}`,
          },
        }}
        // setshowFlutterWave={setshowFlutterWave}
        totalToPay={totalToPay?.toFixed(2)}
        orderId={orderId}
      />
      {/* <PaymentSuccessDialog /> */}
    </div>
  );
}

export default Checkout;
