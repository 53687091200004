import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  globalLoaderEnd,
  globalLoaderStart,
} from "../redux/reducers/globalReducer";
import { LoginService } from "../services/OnBoarding/loginService";
import "./tnc.scss";

const loginService = new LoginService();

function TermsAndConditions() {
  const dispatch = useDispatch();
  const [tnc, setTnc] = useState("");
  useEffect(() => {
    dispatch(globalLoaderStart());
    loginService.getTnC(1).then((res) => {
      dispatch(globalLoaderEnd());
      setTnc(res.data);
    });
  }, []);
  return (
    <div className="page-tnc">
      <h1>Terms and Conditions</h1>
      <div className="tnc-content">
        {<p dangerouslySetInnerHTML={{ __html: tnc }} />}
      </div>
    </div>
  );
}

export default TermsAndConditions;
