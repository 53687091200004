import TextField, { TextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { useField, useFormikContext } from "formik";
import { InputAdornment } from "@mui/material";

type SelectWrapperProps = {
  children: React.ReactNode;
  name: string;
  isStartIcon?: true;
  isEndIcon?: true;
  Icon?: JSX.Element;
  readOnly?: boolean;
} & TextFieldProps;

const StyledTextField = styled(TextField)<SelectWrapperProps>(() => ({}));

const SelectWrapper = ({
  name,
  children,
  isStartIcon,
  isEndIcon,
  Icon,
  readOnly,
  ...otherProps
}: SelectWrapperProps) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setFieldValue(name, value);
  };

  const configSelect = {
    ...field,
    ...otherProps,
    select: true,
    onChange: handleChange,
  };

  if (meta && meta.touched && meta.error) {
    configSelect.error = true;
    configSelect.helperText = meta.error;
  }

  return (
    <StyledTextField
      {...configSelect}
      inputProps={{ readOnly }}
      InputProps={
        (isStartIcon || isEndIcon) && Icon
          ? {
              startAdornment: (
                <InputAdornment
                  position={
                    (isStartIcon && "start") || (isEndIcon && "end") || "start"
                  }
                >
                  {Icon}
                </InputAdornment>
              ),
            }
          : {}
      }
    >
      {children}
    </StyledTextField>
  );
};

export default SelectWrapper;
