import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addToCart, removeFromCart } from "../../redux/actions/cartActions";
import { IMedicine } from "../Landing/model/searchMedicineModel";
import { syringeImg } from "../../utility/images";
import "./ProductCard.scss";

function ProductCard(props: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state: any) => state.cart.cart);
  const [medicine, setMedicine] = useState<any>({});
  function changeCartQuantity(type: string, medicine: IMedicine) {
    if (type === "increment") {
      dispatch(addToCart(medicine) as any);
    }
    if (type === "decrement") {
      const index = cart.findIndex((ele: any) => ele.id === medicine.id);
      if (index !== -1) dispatch(removeFromCart(medicine) as any);
    }
  }

  useEffect(() => {
    setMedicine({ ...props.medicine });
  }, [props.medicine]);

  useEffect(() => {
    for (const medicineInCart of cart) {
      if (medicineInCart.id === props.medicine.id) {
        setMedicine(medicineInCart);
      }
      // else setMedicine({ ...props.medicine });
    }
  });
  return (
    <div className="product-card">
      <div>
        <div
          className="product-image"
          onClick={() => {
            navigate("/product-detail", {
              state: {
                medicine: medicine,
              },
            });
          }}
        >
          {/* <img src={props.product.name} title="Go to cart" /> */}
          <img
            src={
              medicine?.image
                ? process.env.REACT_APP_API_URL + medicine?.image
                : syringeImg
            }
            title="Go to cart"
          />
        </div>

        <h3 className="product-name">{medicine.name}</h3>
        <h2 className="product-price bold">
          {/* {Math.ceil(medicine?.customer_price)} KES */}
          {medicine?.customer_price?.toFixed(2)} KES
        </h2>
      </div>
      {medicine.quantity > 0 && (
        <div className="btn-cover">
          <button
            className="btn btn-add"
            onClick={() => {
              changeCartQuantity("decrement", medicine);
            }}
          >
            -
          </button>
          <span>{medicine.quantity}</span>
          <button
            className="btn btn-add"
            onClick={() => {
              changeCartQuantity("increment", medicine);
            }}
          >
            +
          </button>
        </div>
      )}
      {medicine.quantity === 0 && (
        <button
          className="btn btn-add w-100"
          onClick={() => {
            changeCartQuantity("increment", medicine);
          }}
        >
          Add to Cart
        </button>
      )}
    </div>
  );
}

export default ProductCard;
