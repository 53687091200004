import React from "react";
import Dialog from "@mui/material/Dialog";
import { DialogContent, DialogTitle } from "@mui/material";

function PrescriptionInfoDialog(props: any) {
  const { openDialog, onClose, pharmacyName } = props;

  return (
    <Dialog open={openDialog} fullWidth maxWidth="xs" onClose={onClose}>
      {/* <DialogTitle> */}
      <h2 className="mt-20 ml-20" style={{ fontWeight: "600" }}>
        Thank you for using {pharmacyName}.
      </h2>
      {/* </DialogTitle> */}
      <DialogContent>
        <h3>
          Your prescription is being processed for pricing and availability. The
          pharmacy will get back to you in a few minutes.
        </h3>
        <div className="flex-center mt-30">
          <button className="btn btn-danger" onClick={onClose}>
            Close
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default PrescriptionInfoDialog;
