import { Avatar, Box, Grid, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import MapToReview from "../../components/Map/MapToReview";
import PaymentDialog from "../../components/paymentDialog/PaymentDialog";
import PaymentSuccessDialog from "../../components/paymentDialog/paymentSuccessDialog";
import PreviewImage from "../../components/PreviewImage/PreviewImage";
import { ReducersModal } from "../../modal";
import {
  globalLoaderEnd,
  globalLoaderStart,
} from "../../redux/reducers/globalReducer";
import RegisterUser from "../../services/OnBoarding/MyProfileService";
import {
  getOrderDate,
  getOrderStatus,
  getOrderStatusBgColor,
} from "../../utility/commonFuntion";
import { getGuestUser, getGuestUserData, setPayURoute } from "../../utility/util";
import { getUserOrderDetail, orderPaymentStatus } from "./action";
import ItemList from "./ItemList";
import "./Profile.scss";
import CheckIcon from "@mui/icons-material/Check";
import OrderService from "../../services/OrderService";
import { deleteCart } from "../../redux/actions/cartActions";

const profileSer = new RegisterUser();
const orderService = new OrderService();

function OrderDetail() {
  const { orderId } = useParams<{ orderId: string }>();
  const paymentStatusRef = useRef<ReturnType<typeof setInterval>>();
  const location: any = useLocation().state;
  const dispatch = useDispatch();
  const {
    claim_id,
    create_date,
    status_id,
    drugs,
    total_price_drugs,
    total_price,
    pay_type_id,
    self_collect,
    status_name,
    images,
    delivery_cost,
    delivery_amount,
    transaction_id,
  } = useSelector((state: ReducersModal) => state.userOrderDetailReducer);
  const { phone_number, first_name, last_name, email } = useSelector(
    (state: ReducersModal) => state.profileReducer
  );
  const [openPaymentDialog, setPaymentDialog] = useState(false);
  const scrollRef = useRef<any>(null);

  const [coordinates, setCoordinates] = useState<any>();
  const [pharmacyPhone, setPharmacyPhone] = useState("");
  const [openPreview, setOpenPreview] = useState(false);
  const [previewImage, setPreviewImg] = useState<any>();
  const [pharmacyId, setPharmacyId] = useState("");
  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  const executeScroll = () => scrollRef.current.scrollIntoView();
  useEffect(() => {
    
    console.log("dfdfhdf",window.location.search)
    setPayURoute("")
    const params = new URLSearchParams(window.location.search)
    console.log("params", params.get("status"))
    if(params.get("status")){
      dispatch(deleteCart());
      window.location.href= `/#/user/my-order/detail/${orderId}`
    }
    
    executeScroll();
    
  }, []);

  useEffect(() => {
    if (orderId) {
      paymentStatusRef.current = setInterval(() => {
        if (pay_type_id === "1") {
          orderPaymentStatus(orderId)
            .then((res: any) => {
              console.log(res?.data);
              if (res?.data?.status_id !== "10") {
                dispatch(getUserOrderDetail(orderId));
                clearInterval(paymentStatusRef.current);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }, 10000);
    }

    return () => {
      clearInterval(paymentStatusRef.current);
    };
  }, [orderId, pay_type_id]);

  useEffect(() => {
    if (orderId) {
      dispatch(getUserOrderDetail(orderId));
      if (pay_type_id === "1") {
        orderPaymentStatus(orderId).then((res) => {
          console.log("res", res);
        });
      }
    }
  }, [dispatch, orderId, pay_type_id]);

  useEffect(() => {
    profileSer.bannerDetail().then((res: any) => {
      console.log("banner response", res);
      if (res?.data?.body?.chemist_details) {
        setCoordinates({
          lat: parseFloat(res?.data?.body?.chemist_details?.latitude),
          lng: parseFloat(res?.data?.body?.chemist_details?.longitude),
        });
        setPharmacyPhone(res?.data?.body?.chemist_details?.phone_number);
        setPharmacyId(res?.data?.body?.chemist_details?.id);
      }
    });
    console.log(
      "location.open, location.orderId",
      location?.open,
      location?.orderId
    );
  }, []);
  const handlePaymentClose = () => {
    setPaymentDialog(false);
    dispatch(getUserOrderDetail(orderId as any));
  };
  const openDialog = () => {
    setPaymentDialog(true);
  };
  // useEffect(() => {
  //   if (pay_type_id === "0") {
  //     dispatch(globalLoaderStart());
  //     dispatch(getUserOrderDetail(orderId as any));
  //   }
  // }, [pay_type_id]);
  const keyValue = (key: string, value: string) => (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        mt: 0.5,
      }}
    >
      <Typography
        sx={{
          font: (theme) =>
            `normal ${theme.typography.fontWeightLight} ${theme.spacing(
              1.8
            )} Josefin Sans, sans-serif`,
        }}
      >
        {key} -
      </Typography>
      <Typography color="primary" variant="h4" sx={{ ml: 0.5 }}>
        {value}
      </Typography>
    </Box>
  );
  const changeOrderStatus = () => {
    dispatch(globalLoaderStart());
    orderService
      .orderStatus({ pharmacy_id: pharmacyId, button_type: "1" }, orderId)
      .then((res) => {
        dispatch(globalLoaderEnd());
        dispatch(getUserOrderDetail(orderId as any));
      })
      .catch((error: any) => {
        dispatch(globalLoaderEnd());
      });
  };

  return (
    <div ref={scrollRef}>
      {status_id === "12" && self_collect === "1" && (
        <div className="collected-box">
          <button
            className="btn btn-white"
            style={{ marginLeft: "auto", marginRight: "auto" }}
            onClick={changeOrderStatus}
          >
            <CheckIcon></CheckIcon> Collected
          </button>
          <h3 className="center mt-10">
            Tap on the "Collected" button above once you have picked up your
            order at the pharmacy
          </h3>
        </div>
      )}
      {((status_id === "3" &&
        pay_type_id === "0" &&
        !images?.length &&
        !location?.open) ||
        (status_id === "11" && pay_type_id === "1")) && (
        <div className="collected-box">
          <button
            className="btn btn-white"
            style={{ marginLeft: "auto", marginRight: "auto", width: "150px" }}
            onClick={openDialog}
          >
            Retry Payment
          </button>
        </div>
      )}
      {status_id === "3" && images?.length > 0 ? (
        <div className="collected-box">
          {/* className="btn-cover flex-center skeu-card" */}
          <button
            className="btn btn-white"
            style={{ width: "150px", marginLeft: "auto", marginRight: "auto" }}
            onClick={openDialog}
          >
            Pay Now
          </button>
        </div>
      ) : (
        ""
      )}
      <div className="skeu-card" style={{ marginBottom: "20px" }}>
        <h3 className="semi-bold">Order Details</h3>
        <hr className="mt-15 mb-10" />
        <Grid container spacing={{ sm: 1, xs: 1 }}>
          <Grid item xs={12} lg={6}>
            <h3 className="bold">
              Order ID : {orderId || "N/A"}{" "}
              {claim_id && `(Claim ID - ${claim_id})`}
            </h3>
          </Grid>
          <Grid item xs={12} lg={6}>
            <h3 className="bold">
              Order Date : {create_date ? getOrderDate(create_date) : ""}
            </h3>
          </Grid>

          <Grid item xs={12} lg={6}>
            <h3 className="semi-bold">
              Order Status :{" "}
              <span
                className="capitalize"
                style={{
                  color: getOrderStatusBgColor(status_id),
                  fontWeight: "bold",
                }}
              >
                {status_name === "Best offer" && !images?.length
                  ? "Payment pending for your order"
                  : getOrderStatus(status_id)}
              </span>
            </h3>
          </Grid>

          <Grid item xs={12} lg={6}>
            <h3 className="semi-bold">
              Order Type : {self_collect === "1" ? "Self Collect" : "Delivery"}
            </h3>
          </Grid>
          {total_price_drugs && drugs.length > 0 && (
            <Grid item xs={12} lg={6}>
              <h3 className="semi-bold">
                {total_price_drugs
                  ? `Cost of Drugs : ${total_price_drugs} KES`
                  : ""}
              </h3>
            </Grid>
          )}
          {delivery_amount !== "0.00" && drugs.length > 0 && (
            <Grid item xs={12} lg={6}>
              <h3 className="semi-bold">
                {total_price ? `Cost of Delivery : ${delivery_amount} KES` : ""}
              </h3>
            </Grid>
          )}
          <Grid item xs={12} lg={6}>
            {total_price && drugs.length > 0 ? (
              <h3 className="semi-bold">Total Price : {total_price} KES</h3>
            ) : (
              ""
            )}
          </Grid>
          {pay_type_id !== "0" ? (
            <Grid item xs={12} lg={6}>
              <h3 className="semi-bold">
                Payment by :{" "}
                {pay_type_id == "1" ? "MPESA" : "Debit/Credit card"}
              </h3>
            </Grid>
          ) : (
            ""
          )}
          {self_collect === "1" && drugs.length > 0 && pay_type_id !== "0" ? (
            <Grid item xs={12} lg={6}>
              <h3 className="semi-bold">
                Pharmacist : {`(+254)${pharmacyPhone}`}
              </h3>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </div>

      {images?.length > 0 && (
        <Box className="skeu-card" style={{ marginBottom: "20px" }}>
          <Box>
            <h2>Attached Prescription</h2>
          </Box>
          <div
            className="flex-center flex-wrap"
            style={{ margin: "0px -10px" }}
          >
            {images.map((image: string, index: number) => (
              <Avatar
                key={index}
                sx={{
                  width: 100,
                  height: 100,
                  margin: "10px",
                  my: 1.5,
                  cursor: "pointer",
                  boxShadow: 1,
                }}
                variant="rounded"
                src={image ? process.env.REACT_APP_API_URL + image : ""}
                onClick={() => {
                  setPreviewImg(process.env.REACT_APP_API_URL + image);
                  setOpenPreview(true);
                }}
              ></Avatar>
            ))}
          </div>
          <br />
        </Box>
      )}
      {drugs?.length > 0 ? <ItemList data={drugs} /> : <></>}

      {self_collect === "1" && drugs.length > 0 && pay_type_id !== "0" ? (
        <div className="skeu-card" style={{ marginBottom: "20px" }}>
          <Box>
            <Grid item xs={12} sm={12}>
              <MapToReview coordinates={coordinates} />
            </Grid>
          </Box>
          &nbsp;
        </div>
      ) : (
        ""
      )}

      {drugs?.length > 0 && !(status_id === "3" && images?.length > 0) ? (
        <Box
          style={{
            position: "sticky",
            bottom: "0px",
            background: "white",
            width: "100%",
            paddingTop: "20px",
          }}
          className="skeu-card"
        >
          <h3 style={{ textAlign: "center" }}>
            {self_collect === "1"
              ? `To access more features and to consult with Doctors online`
              : `To Track your order, access more features and to consult with
            Doctors online`}
          </h3>

          <button
            className="btn btn-primary"
            style={{ margin: "10px auto" }}
            onClick={() => {
              window.open("https://bit.ly/38YPqRC", "_blank");
            }}
          >
            Tap to download Livia Health App
          </button>
        </Box>
      ) : (
        <></>
      )}
      <PaymentDialog
        openDialog={openPaymentDialog ? true : false}
        onClose={handlePaymentClose}
        config={{
          public_key: process.env.REACT_APP_FLUTTERWAVE_KEY,
          tx_ref: Date.now(),
          amount: (
            parseFloat(total_price) 
          ).toFixed(2), //"", //totalToPay,//+ parseFloat(delivery_amount)
          currency: "KES",
          payment_options: "card",
          customer: {
            email:
              getGuestUser() === "true" ? getGuestUserData()?.email : email,
            phonenumber:
              getGuestUser() === "true"
                ? getGuestUserData()?.phone_number
                : phone_number,
            name:
              getGuestUser() === "true"
                ? `${getGuestUserData()?.first_name} ${
                    getGuestUserData()?.last_name
                  }`
                : `${first_name} ${last_name}`,
          },
        }}
        // setshowFlutterWave={setshowFlutterWave}
        totalToPay={(
          parseFloat(total_price) ).toFixed(2)} ////+ parseFloat(delivery_amount)
        orderId={orderId}
      />
      {/* </Box> */}
      <PreviewImage
        open={openPreview}
        handleClose={handleClosePreview}
        image={previewImage as any}
      />
      <PaymentSuccessDialog
        open={location?.open ? true : false}
        orderId={location?.orderId}
      />
    </div>
  );
}

export default OrderDetail;
