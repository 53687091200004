import React from "react";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { toast } from "react-toastify";
import { toastIdsConstant } from "../utility/util";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteCart } from "../redux/actions/cartActions";
import { getUserOrderDetail } from "../pages/Profile/action";

import { useState } from "react";
import showAlert from "../utility/alert";

function FlutterWave(props) {
  const { config, onCancel, orderId, setshowFlutterWave } = props;
  const handleFlutterPayment = useFlutterwave(config);
  console.log("config", config);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClick = () => {
    handleFlutterPayment({
      callback: async (response) => {
        closePaymentModal();
        setshowFlutterWave(false);
        if (onCancel) onCancel();
        // if (handleCancel) {
        //     dispatch(getAppointmentDetailForWebsiteAction(eventId));
        //     handleCancel();
        // }
        if (response?.status === "successful") {
          console.log(response, orderId);
          // dispatch(getUserOrderDetail(orderId));
          // dispatch(deleteCart());
          // setOpenDialog(true);
          navigate(`/user/my-order/detail/${orderId}`, {
            state: {
              open: true,
              orderId: orderId,
            },
          });
          // window.location.reload();
          // if (document.querySelectorAll("iframe[name=checkout]").length >= 3) {
          //   window.alert(
          //     "Payment Successful: Awesome, your order has been confirmed."
          //   );
          //   window.location.reload();
          //   localStorage.setItem("cart", JSON.stringify([]));
          //   navigate(`/user/my-order/detail/${orderId}`);
          // }
          // if (sessionStorage.getItem("paymentToast") != "true") {
          //   sessionStorage.setItem("paymentToast", "true");
          //   dispatch(showSuccessDialog({ open: true, orderId: orderId }));
          //   dispatch(deleteCart());
          // }
          // if (!toast.isActive(toastIdsConstant?.paymentSuccess)) {
          //   sessionStorage.setItem("paymentToast", "true");
          //   localStorage.setItem("cart", JSON.stringify([]));
          //   return (toastIdsConstant.paymentSuccess = toast.success(
          //     "Payment Successful: Awesome, your order has been confirmed.",
          //     // "Waiting for payment approval",
          //     {
          //       toastId: toastIdsConstant.paymentSuccess,
          //       className: "toast-success",
          //       onClose: () => {
          //         dispatch(getUserOrderDetail(orderId));
          //         // window.location.reload();
          //         sessionStorage.setItem("paymentToast", "false");
          //         dispatch(deleteCart());
          //       },
          //     }
          //   ));
          // }
          // alert("Payment Successful: Awesome, your appointment has been confirmed.")
          // window.location.reload();
        } else {
          if (!toast.isActive(toastIdsConstant?.paymentSuccess)) {
            return (toastIdsConstant.paymentSuccess = toast.error(
              `Your payment status is ${response?.status}`,
              {
                toastId: toastIdsConstant.paymentSuccess,
                className: "toast-error",
              }
            ));
          }
        }
      },
      onClose: () => {
        // props?.history?.push(`/my-appointments/detail/${currentApptId}`);
        // if (onCancel) onCancel();
        // if (document.querySelectorAll("iframe[name=checkout]").length >= 3) {
        //     // window.location.reload();
        closePaymentModal();
        showAlert(2, "Payment failed. Please retry to pay again.");
        navigate(`/user/my-order/detail/${orderId}`);
        // }
        setshowFlutterWave(false);
      },
    });
  };
  return (
    <>
      <button id="flutterWaveButton" onClick={handleClick()}></button>
    </>
  );
}

export default FlutterWave;
