import {
  ADD_PRESCRIPTION,
  DELETE_PRESCRIPTION_LIST,
  REMOVE_PRESCRIPTION,
} from "../../utility/actionConstant";

export const addPrescription = (prescription: any) => (dispatch: any) => {
  const prescriptionList = localStorage.getItem("prescription")
    ? JSON.parse(localStorage.getItem("prescription") as string)
    : [];
  prescriptionList.push({ ...prescription });
  localStorage.setItem("prescription", JSON.stringify(prescriptionList));
  dispatch({ type: ADD_PRESCRIPTION, payload: prescriptionList });
};

export const removePrescription =
  (indexToRemove: number) => (dispatch: any) => {
    let prescriptionList = localStorage.getItem("prescription")
      ? JSON.parse(localStorage.getItem("prescription") as string)
      : [];
    prescriptionList.splice(indexToRemove, 1);
    localStorage.setItem("prescription", JSON.stringify(prescriptionList));
    dispatch({ type: REMOVE_PRESCRIPTION, payload: prescriptionList });
  };

export const deletePrescriptionList = () => (dispatch: any) => {
  localStorage.removeItem("prescription");
  dispatch({ type: DELETE_PRESCRIPTION_LIST, payload: [] });
};
