export const selectPhone = [{ key: 254, value: 254 }];
export const selectName = [
  { key: "Dr.", value: "Dr." },
  { key: "Mr.", value: "Mr." },
  { key: "Mrs.", value: "Mrs." },
  { key: "Ms.", value: "Ms." },
];

export enum UserStatus {
  ACTIVE = "1",
  NOT_ACTIVE = "2",
  MODERATION = "3",
  REFUSAL_TO_REGISTRATION = "4",
  BAN = "5",
  IN_REGISTRATION_PROCESS = "6",
}

export const addressType = [
  { address_title: "Home", address_type: "1" },
  { address_title: "Work", address_type: "2" },
  { address_title: "Other", address_type: "3" },
];
export const gender = [
  { name: "Male", value: "1" },
  { name: "Female", value: "2" },
];

export const salutations = [
  { name: "Dr.", key: 1 },
  { name: "Mr.", key: 2 },
  { name: "Mrs.", key: 3 },
  { name: "Ms.", key: 4 },
  { name: "Prof.", key: 5 },
];

export enum OrderStatus {
  IN_PROCESS = 1,
  NO_OFFERS = 2,
  BEST_OFFER = 3,
  PREPARE_ORDER = 4,
  ON_DELIVERY = 5,
  CANCELLED = 6,
  RECEIVED = 7,
  NOT_RECEIVED = 8,
  REFUNDED = 9,
  IN_PAYMENT = 10,
  PAYMENT_UNSUCCESSFUL = 11,
  TAKEAWAY = 12,
  PARTIALLY_CLOSED = 13,
  ON_MODERATION = 14,
  ALTERNATIVE_ITEMS = 15,
  BEST_OFFER_WITH_ALTERNATIVE_ITEMS = 16,
  PRESCRIPTION_NEED_PAY = 17,
  PRESCRIPTION_IN_PAYMENT_PROCESS = 18,
  PRESCRIPTION_PAYMENT_UNSUCCESSFUL = 19,
  PRESCRIPTION_NOT_PAY_CANCELLED = 20,
  PRE_AUTHORIZATION = 21,
  PRESCRIPTION_ON_MODERATION = 22,
  PRESCRIPTION_MODERATION_FAILED = 23,

  APPOINTMENT_NEED_PAY = 24,
  APPOINTMENT_IN_PAYMENT_PROCESS = 25,
  APPOINTMENT_PAYMENT_UNSUCCESSFUL = 26,
  APPOINTMENT_NOT_PAY_CANCELLED = 27,
  APPOINTMENT_ON_MODERATION = 28,
  APPOINTMENT_MODERATION_FAILED = 29,
  APPOINTMENT_PAYMENT_RECIVED = 30,

  VDPS_NEED_PAY = 31,
  VDPS_IN_PAYMENT_PROCESS = 32,
  VDPS_PAYMENT_UNSUCCESSFUL = 33,
  VDPS_NOT_PAY_CANCELLED = 34,
  VDPS_PAYMENT_RECIVED = 35,

  APPEAL_NEED_PAY = 36,
  APPEAL_IN_PAYMENT_PROCESS = 37,
  APPEAL_PAYMENT_UNSUCCESSFUL = 38,
  APPEAL_NOT_PAY_CANCELLED = 39,
  APPEAL_PAYMENT_RECIVED = 40,
}

export enum PaymentStatus {
  MPESA = 1,
  CASH = 2,
  PAYPAL_ACCOUNT = 3,
  PAYPAL_CARD = 4,
  WEBPAY = 5,
  INSURANCE = 6,
  FLUTTER_WAVE = 7,
}

export const medicineList = [
  {
    id: "8016",
    name: "Paracetamol 500mg - generic",
    api: "paracetamolm 5t00mg",
    quantity_measuring: "tablets",
    price: "1.00",
    trade_price: "0.75",
    description: "mild pain management",
    image: null,
    customer_price: 0.8625,
    type: 1,
    quantity: 0,
  },
  {
    id: "2817",
    name: "ParaCETAMOL TABLETS 500 mg",
    api: "Paracetamol",
    quantity_measuring: "\u0027s",
    price: "5.00",
    trade_price: "3.76",
    description:
      "fever,mild to moderate pain;headache,migraine,neuralgia,toothache,sore throat,aches,pains",
    image: null,
    customer_price: 4.324,
    type: 1,
    quantity: 0,
  },
  {
    id: "12345",
    name: "paradontax  toothpaste",
    api: "paradontal paste",
    quantity_measuring: "pack",
    price: "300.00",
    trade_price: "225.56",
    description: "",
    image: null,
    customer_price: 259.394,
    type: 1,
    quantity: 0,
  },
  {
    id: "11955",
    name: "parafast 1gm",
    api: "Paracetamol",
    quantity_measuring: "",
    price: "25.00",
    trade_price: "18.80",
    description: "",
    image: null,
    customer_price: 21.62,
    type: 1,
    quantity: 0,
  },
  {
    id: "2821",
    name: "PARAFAST ET 1000 TABLETS",
    api: "Paracetamol",
    quantity_measuring: "\u0027s",
    price: "25.00",
    trade_price: "18.80",
    description:
      "fever,mild to moderate pain;headache,migraine,neuralgia,toothache,sore throat,aches,pains",
    image: null,
    customer_price: 21.62,
    type: 1,
    quantity: 0,
  },
  {
    id: "1068",
    name: "A.P.C TABLETS",
    api: "Aspirin/Paracetamol/Caffeine",
    quantity_measuring: " TABLETS",
    price: "1.00",
    trade_price: "0.75",
    description:
      "mild to moderate pain,fever,rheumatic fever,osteoarthritis,rhematoid arthritis",
    image: null,
    customer_price: 0.8625,
    type: 1,
    quantity: 0,
  },
  {
    id: "11520",
    name: "A.P.C TABLETS (100 tablets)",
    api: "Aspirin/Paracetamol/Caffeine",
    quantity_measuring: " TABLETS",
    price: "3.00",
    trade_price: "2.26",
    description: null,
    image: null,
    customer_price: 2.5989999999999998,
    type: 1,
    quantity: 0,
  },
  {
    id: "11521",
    name: "A.P.C TABLETS (500 tablets)",
    api: "Aspirin/Paracetamol/Caffeine",
    quantity_measuring: " TABLETS",
    price: "1.00",
    trade_price: "0.75",
    description: null,
    image: null,
    customer_price: 0.8625,
    type: 1,
    quantity: 0,
  },
  {
    id: "153",
    name: "ACEPAR-SP CAPLETS",
    api: "ACECLOFENAC, PARACETAMOL AND SERRATIOPEPTIDASE",
    quantity_measuring: " TABLETS",
    price: "33.00",
    trade_price: "24.81",
    description:
      "muscle pain,osteoarthritis,toochache,bones and joint pain,swelling,ankylosing spondilytis, rheumatoid arthritis, upper respiratory tract infection",
    image: null,
    customer_price: 28.531499999999998,
    type: 1,
    quantity: 0,
  },
  {
    id: "12733",
    name: "Acetaminophen",
    api: "Paracetamol",
    quantity_measuring: "bottle",
    price: "10.00",
    trade_price: "7.52",
    description: "",
    image: null,
    customer_price: 8.648,
    type: 1,
    quantity: 0,
  },
  {
    id: "148",
    name: "ACLOTAS-P TABLETS",
    api: "Aceclofenac BP 100 mg Paracetamol BP 500 mg",
    quantity_measuring: "TABLETS",
    price: "42.00",
    trade_price: "31.58",
    description: "PAIN AND INFLAMATION",
    image: null,
    customer_price: 36.317,
    type: 1,
    quantity: 0,
  },
  {
    id: "2309",
    name: "Admol MR",
    api: "CHLORZOXAZONE/DICLOFENAC/PARACETAMOL",
    quantity_measuring: " tablets",
    price: "15.00",
    trade_price: "11.28",
    description:
      "low back pain,Traumatic muscle injury and fibrosis,Tension headaches,Torticollis,Myalgia,Sprains and Strains,Cervical root and disc syndromes",
    image: null,
    customer_price: 12.972,
    type: 1,
    quantity: 0,
  },
  {
    id: "8613",
    name: "Adol 125mg suppositories",
    api: "ACETAMINOPHEN",
    quantity_measuring: "suppository",
    price: "30.00",
    trade_price: "22.56",
    description: "",
    image: null,
    customer_price: 25.944,
    type: 1,
    quantity: 0,
  },
  {
    id: "8614",
    name: "Adol 250mg suppositories",
    api: "ACETAMINOPHEN",
    quantity_measuring: "suppository",
    price: "30.00",
    trade_price: "22.56",
    description: "",
    image: null,
    customer_price: 25.944,
    type: 1,
    quantity: 0,
  },
  {
    id: "1069",
    name: "APYCY TABLETS",
    api: "Aspirin/Paracetamol/Caffeine",
    quantity_measuring: " TABLETS",
    price: "2.00",
    trade_price: "1.50",
    description:
      "mild to moderate pain,fever,rheumatic fever,osteoarthritis,rhematoid arthritis",
    image: null,
    customer_price: 1.725,
    type: 1,
    quantity: 0,
  },
  {
    id: "1074",
    name: "Aspec Tablets",
    api: "Aspirin/Paracetamol/Caffeine",
    quantity_measuring: " TABLETS",
    price: "2.00",
    trade_price: "1.50",
    description:
      "mild to moderate pain,fever,rheumatic fever,osteoarthritis,rhematoid arthritis",
    image: null,
    customer_price: 1.725,
    type: 1,
    quantity: 0,
  },
  {
    id: "5955",
    name: "Benylin For Flu Liquid 200ml",
    api: "Diphenhydramine HCl, Paracetamol,  Pseudoephedrine HCL",
    quantity_measuring: "bottle",
    price: "798.00",
    trade_price: "600.00",
    description:
      "Relief from the symptoms of colds and flu, such as headache, sore throat, aches and pains, fever, blocked or runny nose and tickly cough.",
    image: "/images/drugs/5955/58e12ac7e9a3a1491151559.jpg",
    customer_price: 690,
    type: 1,
    quantity: 0,
  },
  {
    id: "8615",
    name: "Betamol suspension",
    api: "ACETAMINOPHEN",
    quantity_measuring: "bottle",
    price: "25.00",
    trade_price: "18.80",
    description: "",
    image: null,
    customer_price: 21.62,
    type: 1,
    quantity: 0,
  },
  {
    id: "8616",
    name: "Betamol Tablets",
    api: "ACETAMINOPHEN",
    quantity_measuring: "tablet",
    price: "1.00",
    trade_price: "0.75",
    description: "",
    image: null,
    customer_price: 0.8625,
    type: 1,
    quantity: 0,
  },
  {
    id: "2890",
    name: "Betapyn",
    api: "Paracetamol 450mg Codeine phosphate 10mg Caffeine 45mg Doxylamine Succinate 5mg Caffeine 50mg",
    quantity_measuring: "tablets",
    price: "20.00",
    trade_price: "15.04",
    description: "",
    image: null,
    customer_price: 17.296,
    type: 1,
    quantity: 0,
  },
  {
    id: "3978",
    name: "Brustan Suspension",
    api: "Ibuprofen/Paracetamol",
    quantity_measuring: " bottle",
    price: "367.00",
    trade_price: "275.94",
    description:
      "Relief of headache from musculoskeletal origin,muscular,menstrual and dental pain",
    image: null,
    customer_price: 317.331,
    type: 1,
    quantity: 0,
  },
  {
    id: "3979",
    name: "Brustan Tablets",
    api: "Ibuprofen/Paracetamol",
    quantity_measuring: "\u0027s",
    price: "14.00",
    trade_price: "10.53",
    description:
      "Relief of headache from musculoskeletal origin,muscular,menstrual and dental pain",
    image: null,
    customer_price: 12.109499999999999,
    type: 1,
    quantity: 0,
  },
  {
    id: "3870",
    name: "Buscopan Plus Tablets",
    api: "Hyoscine Butyl Bromide/Paracetamol",
    quantity_measuring: "tablets",
    price: "36.00",
    trade_price: "27.07",
    description:
      "Paroxysmal and spastic pain in GIT, GUT and biliary disorders",
    image: null,
    customer_price: 31.1305,
    type: 1,
    quantity: 0,
  },
  {
    id: "8504",
    name: "calpol for babies",
    api: "paracetamol",
    quantity_measuring: "",
    price: "230.00",
    trade_price: "172.93",
    description: "",
    image: null,
    customer_price: 198.86950000000002,
    type: 1,
    quantity: 0,
  },
  {
    id: "12148",
    name: "Calpol infant 60ml",
    api: "Paracetamol",
    quantity_measuring: "",
    price: "221.00",
    trade_price: "166.17",
    description: "",
    image: null,
    customer_price: 191.0955,
    type: 1,
    quantity: 0,
  },
  {
    id: "12147",
    name: "Calpol infant syrup 100ml",
    api: "Paracetamol",
    quantity_measuring: "",
    price: "320.00",
    trade_price: "240.60",
    description: "",
    image: null,
    customer_price: 276.69,
    type: 1,
    quantity: 0,
  },
  {
    id: "2794",
    name: "CALPOL SUSPENSION",
    api: "Paracetamol",
    quantity_measuring: "bottle",
    price: "330.00",
    trade_price: "248.12",
    description:
      "fever,mild to moderate pain;headache,migraine,neuralgia,toothache,sore throat,aches,pains",
    image: null,
    customer_price: 285.338,
    type: 1,
    quantity: 0,
  },
  {
    id: "12211",
    name: "cipladon 1000 mg",
    api: "paracetamol",
    quantity_measuring: "",
    price: "37.00",
    trade_price: "27.82",
    description: "",
    image: null,
    customer_price: 31.993000000000002,
    type: 1,
    quantity: 0,
  },
  {
    id: "2872",
    name: "Cipladon 1000MG",
    api: "Paracetamol",
    quantity_measuring: "tablets",
    price: "37.00",
    trade_price: "27.82",
    description:
      "fever,mild to moderate pain;headache,migraine,neuralgia,toothache,sore throat,aches,pains",
    image: null,
    customer_price: 31.993000000000002,
    type: 1,
    quantity: 0,
  },
  {
    id: "8129",
    name: "cipladon 1g",
    api: "paracetamol",
    quantity_measuring: "",
    price: "37.00",
    trade_price: "27.82",
    description: "",
    image: null,
    customer_price: 31.993000000000002,
    type: 1,
    quantity: 0,
  },
  {
    id: "8444",
    name: "cipladon 500g",
    api: "paracetamol",
    quantity_measuring: "",
    price: "19.00",
    trade_price: "14.29",
    description: "",
    image: null,
    customer_price: 16.4335,
    type: 1,
    quantity: 0,
  },
  {
    id: "2841",
    name: "Cipladon 500MG",
    api: "Paracetamol",
    quantity_measuring: "\u0027s",
    price: "19.00",
    trade_price: "14.29",
    description:
      "fever,mild to moderate pain;headache,migraine,neuralgia,toothache,sore throat,aches,pains",
    image: null,
    customer_price: 16.4335,
    type: 1,
    quantity: 0,
  },
  {
    id: "2934",
    name: "COLD-FLU CAPSULES",
    api: "paracetamol/Chlorpheniramine Maleate",
    quantity_measuring: "cap",
    price: "5.00",
    trade_price: "3.76",
    description: "",
    image: null,
    customer_price: 4.324,
    type: 1,
    quantity: 0,
  },
  {
    id: "2927",
    name: "Coldcap Daytime  Capsules",
    api: "Paracetamol Pseudophedrine Hcl Caffein anhydrous",
    quantity_measuring: "\u0027s",
    price: "8.00",
    trade_price: "6.02",
    description: "",
    image: null,
    customer_price: 6.923,
    type: 1,
    quantity: 0,
  },
  {
    id: "2933",
    name: "Coldcap Night Time capsules",
    api: "paracetamol/Chlorpheniramine Maleate",
    quantity_measuring: "cap",
    price: "10.00",
    trade_price: "7.52",
    description: "",
    image: null,
    customer_price: 8.648,
    type: 1,
    quantity: 0,
  },
  {
    id: "2925",
    name: "Coldcap original capsules",
    api: "Paracetamol Pseudoephedrine HCl   Caffeine anhyrous Chlorpheniramine Maleate",
    quantity_measuring: "\u0027s",
    price: "10.00",
    trade_price: "7.52",
    description: "",
    image: null,
    customer_price: 8.648,
    type: 1,
    quantity: 0,
  },
  {
    id: "2880",
    name: "Coldcap syrup",
    api: "Paracetamol  Chlorpheniramine Maleate Pseudoephedrine HCl",
    quantity_measuring: " bottle",
    price: "120.00",
    trade_price: "90.23",
    description: "flu and cold symptoms",
    image: null,
    customer_price: 103.7645,
    type: 1,
    quantity: 0,
  },
  {
    id: "2224",
    name: "CONTUS 650 Tablets",
    api: "Chlorphenamine 2MG Phenylephrine 5MG and Paracetamol 650MG Tablets",
    quantity_measuring: "tablet",
    price: "12.00",
    trade_price: "9.02",
    description: "",
    image: null,
    customer_price: 10.373,
    type: 1,
    quantity: 0,
  },
  {
    id: "2232",
    name: "CONTUS Plus Paediatric Suspension",
    api: "Chlorphenamine Phenylephrine and Paracetamol Suspension",
    quantity_measuring: " BOTTLE",
    price: "296.00",
    trade_price: "222.56",
    description: "RELEIF OF COLD AND FLU SYMPTOMS",
    image: null,
    customer_price: 255.94400000000002,
    type: 1,
    quantity: 0,
  },
  {
    id: "2887",
    name: "Corenza Cold and Flu Syrup",
    api: "Paracetamol 120 mg Pseudoephedrine hydrochloride 15 mg Chlorpheniramine maleate 2 mg per 5 ml",
    quantity_measuring: " bottle",
    price: "137.00",
    trade_price: "103.01",
    description: "flu and cold symptoms",
    image: null,
    customer_price: 118.4615,
    type: 1,
    quantity: 0,
  },
  {
    id: "12732",
    name: "Crocin",
    api: "Paracetamol",
    quantity_measuring: "capsule",
    price: "10.00",
    trade_price: "7.52",
    description: "",
    image: null,
    customer_price: 8.648,
    type: 1,
    quantity: 0,
  },
  {
    id: "5896",
    name: "Cyclopam P Tablets",
    api: "Dicycloverine Hydrochloride and Paracetamol Tablets",
    quantity_measuring: " tablets",
    price: "12.00",
    trade_price: "9.02",
    description: "",
    image: null,
    customer_price: 10.373,
    type: 1,
    quantity: 0,
  },
  {
    id: "5891",
    name: "DE-SPAS Tablets",
    api: "Dicyclomine Hydrochloride and Paracetamol Tablets",
    quantity_measuring: " tablets",
    price: "11.00",
    trade_price: "8.27",
    description: "",
    image: null,
    customer_price: 9.5105,
    type: 1,
    quantity: 0,
  },
  {
    id: "2949",
    name: "DOLAFREE-P",
    api: "Paracetamol/Tramadol",
    quantity_measuring: "\u0027s",
    price: "16.00",
    trade_price: "12.03",
    description: "",
    image: null,
    customer_price: 13.834499999999998,
    type: 1,
    quantity: 0,
  },
  {
    id: "2306",
    name: "Doloact-MR Tablets",
    api: "CHLORZOXAZONE/DICLOFENAC/PARACETAMOL",
    quantity_measuring: " tablets",
    price: "27.00",
    trade_price: "20.30",
    description:
      "low back pain,Traumatic muscle injury and fibrosis,Tension headaches,Torticollis,Myalgia,Sprains and Strains,Cervical root and disc syndromes",
    image: null,
    customer_price: 23.345,
    type: 1,
    quantity: 0,
  },
  {
    id: "3869",
    name: "Duxcospan Plus Tablets",
    api: "Hyoscine Butyl Bromide/Paracetamol",
    quantity_measuring: " tablets",
    price: "20.00",
    trade_price: "15.04",
    description:
      "Paroxysmal and spastic pain in GIT, GUT and biliary disorders",
    image: null,
    customer_price: 17.296,
    type: 1,
    quantity: 0,
  },
  {
    id: "6430",
    name: "ECOFREE PLUS TABLET",
    api: "Etoricoxib/Paracetamol",
    quantity_measuring: " tablets",
    price: "52.00",
    trade_price: "39.10",
    description: "",
    image: null,
    customer_price: 44.965,
    type: 1,
    quantity: 0,
  },
  {
    id: "8963",
    name: "Emulsifying ointment",
    api: "Emulsifying wax, white soft paraffin, liquid paraffin",
    quantity_measuring: "",
    price: "100.00",
    trade_price: "75.19",
    description:
      "Apply as a moisturiser, soap alternative or bath additive to soothe \u0026 protects very dry, cracked skin",
    image: null,
    customer_price: 86.46849999999999,
    type: 1,
    quantity: 0,
  },
];
