import { axiosInstance } from "../api/axiosInstance";
import { urlEndpoints } from "../api/endpoints";
import { getLocalStorageKeyData } from "../utility/util";

export default class AddressService {
  getAddress = async () => {
    return await axiosInstance.get(`${urlEndpoints.USER_ADDRESS}`, {
      headers: {
        "LiviaApp-city": getLocalStorageKeyData("user_city") || "",
      },
    });
  };
}
