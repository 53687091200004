import TextField, { TextFieldProps } from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { Autocomplete, FormHelperText } from "@mui/material";
import { useField, useFormikContext } from "formik";

type AutoCompleteWrapperProps = {
  name: string;
  options: any[];
  disabled?: boolean;
};

const StyledTextField = styled(TextField)<TextFieldProps>(() => ({}));

function AutoCompleteWrapper({
  name,
  options,
  disabled,
}: AutoCompleteWrapperProps) {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (_event: any, newValue: any) => {
    setFieldValue(name, newValue);
  };

  const configSelect = {
    ...field,
    onChange: handleChange,
  };

  const helperText = () => {
    if (meta && meta.touched && meta.error) {
      return <FormHelperText error>{meta.error}</FormHelperText>;
    }
    return null;
  };

  return (
    <>
      <Autocomplete
        {...configSelect}
        options={options ? options : []}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        getOptionLabel={(option) => option?.name}
        size="small"
        clearIcon={false}
        disabled={disabled}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            placeholder="Select City"
            InputProps={{
              ...params.InputProps,
              // startAdornment: (
              //   <InputAdornment position={"start"}>
              //     <AddLocationIcon color="primary" fontSize="small" />
              //   </InputAdornment>
              // ),
              endAdornment: <>{params.InputProps.endAdornment}</>,
            }}
          />
        )}
      />
      {helperText()}
    </>
  );
}

export default AutoCompleteWrapper;
