import { GlobalLoaderModal } from "../../modal";
import ACTION_NAME from "../../utility/actionConstant";

export const globalLoaderReducer = (
  state: GlobalLoaderModal = new GlobalLoaderModal(),
  action: any
) => {
  switch (action.type) {
    case ACTION_NAME.LOADING:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const globalLoaderStart = () => {
  return {
    type: ACTION_NAME.LOADING,
    payload: { isLoading: true },
  };
};

export const globalLoaderEnd = () => {
  return {
    type: ACTION_NAME.LOADING,
    payload: { isLoading: false },
  };
};
