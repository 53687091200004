import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { addToCart, removeFromCart } from "../../redux/actions/cartActions";
import { productImg } from "./../../utility/images";
import { IMedicine } from "./model/searchMedicineModel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import medicineIcon from "./../../assets/images/medicine-icon.svg";
import descriptionIcon from "./../../assets/images/description-icon.svg";
import questionIcon from "./../../assets/images/question-icon.svg";
import categoryIcon from "./../../assets/images/category-icon.svg";
import "./ProductDetail.scss";

function ProductDetails() {
  const location: any = useLocation().state;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(
    location.medicine.customer_price.toFixed(2)
  );
  // Math.ceil(location.medicine.customer_price)
  const cart = useSelector((state: any) => state.cart.cart);
  useEffect(() => {
    // to add one medicine in cart if none is added
    if (
      !location.medicine.hasOwnProperty("quantity") ||
      location.medicine.quantity === 0
    ) {
      dispatch(addToCart(location.medicine) as any);
    }
  }, []);
  useEffect(() => {
    for (const medicineObj of cart) {
      if (medicineObj.id === location.medicine.id) {
        setCount(medicineObj.quantity);
      }
    }
  }, [cart]);
  useEffect(() => {
    const price =
      count > 0
        ? count * location.medicine.customer_price
        : location.medicine.customer_price;
    setTotalPrice(price.toFixed(2));
    // setTotalPrice(Math.ceil(price));
  }, [count]);
  function changeCartQuantity(type: string, medicine: IMedicine) {
    if (type === "increment") {
      dispatch(addToCart(medicine) as any);
    }
    if (type === "decrement") {
      dispatch(removeFromCart(medicine) as any);
    }
  }
  return (
    <div className="page-product-detail">
      <h3 className="mb-30">
        {/* <span className="pointer" onClick={() => navigate(-1)}>
          Home /{" "}
        </span>{" "} */}
        <span className="color-primary justify-start">
          <ArrowBackIcon
            className="pointer mr-10"
            titleAccess="Go Back"
            onClick={() => navigate(-1)}
          />{" "}
          Product Details
        </span>
      </h3>
      <div className="upper-portion">
        <div className="left-portion">
          <div className="product-image">
            <img
              src={
                location.medicine.image
                  ? process.env.REACT_APP_API_URL + location.medicine.image
                  : productImg
              }
              alt="Product"
            />
          </div>
        </div>
        <div className="price-card">
          <h1 className="mb-30 bold">{location.medicine.name}</h1>
          {/* <h2 className="mb-30">Quantity: {count}</h2> */}
          <h1 className="mb-30 bold">Price : {totalPrice} KES</h1>
          {count !== 0 && (
            <div className="btn-cover" style={{ maxWidth: "200px" }}>
              <button
                className="btn btn-white btn-add"
                onClick={() => {
                  changeCartQuantity("decrement", location.medicine);
                }}
              >
                -
              </button>
              <span>{count}</span>
              <button
                className="btn btn-white btn-add"
                onClick={() => {
                  changeCartQuantity("increment", location.medicine);
                }}
              >
                +
              </button>
            </div>
          )}
          {count === 0 && (
            <button
              className="btn btn-white"
              style={{ width: "200px" }}
              onClick={() => {
                changeCartQuantity("increment", location.medicine);
              }}
            >
              Add to Cart
            </button>
          )}
          <button
            className="btn btn-white mt-20"
            style={{ width: "200px" }}
            onClick={() => {
              navigate("/cart");
            }}
          >
            Go to Cart
          </button>
        </div>
      </div>
      <div className="skeu-card">
        <h1 className="mb-20 bold justify-start">
          <img src={medicineIcon} className="mr-20" alt="Icon" />{" "}
          {location.medicine.name}
        </h1>
        <p>{location.medicine.api}</p>
      </div>
      {location.medicine.description && (
        <div className="skeu-card">
          <h1 className="mb-20 bold justify-start">
            <img src={descriptionIcon} className="mr-20" alt="Icon" />{" "}
            Description
          </h1>
          <p>{location.medicine.description}</p>
        </div>
      )}
      {/* <div className="skeu-card">
        <h1 className="mb-20 bold justify-start">
          <img src={questionIcon} className="mr-20" alt="Icon" /> How to Use
        </h1>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos, odit
          quas ex similique veniam a est cumque obcaecati quibusdam, sunt
          perferendis id numquam porro dolor? Neque sit voluptates quidem
          pariatur.
        </p>
      </div>
      <div className="skeu-card">
        <h1 className="mb-20 bold justify-start">
          <img src={categoryIcon} className="mr-20" alt="Icon" /> Product
          Category
        </h1>
        <p className="mb-20">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos, odit
          quas ex similique veniam a est cumque obcaecati quibusdam, sunt
          perferendis id numquam porro dolor? Neque sit voluptates quidem
          pariatur.
        </p>
      </div> */}
    </div>
  );
}

export default ProductDetails;
