import { styled } from "@mui/material/styles";
import TableCell, {
  tableCellClasses,
  TableCellProps,
} from "@mui/material/TableCell";
import React from "react";

type TableCellWrapperProps = {
  children: React.ReactNode;
} & TableCellProps;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.primary.main,
    font: `normal ${theme.typography.fontWeightBold} ${theme.spacing(
      1.5
    )} Roboto sans, sans-serif`,
    textAlign: "center",
    padding: theme.spacing(0.5, 0.5),
  },
  [`&.${tableCellClasses.body}`]: {
    font: `normal ${theme.typography.fontWeightLight} ${theme.spacing(
      1.5
    )}  Roboto sans, sans-serif`,
    textAlign: "center",
    padding: theme.spacing(1.5, 0.5),
  },
}));

function TableCellWrapper({ children, ...otherProps }: TableCellWrapperProps) {
  return <StyledTableCell {...otherProps}>{children}</StyledTableCell>;
}

export default TableCellWrapper;
