import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import showAlert from "../../utility/alert";
import FlutterWave from "../FlutterWave";
import { useDispatch } from "react-redux";
import { makePayment } from "../../redux/actions/paymentAction";
import {
  globalLoaderEnd,
  globalLoaderStart,
} from "../../redux/reducers/globalReducer";
import { useNavigate } from "react-router-dom";
import { deleteCart } from "../../redux/actions/cartActions";
import { PayUService } from "../../services/PayUService";
import { setPayURoute } from "../../utility/util";
import Loader from "../Loader";
const payTypes = {
  mpesa: "mpesa",
  creditDebitCard: "creditDebitCard",
};

function PaymentDialog(props: any) {
  const { openDialog, onClose, config, totalToPay, orderId, fromCheckout } =
    props;
  const [currentPayType, setCurrentPayType] = useState("");
  const [showFlutterWave, setshowFlutterWave] = useState(false);
  const [currentApptId, setcurrentApptId] = useState(null);
  const [flutterWaveRef, setflutterWaveRef] = useState();
  const [configToSend, setConfig] = useState<any>(null);
  const [connectingToPayU, setConnectingToPayu] = useState(false) 
  const payUService = new PayUService()

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    // if (flutterWaveRef) {
    // console.log("flutterWaveRef", flutterWaveRef);
    // setshowFlutterWave(true);
    // }
  }, [flutterWaveRef]);
  useEffect(() => {
    // console.log(currentPayType);
  }, [currentPayType]);
  const handlePay = async () => {
    if (currentPayType == "") {
      showAlert(3, "Please select payment method");
      return;
    }
    if (currentPayType === payTypes.mpesa) {
      console.log("orderId", orderId);
      const payload = {
        amount: totalToPay.toString(),
        pay_type: currentPayType,
        orderId: orderId,
      };
      dispatch(globalLoaderStart());
      dispatch(makePayment(payload))
        .then((res: any) => {
          dispatch(globalLoaderEnd());
          console.log(res?.data);
          if (res?.data?.code === 200) {
            onClose();
            localStorage.removeItem("cart");
            dispatch(deleteCart());
            navigate(`/user/my-order/detail/${orderId}`);
          }
        })
        .catch((error: any) => {
          dispatch(globalLoaderEnd());
          if (
            error?.response?.data?.messages &&
            error?.response?.data?.messages[0]
          ) {
            showAlert(2, error?.response?.data?.messages[0]);
          }
        });
    }
    if (currentPayType === payTypes.creditDebitCard) {
      dispatch(globalLoaderStart());
      console.log("orderId", orderId);
      const payload = {
        amount: totalToPay.toString(),
        // pay_type: "flutter_wave",
        pay_type: "payu",
        orderId: orderId,
      };
      dispatch(makePayment(payload))
        .then((res: any) => {
          dispatch(globalLoaderEnd());
          // console.log(res?.data?.transaction_reference);
          if (res?.data?.transaction_reference) {
            // onClose();
            // setflutterWaveRef(res?.data?.transaction_reference);
            // setcurrentApptId(res?.data?.transaction_reference);
            // setshowFlutterWave(true);
            // setConfig({ ...config, tx_ref: res?.data?.transaction_reference });
            callPayU(res?.data?.transaction_reference, totalToPay, orderId)
            

          }

          // setflutterWaveRef(res)
          // if (res?.data?.code === 200) {
          //   onClose();
          //   localStorage.removeItem("cart");
          //   navigate(`/user/my-order/detail/${orderId}`);
          // }
        })
        .catch((error: any) => {
          dispatch(globalLoaderEnd());
          if (
            error?.response?.data?.messages &&
            error?.response?.data?.messages[0]
          ) {
            showAlert(2, error?.response?.data?.messages[0]);
          }
        });
    }
    // setshowFlutterWave(true);
  };

  const callPayU = async(referenceId:any, amount:any, orderId:any)=>{
    dispatch(globalLoaderEnd());
      const res = await payUService.createPayUId({"amount":parseInt(amount)*100, "currency":"KES"})
      console.log("response from payu", res)
      if(res.status === 201){
        const response = await payUService.openPayU(res?.data?.id, referenceId, orderId)
        console.log("response from changres",response)
        if(response?.status === 201){
          dispatch(globalLoaderEnd());
          setConnectingToPayu(true)
          setPayURoute(response?.data?.redirection?.url)
          window.location.replace(response?.data?.redirection?.url)
          window.history.pushState(null, "", window.location.href);
          window.onpopstate = function () {
              window.history.go(1);
          };
        }
      }
  }
  const onCancelOrder = () => {
    showAlert(2, "Payment failed. Please retry to pay again.");
    if (fromCheckout) navigate(`/user/my-order/detail/${orderId}`);
    onClose();
  };
  return (
    <>
      <Dialog open={openDialog} fullWidth maxWidth="xs" onClose={onClose}>
        {!connectingToPayU?<div className="payment-dialog popup-ui">
          <div className="popup-header">
            <h2 className="semi-bold center">Choose Payment Method</h2>
            <br />
            <h2 className="semi-bold center">{totalToPay} KES </h2>
          </div>
          <div className="popup-body">
            <ul>
              <li
                onClick={() => {
                  setCurrentPayType(payTypes.mpesa);
                }}
                className={`btn w-100 mb-20 ${
                  currentPayType === payTypes.mpesa ? "btn-primary" : ""
                }`}
              >
                Mpesa
              </li>
              <li
                onClick={() => {
                  setCurrentPayType(payTypes.creditDebitCard);
                }}
                className={`btn w-100 ${
                  currentPayType === payTypes.creditDebitCard
                    ? "btn-primary"
                    : ""
                }`}
              >
                Credit Card
              </li>
            </ul>
            <div className="btn-cover flex-center">
              <button className="btn btn-danger mr-20" onClick={onCancelOrder}>
                Cancel
              </button>
              <button className="btn btn-secondary" onClick={handlePay}>
                Pay
              </button>
            </div>
          </div>
        </div>:
        <div className="payment-dialog popup-ui">
          <div className="popup-header center"><h2  className="bold color-primary">Connecting to PayU....</h2></div>
          <div className="popup-body center color-primary"><Loader/></div>
          
        </div>
        }
      </Dialog>
      {showFlutterWave && currentApptId && configToSend !== null && (
        <FlutterWave
          config={configToSend}
          currentApptId={currentApptId}
          setshowFlutterWave={setshowFlutterWave}
          onCancel={onClose}
          orderId={orderId}
        />
      )}
    </>
  );
}

export default PaymentDialog;
