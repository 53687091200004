import GetAddressService from "../../services/GetAddressService";
import { commonAction, GET_ADDRESS } from "../../utility/actionConstant";
import { commonActionCreator } from "./commonActionCreator";

const getAddressService = new GetAddressService();

export function getAddress() {
  return async (dispatch: any) => {
    dispatch(
      commonActionCreator(GET_ADDRESS)(commonAction.IS_API_LOADING, true)
    );
    try {
      let response = await getAddressService.getAddress();
      dispatch(
        commonActionCreator(GET_ADDRESS)(commonAction.SET_API_DATA, response)
      );
    } catch (error) {
      dispatch(
        commonActionCreator(GET_ADDRESS)(commonAction.SET_API_ERROR, error)
      );
    }
  };
}
