import { Modal } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import React from "react";

type ModalWrapperProps = {
  children: React.ReactNode;
  open: boolean;
  handleClose: () => void;
} & BoxProps;

const StyledBox = styled(Box)<BoxProps>(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: "var(--button-hover-box-shadow)",
  backgroundColor: "var(--white)",
}));

function ModalWrapper({
  open,
  handleClose,
  children,
  ...otherProps
}: ModalWrapperProps) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StyledBox {...otherProps}>{children}</StyledBox>
    </Modal>
  );
}

export default ModalWrapper;
