import axios from "axios";
import { baseUrl } from "../../api/endpoints";
export default class SearchMedicine {
  getMedicineList = async (search = "", offset = "", limit = "") => {
    try {
      // const response = axios.get(
      //   "https://jsonplaceholder.typicode.com/comments"
      // );
      const response = axios.get(
        `${baseUrl}/chemist-user-medicine-list?search=${search}&offset=${offset}&limit=${limit}`,
        {
          headers: {
            "LiviaApp-country": "ke",
            "LiviaApp-city": "186301",
            "LiviaApp-language": "en",
            "LiviaApp-timezone": "330",
            "LiviaApp-APIVersion": "4.0",
            "Content-Type": "application/json",
            // "liviaApp-chemistUrl": process.env.REACT_APP_DOMAIN as any,
            "liviaApp-chemistUrl":
              process.env.NODE_ENV === "production"
                ? (window.location.host as any)
                : process.env.REACT_APP_DOMAIN,
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  getTopSellingMedicineList = async (search = "", offset = "", limit = "") => {
    try {
      // const response = axios.get(
      //   "https://jsonplaceholder.typicode.com/comments"
      // );
      const response = axios.get(
        `${baseUrl}/top-selling-drug?type=top-selling-drug&search=${search}&offset=${offset}&limit=${limit}`,
        {
          headers: {
            "LiviaApp-country": "ke",
            "LiviaApp-city": "186301",
            "LiviaApp-language": "en",
            "LiviaApp-timezone": "330",
            "LiviaApp-APIVersion": "4.0",
            "Content-Type": "application/json",
            // "liviaApp-chemistUrl": process.env.REACT_APP_DOMAIN as any,
            "liviaApp-chemistUrl":
              process.env.NODE_ENV === "production"
                ? (window.location.host as any)
                : process.env.REACT_APP_DOMAIN,
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };
}
