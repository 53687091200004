import { styled } from "@mui/material/styles";
import TableRow, { TableRowProps } from "@mui/material/TableRow";
import React from "react";

type TableRowWrapperProps = {
  children: React.ReactNode;
} & TableRowProps;

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {},
  "& td, & th": {
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
  },
}));

function TableRowWrapper({ children, ...otherProps }: TableRowWrapperProps) {
  return <StyledTableRow {...otherProps}>{children}</StyledTableRow>;
}

export default TableRowWrapper;
