import { Box, Grid, IconButton, Paper } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { getOrderStatusBgColor } from "../../utility/commonFuntion";
import { useEffect } from "react";

type OrderProgressProps = {
  status: string;
  id: string;
  orderOn: string;
  deliveredOn?: string;
  orderAction: string;
  status_id: string;
  handleActionClick: () => void;
  handleDetailClick: (id: string) => void;
};

function OrderProgress({
  status,
  id,
  orderOn,
  deliveredOn,
  orderAction,
  status_id,
  handleActionClick,
  handleDetailClick,
}: OrderProgressProps) {
  useEffect(() => {}, []);
  return (
    <div className="skeu-card" style={{ marginBottom: "20px" }}>
      <Grid container spacing={1}>
        <Grid item xs={10} sm={11} md={11}>
          <h1>
            Status:{" "}
            <span
              style={{
                color: getOrderStatusBgColor(status_id),
                fontWeight: "bold",
              }}
            >
              {status}
            </span>
          </h1>
          <h4 className="mt-10">Order ID : {id}</h4>
          <h4 className="mt-10">Ordered on : {orderOn}</h4>
          {deliveredOn && (
            <h4 className="mt-10">Delivered on : {deliveredOn}</h4>
          )}
          <div className="justify-start mt-20">
            <button
              className="btn btn-primary small"
              onClick={handleActionClick}
              style={{ display: "none" }}
            >
              {orderAction}
            </button>
            <button
              className="btn btn-primary mr-20"
              onClick={() => handleDetailClick(id)}
            >
              View Order Detail
            </button>
            {/* <button className="btn btn-primary">Retry Payment</button> */}
          </div>
        </Grid>
        <Grid item xs={2} sm={1} md={1}>
          <IconButton onClick={() => handleDetailClick(id)}>
            <ArrowForwardIosIcon color="primary" />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
}

export default OrderProgress;
