const ROUTES_NAME = {
  LOGIN: "login",
  SIGN_UP: "sign-up",
  USER: "user",
  PERSONAL_INFO: "personal-info",
  MY_ORDER: "my-order",
  PRESCRIPTION: "upload-prescription",
  DELIVERY: "select-delivery",
  MY_ORDER_DETAIL: "my-order/detail",
  SELECT_ADDRESS: "select-address",
  REVIEW_ORDER: "review-order",
  TERMS_N_CONDITION: "terms-and-condition",
};

export default ROUTES_NAME;
