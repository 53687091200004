import { ACTIVE_USER, GUEST_USER } from "../../utility/actionConstant";

const initialState = {
  showLogin: true,
  showProfile: true,
};
export const loginReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ACTIVE_USER:
      return {
        ...action.payload,
      };
    case GUEST_USER:
      return {
        ...action.payload,
      };
    default:
      return { ...state };
  }
};
