import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useLocation } from "react-router-dom";
import { IMedicine } from "../Landing/model/searchMedicineModel";
import SearchMedicine from "../../components/SearchMedicine";
import ProductCard from "../ProductCard/ProductCard";
import "./SearchResult.scss";

function SearchResult() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedSort, setSelectedSort] = useState("Relevance");
  const open = Boolean(anchorEl);
  const location: any = useLocation().state;
  const [isMounted, setIsMounted] = useState(false);
  const [medicineList, setMedicinieList] = useState<IMedicine[]>([]);
  const [unsortedList, setUnsortedList] = useState<IMedicine[]>([]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  function handleSort(sortType: string) {
    if (sortType === "H2L") {
      setMedicinieList(
        medicineList.sort(function (a, b) {
          return parseFloat(b.price) - parseFloat(a.price);
        })
      );
    }
    if (sortType === "L2H") {
      setMedicinieList(
        medicineList.sort(function (a, b) {
          return parseFloat(a.price) - parseFloat(b.price);
        })
      );
    }
    if (sortType === "Relevance") {
      setMedicinieList(unsortedList);
    }
    handleClose();
  }
  useEffect(() => {
    setIsMounted(true);
  }, [isMounted]);

  useEffect(() => {
    const listWithQuantity = location.medicineList.map((medicine: any) => {
      return { ...medicine, quantity: 0 };
    });
    setMedicinieList([...listWithQuantity]);
    const unsortedList = [...listWithQuantity];
    setUnsortedList(unsortedList);
  }, [location.medicineList]);

  return (
    <div className="search-page">
      <div className="flex-start mb-10 flex-wrap">
        <div className="search-cover">
          <SearchMedicine search={location.search} />
          <p className="mb-20 mt-10">
            Search results for <i>"{location.search}"</i>
          </p>
        </div>
        <button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          className="btn btn-secondary-border"
        >
          Sort By: {selectedSort} <ExpandMoreIcon className="ml-10" />
        </button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              handleSort("Relevance");
              setSelectedSort("Relevance");
            }}
          >
            Relevance
          </MenuItem>
          <MenuItem
            onClick={() => {
              setSelectedSort("Price - High to Low");
              handleSort("H2L");
            }}
          >
            Price - High to Low
          </MenuItem>
          <MenuItem
            onClick={() => {
              setSelectedSort("Price - Low to High");
              handleSort("L2H");
            }}
          >
            Price - Low to High
          </MenuItem>
        </Menu>
      </div>
      <Grid
        container
        rowSpacing={{ xs: 3, sm: 4, md: 6 }}
        columnSpacing={{ xs: 2, sm: 4, md: 6 }}
      >
        {medicineList.map((medicine) => (
          <Grid key={medicine.id} item xs={6} sm={4} md={3} lg={2.4}>
            <ProductCard medicine={medicine} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default SearchResult;
