import { AxiosResponse } from "axios";
import { axiosInstance } from "../../api/axiosInstance";
import { urlEndpoints } from "../../api/endpoints";
import {
  globalLoaderEnd,
  globalLoaderStart,
} from "../../redux/reducers/globalReducer";
import {
  GetUserOrderPayload,
  UserOrderAR,
  UserOrderDetailDTO,
} from "../../types";
import ACTION_NAME from "../../utility/actionConstant";
import showAlert from "../../utility/alert";
import { checkOnline, convertSignupPayload } from "../../utility/commonFuntion";
import { getLocalStorageKeyData } from "../../utility/util";

export const getUserProfile = () => {
  return async (dispatch: Function) => {
    checkOnline();
    dispatch(globalLoaderStart());
    try {
      const params = ``;
      const response = await axiosInstance.get(
        `${urlEndpoints.USER}${params}`,
        {
          headers: {
            "LiviaApp-city": getLocalStorageKeyData("user_city") || "",
          },
        }
      );
      dispatch(globalLoaderEnd());
      dispatch({
        type: ACTION_NAME.GET_PROFILE,
        payload: { ...response?.data },
      });
    } catch (err) {
      showAlert(2);
      dispatch(globalLoaderEnd());
    }
  };
};

export const userUpdate = (
  payload: ReturnType<typeof convertSignupPayload>
) => {
  return new Promise((resolve, reject) => {
    try {
      const params = ``;
      const response = axiosInstance.patch(
        `${urlEndpoints.USER}${params}`,
        payload
      );
      resolve(response);
    } catch (err) {
      reject(err);
    }
  });
};

// ?* user order api
export const getUserOrder = ({
  offset,
  limit,
  search,
  toDate,
  fromDate,
}: GetUserOrderPayload) => {
  return async (dispatch: Function) => {
    checkOnline();
    dispatch(globalLoaderStart());
    try {
      const params = `?offset=${offset}&limit=${limit}&search=${search}&from_date=${fromDate}&to_date=${toDate}`;
      const response: AxiosResponse<UserOrderAR> = await axiosInstance.get(
        `${urlEndpoints.ORDER}${params}`,
        {
          headers: {
            //  "liviaApp-chemistUrl": process.env.REACT_APP_DOMAIN || "",
            "liviaApp-chemistUrl":
              process.env.NODE_ENV === "production"
                ? (window.location.host as any)
                : process.env.REACT_APP_DOMAIN,
          },
        }
      );
      dispatch({
        type: ACTION_NAME.USER_ORDER,
        payload: { ...response.data },
      });
      dispatch(globalLoaderEnd());
    } catch (err) {
      showAlert(2);
      dispatch(globalLoaderEnd());
    }
  };
};

export const getUserOrderDetail = (orderId: string) => {
  return async (dispatch: Function) => {
    checkOnline();
    dispatch(globalLoaderStart());
    try {
      const params = `/${orderId}`;
      const response: AxiosResponse<UserOrderDetailDTO> =
        await axiosInstance.get(`${urlEndpoints.ORDER_DETAIL}${params}`, {
          headers: {
            "liviaApp-chemistUrl":
              process.env.NODE_ENV === "production"
                ? (window.location.host as any)
                : process.env.REACT_APP_DOMAIN,
          },
        });
      dispatch({
        type: ACTION_NAME.USER_ORDER_DETAIL,
        payload: { ...response.data },
      });
      dispatch(globalLoaderEnd());
    } catch (err) {
      showAlert(2);
      dispatch(globalLoaderEnd());
    }
  };
};
// /api/order/123?status_check=1
export const orderPaymentStatus = (orderId: string) => {
  return new Promise((resolve, reject) => {
    try {
      const params = `/${orderId}?status_check=1`;
      const response = axiosInstance.get(
        `${urlEndpoints.ORDER_DETAIL}${params}`
      );
      resolve(response);
    } catch (err) {
      reject(err);
    }
  });
};
