import { AxiosResponse } from "axios";
import { axiosInstance } from "../../../api/axiosInstance";
import { urlEndpoints } from "../../../api/endpoints";
import {
  checkOnline,
  convertSignupPayload,
} from "../../../utility/commonFuntion";

export const getCityList = () => {
  checkOnline();
  return new Promise((resolve, reject) => {
    try {
      const params = `?limit=100&offset=0&search=&country_code=ke`;
      const response = axiosInstance.get(`${urlEndpoints.CITY}${params}`);
      resolve(response);
    } catch (err) {
      reject(err);
    }
  });
};

export const signupUser = (
  payload: ReturnType<typeof convertSignupPayload>
) => {
  return new Promise<AxiosResponse<any>>((resolve, reject) => {
    try {
      const params = `/2`;
      const response = axiosInstance.patch(
        `${urlEndpoints.AUTH}${params}`,
        payload
      );
      resolve(response);
    } catch (err) {
      reject(err);
    }
  });
};
