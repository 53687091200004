import React from "react";
import Dialog from "@mui/material/Dialog";
import { DialogContent, DialogTitle } from "@mui/material";
import OrderService from "../../services/OrderService";
import showAlert from "../../utility/alert";
import { useDispatch } from "react-redux";
import {
  globalLoaderEnd,
  globalLoaderStart,
} from "../../redux/reducers/globalReducer";
const orderService = new OrderService();
function MoreContentDialog(props: any) {
  const { openDialog, onClose, pharmacyName } = props;
  const dispatch = useDispatch();
  const apiHandler = () => {
    dispatch(globalLoaderStart());
    orderService
      .ethicalSub()
      .then((res) => {
        dispatch(globalLoaderEnd());
        console.log("res?.data?.body", res?.data?.is_ethical_substitute);
        if (res?.data?.is_ethical_substitute) onClose();
      })
      .catch((err) => {
        showAlert(2);
        dispatch(globalLoaderEnd());
      });
  };

  return (
    <Dialog open={openDialog}>
      <DialogTitle>
        <h2>Ethical Substitution</h2>
      </DialogTitle>
      <DialogContent>
        <h4>
          Thank you for trusting {pharmacyName} with your health care needs. Our
          team of professionals ensures that your health is our number one
          priority. When designing a Drug Formulary, the team ensures that only
          quality brands with proven long term efficacy and affordable pricing
          structure get a placement in the formulary.
        </h4>
        &nbsp;
        <h4>
          {" "}
          At times, your doctor may prescribe you a brand whose price point is
          upto triple the price of competiting brands with the same active
          ingredient. Utilising costly brands depletes your benefits faster.
        </h4>
        &nbsp;
        <h4>
          In such cases, with your consent, we substitute the costly brand with
          a more cost effective one and which has the same active ingredient as
          the brand that the doctor prescribed. This is called Ethical
          Substitution.
        </h4>
        &nbsp;
        <h4>
          Ethical Substitution ensures that your benefits last you longer thus
          giving you peace of mind that your medical cover will still be
          available when you need it.
        </h4>
        &nbsp;
        <h4>
          Would you like to enrol into our Ethical Substitution Programme and
          safeguard your benefits?
        </h4>
        <div className="flex-center mt-30">
          <button className="btn btn-primary" onClick={onClose}>
            No, thank you
          </button>{" "}
          &nbsp;&nbsp;&nbsp;&nbsp;
          <button className="btn btn-primary" onClick={apiHandler}>
            Yes please
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default MoreContentDialog;
