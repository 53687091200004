import { commonAction, TOPSELLINGMEDICINE } from "../../utility/actionConstant";

const initialState = {
  apiData: null,
  isApiLoading: false,
  apiError: null,
};

export const TopSellingMedicineReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case `${TOPSELLINGMEDICINE}_${commonAction.IS_API_LOADING}`:
      return {
        ...state,
        isApiLoading: action.payload,
      };

    case `${TOPSELLINGMEDICINE}_${commonAction.SET_API_DATA}`:
      return {
        ...state,
        apiData: action.payload,
        isApiLoading: false,
      };

    case `${TOPSELLINGMEDICINE}_${commonAction.SET_API_ERROR}`:
      return {
        ...state,
        apiError: action.payload,
        isApiLoading: false,
      };

    default:
      return { ...state };
  }
};
