// import * as React from "react";
import React, { useEffect, useState } from "react";
import Link from "@mui/material/Link";
import pharmacyImg from "./../../assets/images/pharmacy.png";
import profileIcon from "./../../assets/images/profile-icon.png";
import cartIcon from "./../../assets/images/cart-icon.png";
import loginIcon from "./../../assets/images/login-icon.png";
import logoutIcon from "./../../assets/images/logout-icon.png";
import menuIcon from "./../../assets/images/menu-icon.png";
import Badge from "@mui/material/Badge";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import userImage from "./../../../src/assets/images/user_placeholder.svg";
import Divider from "@mui/material/Divider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "./Header.scss";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ProfileModel } from "../../modal";
import mobileImage from "./../../../src/pages/Header/icon-512x512.png";

import Geocode from "react-geocode";
import { useDispatch } from "react-redux";
import { showLogin } from "../../redux/actions/loginAction";
import {
  getGuestUser,
  getUserStatus,
  imageIcon,
  removeGuestUserSession,
  removeUserSession,
} from "../../utility/util";
import { getUserProfile } from "../Profile/action";
import { ReducersModal } from "../../modal";
import { API_URL } from "../../api/endpoints";
import ACTION_NAME from "../../utility/actionConstant";
import RegisterUser from "../../services/OnBoarding/MyProfileService";
const profileSer = new RegisterUser();

function Header() {
  const REACT_APP_GOOGLE_MAPS_API_KEY =
    "AIzaSyDbc6vdnB49ZMPq1RjxfsbMFrOLihSH5zw";

  Geocode.setApiKey(REACT_APP_GOOGLE_MAPS_API_KEY);
  const cart = useSelector((state: any) => state.cart.cart);
  const viewToggle = useSelector((state: any) => state.loginReducer);
  const [showHideIcon, setIcon] = useState({
    showLogin: true,
    showProfile: true,
  });
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [itemInCart, setItemInCart] = useState(0);
  const [selectedAddress, setSelectedAdd] = useState("");
  const [pharmacyLogo, setPharmacyLogo] = useState("");
  const [pharmacyName, setPharmacyName] = useState("");
  const open = Boolean(anchorEl);
  const { avatar, first_name } = useSelector(
    (state: ReducersModal) => state.profileReducer
  );
  const navigate = useNavigate();
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    navigator.geolocation.getCurrentPosition(success);
  };
  const success = (position: any) => {
    const currentPosition = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };
    if (currentPosition.lat && currentPosition.lng) {
      Geocode.fromLatLng(currentPosition.lat, currentPosition.lng).then(
        (response: any) => {
          const address = response.results[0].formatted_address;
          setSelectedAdd(address);
        },
        (error: any) => {
          console.error(error);
        }
      );
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    dispatch(showLogin());
    if (getUserStatus()) dispatch(getUserProfile()); //&& !getGuestUser()
  }, [getUserStatus(), getGuestUser()]);
  useEffect(() => {
    setIcon({ ...viewToggle });
  }, [viewToggle]);
  useEffect(() => {
    setItemInCart(
      cart.filter((itemInCart: any) => itemInCart.quantity !== 0).length
    );
  }, [cart]);
  const handleLogout = () => {
    removeUserSession();
    removeGuestUserSession();
    dispatch(showLogin());
    dispatch({
      type: ACTION_NAME.GET_PROFILE,
      payload: { ...new ProfileModel() },
    });
    navigate("/home");
  };

  useEffect(() => {
    profileSer.bannerDetail().then((res: any) => {
      if (res?.data?.body) {
        setPharmacyName(res?.data?.body?.chemist_details?.pharmacy_name);
        setPharmacyLogo(
          res?.data?.body?.chemist_details?.logo
            ? process.env.REACT_APP_API_URL +
                res?.data?.body?.chemist_details?.logo
            : ""
        );
        // var myDynamicManifest = {
        //   short_name: res?.data?.body?.chemist_details?.pharmacy_name,
        //   name: res?.data?.body?.chemist_details?.pharmacy_name,
        //   icons: [
        //     {
        //       src: imageIcon(res?.data?.body?.chemist_details?.logo_512),
        //       type: "image/png",
        //       sizes: "512x512",
        //     },
        //     {
        //       src: imageIcon(res?.data?.body?.chemist_details?.logo_192),
        //       type: "image/png",
        //       sizes: "192x192",
        //     },
        //     {
        //       src: imageIcon(res?.data?.body?.chemist_details?.logo_180),
        //       type: "image/png",
        //       sizes: "180x180",
        //     },
        //     {
        //       src: imageIcon(res?.data?.body?.chemist_details?.logo_120),
        //       type: "image/png",
        //       sizes: "120x120",
        //     },
        //   ],
        //   start_url: `${window.location.origin}/`,
        //   display: "standalone",
        //   theme_color: "#000000",
        //   background_color: "#ffffff",
        // };
        // let content = encodeURIComponent(JSON.stringify(myDynamicManifest));
        // let url = "data:application/manifest+json," + content;
        // let element = document.createElement("link");
        // element.setAttribute("rel", "manifest");
        // element.setAttribute("href", url);
        // document.querySelector("head")?.appendChild(element);
      }
    });
  }, []);
  return (
    <header className="space-between">
      <div className="justify-start h-100">
        <div
          className="justify-start logo-cover pointer h-100"
          onClick={() => navigate("/home")}
        >
          <img src={pharmacyLogo} />
          <h2 className="ml-10">{pharmacyName}</h2>
        </div>
        {/* <div className="justify-start ml-20">
          <div
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            className="justify-start pointer"
          >
            <AddLocationIcon className="mr-10 color-primary" />{" "}
            <h5>
              {selectedAddress.length ? selectedAddress : "Select Your Address"}
            </h5>
            <ExpandMoreIcon className="ml-10 color-secondary" />
          </div>
        </div> */}
      </div>
      <div className="justify-end">
        <ul>
          <li className="for-desktop">
            <Link
              className="semi-bold pointer"
              onClick={() => navigate("/home")}
            >
              Home
            </Link>
          </li>
          {!showHideIcon.showLogin && (
            <li className="for-desktop">
              <Link
                className="semi-bold pointer"
                onClick={() => navigate("/user/my-order")}
              >
                My Orders
              </Link>
            </li>
          )}
          {showHideIcon.showLogin && (
            <li className="for-desktop">
              <h4 className="semi-bold">Hi Guest!</h4>
            </li>
          )}
          {/* <li className="for-desktop">
            <Link className="semi-bold pointer" href="/">
              About Us
            </Link>
          </li>
          <li className="for-desktop">
            <Link className="semi-bold pointer" href="/">
              Contact
            </Link>
          </li> */}

          {showHideIcon.showLogin && (
            <li className="for-desktop">
              <img src={loginIcon} title="Login" className="mr-10" />
              <Link
                className="semi-bold pointer"
                onClick={() => navigate("/login")}
              >
                Login
              </Link>
            </li>
          )}
          <li
            onClick={() => {
              navigate("/cart");
            }}
          >
            <Badge
              badgeContent={itemInCart === 0 ? "0" : itemInCart}
              onClick={() => {
                navigate("/cart");
              }}
            >
              <img
                className="cart-icon"
                src={cartIcon}
                title="Go to cart"
                // onClick={() => {
                //   navigate("/cart");
                // }}
              />
            </Badge>
          </li>
          {!showHideIcon.showProfile && (
            <li
              className="for-mobile pointer"
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <img src={menuIcon} title="Open Menu" />
            </li>
          )}
        </ul>

        {showHideIcon.showProfile && (
          <div
            className="profile-img-cover ml-10 pointer"
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <img src={avatar ? API_URL + avatar : userImage} />
            &nbsp;&nbsp;
            <h3 className="for-desktop">
              {/* {first_name ? `Hi ${first_name}!` : ""} */}
              {first_name && first_name.length > 10
                ? `Hi ${first_name.substring(0, 10)}...`
                : first_name && first_name.length <= 10
                ? `Hi ${first_name}`
                : ""}
            </h3>
            <KeyboardArrowDownIcon />
          </div>
        )}
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {!showHideIcon.showLogin && (
            <div>
              <MenuItem onClick={() => navigate("/user/personal-info")}>
                <img
                  className="menu-profile-img"
                  src={avatar ? API_URL + avatar : profileIcon}
                  title="View Profile"
                  // style={{ borderRadius: "50%" }}
                />

                <h4 className="ml-15">Profile</h4>
              </MenuItem>
              <Divider />
              <MenuItem
                className="for-mobile-menu"
                onClick={() => navigate("/user/my-order")}
              >
                <h4 className="ml-5">My Orders</h4>
              </MenuItem>
              <Divider className="for-mobile-menu" />
            </div>
          )}

          <MenuItem
            className="for-mobile-menu"
            onClick={() => {
              navigate("/home");
            }}
          >
            <h4 className="ml-5">Home</h4>
          </MenuItem>
          {/* <MenuItem className="for-mobile-menu">
            <h4 className="ml-5">About Us</h4>
          </MenuItem>
          <MenuItem className="for-mobile-menu">
            <h4 className="ml-5">Contact</h4>
          </MenuItem> */}
          <Divider className="for-mobile-menu" />
          {/* <MenuItem
            onClick={() => {
              navigate("/cart");
            }}
          >
            <Badge badgeContent={itemInCart === 0 ? "0" : itemInCart}>
              <img src={cartIcon} title="Go to cart" />
            </Badge>
            <h4 className="ml-15">My cart</h4>
          </MenuItem> */}

          {showHideIcon.showLogin && (
            <MenuItem
              className="for-mobile"
              onClick={() => navigate("/login")}
              style={{ display: "flex !important" }}
            >
              <img src={loginIcon} title="Login" />
              <h4 className="ml-15">Login</h4>
            </MenuItem>
          )}
          {!showHideIcon.showLogin && (
            <MenuItem
              onClick={handleLogout}
              style={{ display: "flex !important" }}
            >
              <img src={logoutIcon} title="Login" />
              <h4 className="ml-15">Logout</h4>
            </MenuItem>
          )}
        </Menu>
      </div>
    </header>
  );
}

export default Header;

{
  /* <li>
            <img src={loginIcon} title="Login" className="mr-10" />
            <Link
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/login")}
            >
              Login
            </Link>
          </li>
          <li>
            <Badge badgeContent={itemInCart === 0 ? "0" : itemInCart}>
              <img
                src={cartIcon}
                title="Go to cart"
                onClick={() => {
                  navigate("/cart");
                }}
              />
            </Badge>
          </li> */
}
