import { PaymentService } from "../../services/PaymentService";

const paymentService = new PaymentService();
export function makePayment(payload: any) {
  return (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await paymentService.payment(payload);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };
}
