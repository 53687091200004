import { Box, FormControlLabel, FormHelperText, Grid, MenuItem, Radio, RadioGroup, Tooltip } from "@mui/material";
import { Formik, Form, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import FormLabelWrapper from "../../../components/formUI/FormLabelWrapper/FormLabelWrapper";
import InputFiled from "../../../components/formUI/InputFiled/InputFiled";

import { selectName, selectPhone, UserStatus } from "../../../utility/staticData";
import FORM_FIELD from "../../../validation/formFiled";
import SelectWrapper from "../../../components/formUI/Select/SelectWrapper";
import SCHEMA from "../../../validation/schema";
import { getCityList, signupUser } from "./action";
import AutoCompleteWrapper from "../../../components/formUI/autoCompleteWrapper/AutoCompleteWrapper";
import { convertSignupPayload, subtractDate } from "../../../utility/commonFuntion";
import { ClearLocalStorage, getLocalStorageKeyData, setUserStatus } from "../../../utility/util";
import { useNavigate } from "react-router-dom";
import showAlert from "../../../utility/alert";
import { globalLoaderEnd, globalLoaderStart } from "../../../redux/reducers/globalReducer";
import { useDispatch } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import { showLogin } from "../../../redux/actions/loginAction";
import { getUserProfile } from "../../Profile/action";
import { useSelector } from "react-redux";
import { ReducersModal } from "../../../modal";
import moment from "moment";

function FormCard() {
  const [initialValues, setInputValues] = useState(FORM_FIELD.SIGN_UP);
  const [cityOptions, setCityOptions] = useState<any[]>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { avatar, name_prefix, first_name, last_name, sex, city_id, date_birth, email, phone_number, user_status } =
    useSelector((state: ReducersModal) => state.profileReducer);

  const phoneNumber = getLocalStorageKeyData("user_phoneNo");
  const phoneCode = getLocalStorageKeyData("user_phoneCode");
  const userStatus = getLocalStorageKeyData("user_status");

  useEffect(() => {
    if (phoneNumber && phoneCode && UserStatus.IN_REGISTRATION_PROCESS === userStatus)
      setInputValues({ ...initialValues, phoneCode, phoneNumber });
    else navigate("/");
  }, [phoneNumber, phoneCode, userStatus]);

  useEffect(() => {
    getCityList().then((res: any) => {
      setCityOptions(res?.data?.body);
    });
    dispatch(getUserProfile());
  }, []);
  useEffect(() => {
    if (email) {
      setInputValues({
        phoneCode: "254",
        phoneNumber: phoneNumber as string,
        title: name_prefix,
        firstName: first_name,
        lastName: last_name,
        gender: sex,
        city: cityOptions?.find((city) => city?.id === city_id),
        dateOfBirth: moment(date_birth).format("YYYY-MM-DD"),
        email: email,
      });
    }
  }, [email]);

  const handleSubmit = (
    value: typeof FORM_FIELD.SIGN_UP,
    { setSubmitting }: FormikHelpers<typeof FORM_FIELD.SIGN_UP>
  ) => {
    const payload = convertSignupPayload({
      ...value,
      avatar: null,
      user_status,
    });
    dispatch(globalLoaderStart());
    signupUser(payload)
      .then((response: any) => {
        if (response?.status === 200) {
          setUserStatus(response?.data?.user_status);
          navigate("/home");
          dispatch(showLogin());
        } else {
          showAlert(2);
        }
        dispatch(globalLoaderEnd());
        setSubmitting(false);
      })
      .catch((error: any) => {
        showAlert(2, error?.response?.data?.messages[0]);
        dispatch(globalLoaderEnd());
        setSubmitting(false);
      });
  };

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={SCHEMA.SIGN_UP}
        enableReinitialize
      >
        {({ values, setFieldValue, errors, isSubmitting }) => (
          <Form>
            <Grid container spacing={{ sm: 2, xs: 1 }}>
              <Grid item xs={12} sm={12}>
                <span className="justify-start">
                  <FormLabelWrapper required>Phone Number</FormLabelWrapper>
                  <Tooltip
                    title="We will use your phone number to send you alerts when your medicines are ready"
                    placement="top-start"
                    enterTouchDelay={0}
                    leaveTouchDelay={7000}
                  >
                    <InfoIcon color="primary" className="info-icon" />
                  </Tooltip>
                </span>
                <div className="space-between">
                  <SelectWrapper name="phoneCode" size="small" readOnly style={{ width: "80px" }}>
                    {selectPhone.map(({ key, value }) => {
                      return (
                        <MenuItem value={value} key={key}>
                          {key}
                        </MenuItem>
                      );
                    })}
                  </SelectWrapper>
                  <InputFiled
                    style={{ width: "calc(100% - 90px)" }}
                    readOnly
                    fullWidth
                    placeholder="Phone Number"
                    name="phoneNumber"
                    size="small"
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormLabelWrapper required>Name</FormLabelWrapper>
                <div className="space-between">
                  <SelectWrapper name="title" size="small" style={{ width: "70px" }}>
                    {selectName.map(({ key, value }) => {
                      return (
                        <MenuItem value={value} key={key}>
                          {key}
                        </MenuItem>
                      );
                    })}
                  </SelectWrapper>
                  <InputFiled
                    fullWidth
                    placeholder="First Name"
                    name="firstName"
                    size="small"
                    style={{ width: "calc(100% - 80px)" }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormLabelWrapper>&nbsp;</FormLabelWrapper>
                <InputFiled fullWidth placeholder="Last Name" name="lastName" size="small" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormLabelWrapper required>Gender</FormLabelWrapper>
                <RadioGroup row value={values.gender} onChange={(e) => setFieldValue("gender", e.target.value)}>
                  <FormControlLabel value="Male" control={<Radio />} label="Male" />
                  <FormControlLabel value="Female" control={<Radio />} label="Female" />
                </RadioGroup>
                <FormHelperText error>{errors.gender}</FormHelperText>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormLabelWrapper required>City</FormLabelWrapper>
                <AutoCompleteWrapper name="city" options={cityOptions} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="justify-start">
                  <FormLabelWrapper required>Date of Birth</FormLabelWrapper>
                  <Tooltip
                    title="We use the DOB to calculate your age which helps the pharmacies to give you the correct doses of the medicines"
                    placement="top-start"
                    enterTouchDelay={0}
                    leaveTouchDelay={7000}
                  >
                    <InfoIcon color="primary" className="info-icon" />
                  </Tooltip>
                </div>
                <InputFiled
                  type="date"
                  fullWidth
                  placeholder="Date of birth"
                  name="dateOfBirth"
                  size="small"
                  minDate={subtractDate(18)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="justify-start">
                  <FormLabelWrapper required>Email</FormLabelWrapper>
                  <Tooltip
                    title="We will use your email as well to send you alerts when your medicines are ready"
                    placement="top-start"
                    enterTouchDelay={0}
                    leaveTouchDelay={7000}
                  >
                    <InfoIcon color="primary" className="info-icon" />
                  </Tooltip>
                </div>
                <InputFiled type="email" fullWidth placeholder="Email" name="email" size="small" />
              </Grid>
            </Grid>
            <h4 className="mt-20 regular">
              By signing up, you agree to Livia{" "}
              <a
                className="bold"
                href="/?p=1#/terms-and-condition"
                target="_blank"
              >
                Terms and Conditions
              </a>{" "}
              and that you are over 18 years of age.
            </h4>

            <div className="space-between">
              <button
                className="btn btn-secondary w-100 mb-30 mt-30 mr-20"
                disabled={isSubmitting}
                onClick={() => {
                  ClearLocalStorage();
                  navigate("/login");
                }}
              >
                Back
              </button>
              <button className="btn btn-primary w-100 mb-30 mt-30" type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Loading..." : "Sign Up"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default FormCard;
