import {
  ADD_PRESCRIPTION,
  DELETE_PRESCRIPTION_LIST,
  REMOVE_PRESCRIPTION,
} from "../../utility/actionConstant";

let initialState = {
  prescriptionList: [],
};

if (localStorage.getItem("prescription")) {
  initialState.prescriptionList = JSON.parse(
    localStorage.getItem("prescription") as string
  );
}

const prescriptionReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_PRESCRIPTION:
      return {
        prescriptionList: [...action.payload],
      };
    case REMOVE_PRESCRIPTION:
      return {
        prescriptionList: [...action.payload],
      };
    case DELETE_PRESCRIPTION_LIST:
      return {
        prescriptionList: [],
      };
    //   case DELETE_FROM_CART:
    //     return {
    //       cart: [...action.payload],
    //     };
    default:
      return state;
  }
};
export default prescriptionReducer;
