import ACTION_NAME from "../../utility/actionConstant";
import {
  ProfileModel,
  UserOrderModal,
  UserOrderDetailModal,
} from "../../modal";

export const profileReducer = (
  state: ProfileModel = new ProfileModel(),
  action: any
) => {
  switch (action.type) {
    case ACTION_NAME.GET_PROFILE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const userOrderReducer = (
  state: UserOrderModal = new UserOrderModal(),
  action: any
) => {
  switch (action.type) {
    case ACTION_NAME.USER_ORDER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const userOrderDetailReducer = (
  state: UserOrderDetailModal = new UserOrderDetailModal(),
  action: any
) => {
  switch (action.type) {
    case ACTION_NAME.USER_ORDER_DETAIL:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
