import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import checkedImg from "../../assets/images/checked.png";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteCart } from "../../redux/actions/cartActions";
import { getUserOrderDetail } from "../../pages/Profile/action";

function PaymentSuccessDialog(props: any) {
  const { open, orderId } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onClose = (reason: any) => {
    if (reason && reason === "backdropClick") return;
    else {
      console.log("View details");
      dispatch(deleteCart());
      dispatch(getUserOrderDetail(orderId));
      navigate(`/user/my-order/detail/${orderId}`);
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle></DialogTitle>
      <DialogContent>
        <h2 className="bold center">Payment Successful</h2>
        <h3 className="bold center">Awesome, your order has been confirmed</h3>
        <img
          src={checkedImg}
          className="mt-10"
          style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            width: "15%",
          }}
        />
        <button
          onClick={onClose}
          className="btn btn-primary mt-20"
          style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          View Order Detail
        </button>
      </DialogContent>
    </Dialog>
  );
}

export default PaymentSuccessDialog;
