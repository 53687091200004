import { commonAction, COUNTRY } from "../../utility/actionConstant";

const initialState = {
  apiData: null,
  isApiLoading: false,
  apiError: null,
};

export const CountryDetail = (state = initialState, action: any) => {
  switch (action.type) {
    case `${COUNTRY}_${commonAction.IS_API_LOADING}`:
      return {
        ...state,
        isApiLoading: action.payload,
      };

    case `${COUNTRY}_${commonAction.SET_API_DATA}`:
      return {
        ...state,
        apiData: action.payload,
        isApiLoading: false,
      };

    case `${COUNTRY}_${commonAction.SET_API_ERROR}`:
      return {
        ...state,
        apiError: action.payload,
        isApiLoading: false,
      };

    default:
      return { ...state };
  }
};
