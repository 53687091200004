import React, { useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ReactFileReader from "react-file-reader";
import prescriptionGuideImg from "./../../assets/images/guidelines.png";
import prescriptionGuideMobileImg from "./../../assets/images/guidelines.png";
import "./UploadPrescription.scss";
import PreviewImage from "../../components/PreviewImage/PreviewImage";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addPrescription, removePrescription } from "../../redux/actions/prescriptionAction";
import showAlert from "../../utility/alert";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function UploadPrescription() {
  const [previewImage, setPreviewImg] = useState();
  const [openPreview, setOpenPreview] = useState(false);
  const { prescriptionList } = useSelector((state: any) => state.prescriptionReducer);
  const [list, setList] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    setList(prescriptionList);
  });
  const handleClosePreview = () => {
    setOpenPreview(false);
  };
  const handleFileReader = (files: any) => {
    dispatch(addPrescription(files));

    if (JSON.parse(localStorage.getItem("prescription") as string).length === 1) {
      var now = new Date().getTime();
      localStorage.setItem("prescriptionAddTime", JSON.stringify(now));
    }
  };
  const handleContinue = () => {
    if (list.length === 0) {
      showAlert(2, "Please upload a prescription");
      return;
    }
    navigate("/select-delivery");
  };

  return (
    <>
      <div className="page-upload-prescription">
        <div className="upload-prescription-content">
          <div className="left-portion skeu-card">
            <div className="space-between flex-wrap">
              <h2 className="mb-10 semi-bold justify-start">
                <ArrowBackIcon
                  className="pointer mr-10 for-desktop"
                  titleAccess="Go Back"
                  onClick={() => navigate(-1)}
                />{" "}
                Upload New Prescription
              </h2>
              {list.length < 5 ? (
                <>
                  <div className="upload-for-desktop">
                    <ReactFileReader
                      handleFiles={handleFileReader}
                      base64={true}
                      multipleFiles={false}
                      fileTypes={[".jpg", ".jpeg", ".png", ".gif"]}
                      disabled={false}
                    >
                      <h3 className="mb-10 color-primary bold justify-end pointer">
                        <UploadFileIcon /> Upload Here
                      </h3>
                    </ReactFileReader>
                  </div>
                  <div className={`upload-for-mobile pointer ${list.length > 0 ? "continue-enable" : ""} `}>
                    <ReactFileReader
                      handleFiles={handleFileReader}
                      base64={true}
                      multipleFiles={false}
                      fileTypes={[".jpg", ".jpeg", ".png", ".gif"]}
                      disabled={false}
                    >
                      <h1 className="bold justify-start">
                        <UploadFileIcon /> &nbsp;&nbsp; Upload Here
                      </h1>
                    </ReactFileReader>
                    <button className="btn btn-primary" onClick={handleContinue}>
                      Continue
                    </button>
                  </div>
                </>
              ) : (
                <div className="upload-for-mobile pointer">
                  <h1 className="bold" style={{ margin: "auto" }} onClick={handleContinue}>
                    Continue
                  </h1>
                </div>
              )}
            </div>
            <hr className="mb-20" />
            <h3 className="mb-10 semi-bold">Attached Prescriptions</h3>
            <p className="mb-30">You can upload a maximum of 5 prescriptions</p>

            <ul className="uploaded-prescription">
              {list.map((prescription: any, index: number) => (
                <li key={index}>
                  <div className="single-prescription">
                    <ClearIcon
                      className="close-icon"
                      onClick={() => {
                        dispatch(removePrescription(index));
                      }}
                      // title="Remove"
                      titleAccess="Remove"
                    />
                    <img
                      src={prescription.base64}
                      alt="Prescription"
                      onClick={() => {
                        setPreviewImg(prescription.base64);
                        setOpenPreview(true);
                      }}
                    />
                  </div>
                </li>
              ))}
            </ul>

            {list.length > 0 && (
              <button
                className="btn btn-primary upload-for-desktop"
                style={{ margin: "30px auto auto" }}
                onClick={handleContinue}
              >
                Continue
              </button>
            )}
          </div>
          <div className="right-portion skeu-card">
            <h2 className="mb-10 semi-bold justify-start">
              <ArrowBackIcon className="pointer mr-10 for-mobile" titleAccess="Go Back" onClick={() => navigate(-1)} />{" "}
              Guide for a valid prescription
            </h2>
            <hr className="mt-10 mb-20" />
            <img className="guide-img for-web" src={prescriptionGuideImg} alt="Prescription" />
            <img className="guide-img for-mobile" src={prescriptionGuideMobileImg} alt="Prescription" />
            <br />
            <hr className=" mb-10" />
            <h3 className="semi-bold mb-10">Why Upload Prescription?</h3>
            <ul className="upload-terms">
              <li>
                <p>Never lose the digital copy of your prescription. It will be with wherever you go.</p>
              </li>
              <li>
                <p>Is your prescription hard to understand? Pharmacist will help you in placing order.</p>
              </li>
              <li>
                <p>Details from your prescription are not shared with any third party.</p>
              </li>
              <li>
                <p>Government regulation require a prescription for ordering some medicine.</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <PreviewImage open={openPreview} handleClose={handleClosePreview} image={previewImage as any} />
    </>
  );
}

export default UploadPrescription;
