import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { DialogContent, DialogTitle } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getUserOrderDetail } from "../../pages/Profile/action";
import { getOrderStatusBgColor } from "../../utility/commonFuntion";

function NotificationDialog(props: any) {
  const { openDialog, closeHandler } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notification = useSelector(
    (state: any) => state.notificationReducer?.notification
  );
  const [notificationMessage, setNotificationMessage] = useState<any>({});

  useEffect(() => {
    console.log("notification", notification);

    setNotificationMessage(notification);
  }, [notification]);
  const viewNotification = () => {
    dispatch(getUserOrderDetail(notificationMessage?.order_id));
    closeHandler();
    navigate(`/user/my-order/detail/${notificationMessage?.order_id}`);
  };
  const onClose = () => {
    dispatch(getUserOrderDetail(notificationMessage?.order_id));
    closeHandler();
  };
  const notificationText = (status: any) => {
    if (status === 3) return "Quotation Received for your order";
    else if (status === 4) return "Your order is being prepared";
    else if (status === 6) return "Your have cancelled the order";
    else if (status === 7) return "Order Delivered";
    else if (status === 12) return "Your order is ready to be collected";
    return "";
  };
  return (
    <Dialog open={openDialog} onClose={closeHandler} fullWidth maxWidth="xs">
      {/* <DialogTitle> */}
      <div
        style={{ backgroundColor: "#308e99", color: "white", height: "auto" }}
      >
        <h2 className="bold center mt-10 mb-10">
          Order ID-{notificationMessage?.order_id}
        </h2>
      </div>

      {/* </DialogTitle> */}
      <DialogContent>
        <h2
          className="bold center"
          style={{
            color: getOrderStatusBgColor(notificationMessage?.status),
          }}
        >
          {notificationText(notificationMessage?.status)}
        </h2>
        <div className="flex-center mt-30">
          <button className="btn btn-primary mr-20" onClick={viewNotification}>
            View Detail
          </button>
          <button className="btn btn-danger " onClick={onClose}>
            Close
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default NotificationDialog;
