import SearchMedicine from "../../services/searchhMedicine/searchMedicine";
import {
  commonAction,
  SEARCHMEDICINE,
  TOPSELLINGMEDICINE,
} from "../../utility/actionConstant";
import { globalLoaderEnd, globalLoaderStart } from "../reducers/globalReducer";
import { commonActionCreator } from "./commonActionCreator";
const searchMedicineService = new SearchMedicine();
export function searchMedicineListAction(
  search: any,
  offset: any = "",
  limit: any = ""
) {
  return async (dispatch: any) => {
    dispatch(
      commonActionCreator(SEARCHMEDICINE)(commonAction.IS_API_LOADING, true)
    );
    // return new Promise(async (resolve, reject) => {
    try {
      const response = await searchMedicineService.getMedicineList(
        search,
        offset,
        limit
      );
      dispatch(
        commonActionCreator(SEARCHMEDICINE)(commonAction.SET_API_DATA, response)
      );
    } catch (error) {
      dispatch(
        commonActionCreator(SEARCHMEDICINE)(commonAction.SET_API_ERROR, error)
      );
    }
    // });
  };
}

export function topSellingMedicineListAction(
  search: any,
  offset: any = "",
  limit: any = ""
) {
  return async (dispatch: any) => {
    dispatch(
      commonActionCreator(TOPSELLINGMEDICINE)(commonAction.IS_API_LOADING, true)
    );
    dispatch(globalLoaderStart());
    // return new Promise(async (resolve, reject) => {
    try {
      const response = await searchMedicineService.getTopSellingMedicineList(
        search,
        offset,
        limit
      );
      dispatch(globalLoaderEnd());
      dispatch(
        commonActionCreator(TOPSELLINGMEDICINE)(
          commonAction.SET_API_DATA,
          response
        )
      );
    } catch (error) {
      dispatch(globalLoaderEnd());
      dispatch(
        commonActionCreator(TOPSELLINGMEDICINE)(
          commonAction.SET_API_ERROR,
          error
        )
      );
    }
    // });
  };
}
