import CountryService from "../../services/country/countryService";
import { commonAction, COUNTRY } from "../../utility/actionConstant";
import { commonActionCreator } from "./commonActionCreator";

const countryService = new CountryService();

export function getCountriesDetail() {
  return async (dispatch: any) => {
    dispatch(commonActionCreator(COUNTRY)(commonAction.IS_API_LOADING, true));

    try {
      let response = await countryService.getCountryDetails();
      // 	response = {
      // 		...response,
      // 		list_of_countries: response?.list_of_countries?.reduce(
      // 			(acc, val, index, currentArray) => {
      // 				if (val?.phone_country_code === "254") {
      // 					acc.unshift(val);
      // 				} else {
      // 					acc.push(val);
      // 				}
      // 				return acc;
      // 			},
      // 			[]
      // 		),
      // 	};
      dispatch(
        commonActionCreator(COUNTRY)(commonAction.SET_API_DATA, response)
      );
    } catch (error) {
      dispatch(commonActionCreator(COUNTRY)(commonAction.SET_API_ERROR, error));
    }
  };
}
