import {
  ADD_TO_CART,
  DELETE_CART,
  DELETE_FROM_CART,
  REMOVE_FROM_CART,
} from "../../utility/actionConstant";

export interface ICartInStorage {
  id?: string;
  quantity?: number;
}

export const addToCart = (product: any) => async (dispatch: any) => {
  const cart = localStorage.getItem("cart")
    ? JSON.parse(localStorage.getItem("cart") as string)
    : [];

  const index = cart.findIndex((med: any) => med.id === product.id);
  if (index !== -1) {
    cart[index].quantity = cart[index].quantity + 1;
  } else {
    const productToAdd = {
      ...product,
      quantity: 1,
    };
    cart.push(productToAdd);
  }
  localStorage.setItem("cart", JSON.stringify(cart));
  dispatch({ type: ADD_TO_CART, payload: cart });
};

export const removeFromCart = (product: any) => async (dispatch: any) => {
  let cart = localStorage.getItem("cart")
    ? JSON.parse(localStorage.getItem("cart") as string)
    : [];

  const index = cart.findIndex((med: any) => med.id === product.id);
  if (index !== -1 && cart[index].quantity > 0) {
    cart[index].quantity = cart[index].quantity - 1;
  } else if (index !== -1 && cart[index].quantity === 0) {
    cart.splice(index, 1);
  }
  localStorage.setItem("cart", JSON.stringify(cart));
  dispatch({ type: REMOVE_FROM_CART, payload: cart });
};

export const deleteFromCart = (product: any) => async (dispatch: any) => {
  let cart = localStorage.getItem("cart")
    ? JSON.parse(localStorage.getItem("cart") as string)
    : [];
  const index = cart.findIndex((medicine: any) => medicine.id === product.id);
  if (index !== -1) {
    cart.splice(index, 1);
  }

  localStorage.setItem("cart", JSON.stringify(cart));
  dispatch({ type: DELETE_FROM_CART, payload: cart });
};

export const deleteCart = () => (dispatch: any) => {
  localStorage.setItem("cart", JSON.stringify([]));
  dispatch({ type: DELETE_CART, payload: [] });
};
