import { ThemeProvider } from "@mui/material";
import { useEffect } from "react";
import { Provider } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import "./App.scss";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import ToasterContainer from "./components/toasterContainer/ToasterContainer";
import store from "./redux/store";
import Routing from "./routing/Routing";
import RegisterUser from "./services/OnBoarding/MyProfileService";
import theme from "./theme";
import { getFaviconElement, getFaviconUrl } from "./utility/util";
const profileSer = new RegisterUser();

function App() {
  const navigate = useNavigate();
  const loc = useLocation();
  var hours = 360; //15days period to remove added prescription
  var now = new Date().getTime();
  var setupTime = localStorage.getItem("prescriptionAddTime") as string;

  if (now - JSON.parse(setupTime) > hours * 60 * 60 * 1000) {
    localStorage.removeItem("prescription");
    localStorage.removeItem("prescriptionAddTime");
  }
  useEffect(() => {
    profileSer.bannerDetail().then((res) => {
      const favicon = getFaviconElement() as any;
      if (favicon) {
        if (res?.data?.body?.chemist_details?.logo_512) {
          favicon.href = getFaviconUrl(
            res?.data?.body?.chemist_details?.logo_512
          );
          window.document.title =
            res?.data?.body?.chemist_details?.pharmacy_name;
        }
      }
    });
    console.log("location", loc, window.location);
    if (loc.search === "?p=1" || window.location.href.includes("?p=1")) {
      // window.location.replace("https://" + window.location.host + loc.pathname);
      // navigate(loc.pathname, { replace: true });
    }
  }, []);
  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <ToasterContainer />
          <Routing />
        </Provider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;
