import { Avatar, Box, FormControlLabel, FormHelperText, Grid, MenuItem, Radio, RadioGroup } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import FORM_FIELD from "../../validation/formFiled";
import SCHEMA from "../../validation/schema";
import { useEffect, useRef, useState } from "react";
import FormLabelWrapper from "../../components/formUI/FormLabelWrapper/FormLabelWrapper";
import SelectWrapper from "../../components/formUI/Select/SelectWrapper";
import InputFiled from "../../components/formUI/InputFiled/InputFiled";
import { selectName, selectPhone } from "../../utility/staticData";
import { convertDate, convertSignupPayload, subtractDate, uploadDocumentImage } from "../../utility/commonFuntion";
import PersonIcon from "@mui/icons-material/Person";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import AutoCompleteWrapper from "../../components/formUI/autoCompleteWrapper/AutoCompleteWrapper";
import { getCityList } from "../Onboarding/Signup/action";
import { useSelector, useDispatch } from "react-redux";
import { ReducersModal } from "../../modal";
import { getUserProfile, userUpdate } from "./action";
import showAlert from "../../utility/alert";
import ReactFileReader from "react-file-reader";
import ImageCrop from "../../components/ImageCroper/ImageCroper";
import { globalLoaderEnd, globalLoaderStart } from "../../redux/reducers/globalReducer";
import ACTION_NAME from "../../utility/actionConstant";
import { API_URL } from "../../api/endpoints";
import IconButtonWrapper from "../../components/button/IconButtonWrapper";
import PreviewImage from "../../components/PreviewImage/PreviewImage";

function ProfileInfo() {
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState(FORM_FIELD.MY_PROFILE);
  const [cityOptions, setCityOptions] = useState<any[]>([]);
  const [imgCropSrc, setImgCropSrc] = useState<any>();
  const [openImgCrop, setOpenImgCrop] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [previewImgUrl, setPreviewImgUrl] = useState("");
  const scrollRef = useRef<any>(null);

  const {
    phone_code,
    phone_number,
    name_prefix,
    first_name,
    last_name,
    sex,
    city_id,
    city_name,
    date_of_the_birth,
    email,
    avatar,
    user_status,
  } = useSelector((state: ReducersModal) => state.profileReducer);

  const executeScroll = () => scrollRef.current.scrollIntoView();
  useEffect(() => {
    executeScroll();
  }, []);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  useEffect(() => {
    getCityList().then((res: any) => {
      setCityOptions(res?.data?.body);
    });
  }, []);

  useEffect(() => {
    if (phone_code || phone_number || email)
      setInitialValues({
        phoneCode: phone_code,
        phoneNumber: phone_number,
        title: name_prefix,
        firstName: first_name,
        lastName: last_name,
        gender: sex,
        // @ts-ignore
        city: { id: city_id, name: city_name },
        dateOfBirth: convertDate(date_of_the_birth),
        email: email,
      });
  }, [city_id, city_name, date_of_the_birth, email, first_name, last_name, name_prefix, phone_code, phone_number, sex]);

  const handleSubmit = (
    value: typeof FORM_FIELD.MY_PROFILE,
    { setSubmitting }: FormikHelpers<typeof FORM_FIELD.MY_PROFILE>
  ) => {
    const payload = convertSignupPayload({ ...value, avatar, user_status });
    userUpdate(payload)
      .then((_response) => {
        dispatch(getUserProfile());
        showAlert(1, "User updated successfully");
        setSubmitting(false);
      })
      .catch((_err) => {
        showAlert(2);
        setSubmitting(false);
      });
  };

  const handleFileReader = (files: any) => {
    setImgCropSrc(files);
    handleOpenImgCrop();
  };

  const handleOpenImgCrop = () => {
    setOpenImgCrop(true);
  };
  const handleCloseImgCrop = () => {
    setOpenImgCrop(false);
  };

  const handleOpenPreview = () => {
    setOpenPreview(true);
  };
  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  const handleClickOpenPreview = () => {
    if (!avatar) return;
    setPreviewImgUrl(API_URL + avatar);
    handleOpenPreview();
  };

  const addCropperProfilePicture = (files: any) => {
    return new Promise((resolve, reject) => {
      dispatch(globalLoaderStart());
      uploadDocumentImage(files.split(",")[1])
        .then((res: any) => {
          if (res?.status === 200) {
            dispatch({
              type: ACTION_NAME.GET_PROFILE,
              payload: { avatar: res?.data?.image },
            });
            dispatch(globalLoaderEnd());
            resolve("upload profile picture");
          }
        })
        .catch((err: any) => {
          dispatch(globalLoaderEnd());
          reject(err?.response?.data?.messages && err?.response?.data?.messages[0]);
        });
    });
  };

  const handleRemoveProfilePic = () => {
    if (!avatar) return;
    dispatch({
      type: ACTION_NAME.GET_PROFILE,
      payload: { avatar: null },
    });
  };

  return (
    <div ref={scrollRef}>
      <div className="skeu-card">
      <h3 className="semi-bold">Personal Info</h3>
      <hr className="mt-15 mb-10" />
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={SCHEMA.MY_PROFILE}
          enableReinitialize
        >
          {({ values, setFieldValue, errors, isSubmitting }) => (
            <Form>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={2.2}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      mb: 3,
                    }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                      }}
                    >
                      <Avatar
                        sx={{
                          width: 100,
                          height: 100,
                          mt: 2,
                          border: (theme) => `5px solid ${theme.palette.primary.main}`,
                          bgcolor: (theme) => theme.palette.common.white,
                          cursor: avatar ? "pointer" : "default",
                        }}
                        src={avatar ? API_URL + avatar : ""}
                        onClick={handleClickOpenPreview}
                      >
                        {!avatar && <PersonIcon color="primary" sx={{ width: 75, height: 75 }} />}
                      </Avatar>
                      {avatar ? (
                        <IconButtonWrapper onClick={handleRemoveProfilePic}>
                          <ClearIcon color="primary" />
                        </IconButtonWrapper>
                      ) : (
                        <ReactFileReader
                          handleFiles={handleFileReader}
                          base64={true}
                          multipleFiles={false}
                          fileTypes={[".jpg", ".jpeg", ".png", ".gif"]}
                          disabled={false}
                        >
                          <IconButtonWrapper>
                            <EditIcon color="primary" />
                          </IconButtonWrapper>
                        </ReactFileReader>
                      )}
                      <ImageCrop
                        src={imgCropSrc}
                        open={openImgCrop}
                        handleClose={handleCloseImgCrop}
                        uploadImage={addCropperProfilePicture}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={9.8}>
                  <Grid container spacing={{ sm: 2, xs: 1 }}>
                    <Grid item xs={12} sm={6}>
                      <FormLabelWrapper required>Phone Number</FormLabelWrapper>
                      <div className="space-between">
                        <SelectWrapper name="phoneCode" size="small" readOnly style={{ width: "80px" }}>
                          {selectPhone.map(({ key, value }) => {
                            return (
                              <MenuItem value={value} key={key}>
                                {key}
                              </MenuItem>
                            );
                          })}
                        </SelectWrapper>
                        <InputFiled
                          style={{
                            width: "calc(100% - 90px)",
                            marginLeft: "10px",
                          }}
                          readOnly
                          fullWidth
                          placeholder="Phone Number"
                          name="phoneNumber"
                          size="small"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormLabelWrapper required>Email</FormLabelWrapper>
                      <InputFiled type="email" fullWidth placeholder="Email" name="email" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className="space-between">
                        <div style={{ width: "80px" }}>
                          <FormLabelWrapper required>Title</FormLabelWrapper>
                          <SelectWrapper name="title" size="small">
                            {selectName.map(({ key, value }) => {
                              return (
                                <MenuItem value={value} key={key}>
                                  {key}
                                </MenuItem>
                              );
                            })}
                          </SelectWrapper>
                        </div>
                        <div
                          style={{
                            width: "calc(100% - 90px)",
                          }}
                        >
                          <FormLabelWrapper required>First Name</FormLabelWrapper>
                          <InputFiled fullWidth placeholder="First Name" name="firstName" size="small" />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormLabelWrapper required>Last Name</FormLabelWrapper>
                      <InputFiled fullWidth placeholder="Last Name" name="lastName" size="small" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormLabelWrapper required>City</FormLabelWrapper>
                      <AutoCompleteWrapper name="city" options={cityOptions} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormLabelWrapper required>Date of Birth</FormLabelWrapper>
                      <InputFiled
                        type="date"
                        fullWidth
                        placeholder="Date of birth"
                        name="dateOfBirth"
                        size="small"
                        minDate={subtractDate(18)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormLabelWrapper required>Gender</FormLabelWrapper>
                      <RadioGroup row value={values.gender} onChange={(e) => setFieldValue("gender", e.target.value)}>
                        <FormControlLabel value="Male" control={<Radio />} label="Male" />
                        <FormControlLabel value="Female" control={<Radio />} label="Female" />
                      </RadioGroup>
                      <FormHelperText error>{errors.gender}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <button className="btn btn-primary w-100 mt-10" type="submit" disabled={isSubmitting}>
                        {isSubmitting ? "Loading..." : "Save"}
                      </button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
        <PreviewImage open={openPreview} handleClose={handleClosePreview} image={previewImgUrl} />
      </div>
    </div>
  );
}

export default ProfileInfo;
