import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Cart from "../pages/Cart/Cart";
import Header from "../pages/Header/Header";
import Home from "../pages/Landing/Home";
import ProductDetails from "../pages/Landing/ProductDetails";
import Login from "../pages/Onboarding/Login";
import AuthGuard from "./AuthGuard";
import SearchResult from "../pages/SearchResult/SearchResult";
import ROUTES_NAME from "../utility/routesName";
import Signup from "../pages/Onboarding/Signup/Signup";
import Checkout from "../pages/Checkout/Checkout";
import Profile from "../pages/Profile/Profile";
import ProfileInfo from "../pages/Profile/ProfileInfo";
import MyOrder from "../pages/Profile/MyOrder";
import UploadPrescription from "../pages/UploadPrescription/UploadPrescription";
import SelectDelivery from "../pages/SelectDelivery/SelectDelivery";
import OrderDetail from "../pages/Profile/OrderDetail";
import SelectAddress from "../pages/SelectAddress/SelectAddress";
import ReviewOrder from "../pages/ReviewOrder/ReviewOrder";
import {
  ClearLocalStorage,
  getAccessTokenFromLS,
  getGuestUser,
  getPhoneId,
  getRegisteredUser,
  getTabId,
  getUserStatus,
} from "../utility/util";
import { useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import { useDispatch } from "react-redux";
import { ShowNotification } from "../redux/actions/notificationAction";
import NotificationDialog from "../components/NotificationDialog/NotifiactionDialog";
import TermsAndConditions from "../pages/terms-and-conditions";

function Routing() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [openNotificationDialog, setNotificationDialog] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      console.log("current connecttosocet call", !ws.current?.connected);
      connectToSocket();
    }, 1500);

    return () => {
      ws?.current?.on("notification_pharmacy", notificationStartListner);
    };
  }, [navigator.onLine]);
  const ws = useRef<any>(null);
  const connectToSocket = () => {
    ws.current = io(
      `${
        process.env.REACT_APP_SOCKET_URL
      }?authorization=${getAccessTokenFromLS()}&userType=1&phone_id=${getPhoneId()}&tab_id=${getTabId()}`,
      { transports: ["websocket"] }
    );
    console.log(ws.current.connected, "*******connecting to Socket******");
    console.log(getTabId(), "getTabId");
    console.log("current socket out", ws?.current);
    ws.current.on("connect", () => {
      console.log("current socket in", ws?.current);
      console.log(ws.current.connected, "*******connected******");
      // getUserType() == 1
      // 	? dispatch(saveWebsiteWebSocketRefAction(ws?.current))
      // 	: dispatch(saveWebSocketRefAction(ws?.current));
      ws.current.on("notification_pharmacy", notificationStartListner);
    });

    ws.current.on("disconnect", (reason: any) => {
      console.log("current disconnect");
      setTimeout(() => {
        console.log("current disconnect interval");

        if (navigator?.onLine) connectToSocket();
      }, 1000);
      console.log(
        ws.current.connected,
        `*******${reason}****** will attempt to reconnect in 1sec`
      );
    });
    ws.current.on("connect_error", (err: any) => {
      console.log(err.message, "*******connect_error******");
      ws.current.close();
    });
  };
  const notificationStartListner = (res: any) => {
    console.log("res from socket", res);
    setNotificationDialog(true);
    dispatch(ShowNotification(res));
  };
  const closeHandler = () => {
    setNotificationDialog(false);
  };
  // added below code to clear session when SignUP is not completed
  useEffect(() => {
    if (
      getGuestUser() !== "true" &&
      getUserStatus() === "6" &&
      getRegisteredUser() !== "true" &&
      location.pathname !== "/sign-up" &&
      location.pathname !== "/terms-and-condition"
    ) {
      ClearLocalStorage();
    }
  }, [location]);
  return (
    <>
      <Header />
      <main className="without-footer">
        <Routes>
          <Route
            path="/"
            element={
              <AuthGuard>
                <Home />
              </AuthGuard>
            }
          />
          <Route
            path="/home"
            element={
              <AuthGuard>
                <Home />
              </AuthGuard>
            }
          />
          <Route
            path="/login"
            element={
              <AuthGuard>
                <Login />
              </AuthGuard>
            }
          />
          <Route
            path={ROUTES_NAME.SIGN_UP}
            element={
              <AuthGuard>
                <Signup />
              </AuthGuard>
            }
          />
          <Route
            path={ROUTES_NAME.USER}
            element={
              <AuthGuard>
                {/* <Profile /> */}
                {getUserStatus() ? <Profile /> : <Navigate to="/" />}
              </AuthGuard>
            }
          >
            <Route
              path={ROUTES_NAME.PERSONAL_INFO}
              element={
                <AuthGuard>
                  {getUserStatus() ? <ProfileInfo /> : <Navigate to="/" />}
                </AuthGuard>
              }
            />
            <Route
              path={ROUTES_NAME.MY_ORDER}
              element={
                <AuthGuard>
                  {/* <MyOrder /> */}
                  {getUserStatus() ? <MyOrder /> : <Navigate to="/" />}
                </AuthGuard>
              }
            />
            <Route
              path={`${ROUTES_NAME.MY_ORDER_DETAIL}/:orderId`}
              element={
                <AuthGuard>
                  {/* <OrderDetail /> */}
                  {getUserStatus() ? <OrderDetail /> : <Navigate to="/" />}
                </AuthGuard>
              }
            />
          </Route>
          <Route
            path="/cart"
            element={
              <AuthGuard>
                <Cart />
              </AuthGuard>
            }
          />
          <Route
            path="/checkout"
            element={
              <AuthGuard>
                {getUserStatus() ? <Checkout /> : <Navigate to="/" />}
              </AuthGuard>
            }
          />
          <Route
            path={ROUTES_NAME.PRESCRIPTION}
            element={
              <AuthGuard>
                <UploadPrescription />
              </AuthGuard>
            }
          />
          <Route
            path={ROUTES_NAME.DELIVERY}
            element={
              <AuthGuard>
                <SelectDelivery />
              </AuthGuard>
            }
          />
          <Route
            path={ROUTES_NAME.SELECT_ADDRESS}
            element={
              <AuthGuard>
                {/* <SelectAddress /> */}
                {getUserStatus() ? <SelectAddress /> : <Navigate to="/" />}
              </AuthGuard>
            }
          />
          <Route
            path={ROUTES_NAME.REVIEW_ORDER}
            element={
              <AuthGuard>
                {/* <ReviewOrder /> */}
                {getUserStatus() ? <ReviewOrder /> : <Navigate to="/" />}
              </AuthGuard>
            }
          />
          <Route
            path="/product-detail"
            element={
              <AuthGuard>
                <ProductDetails />
              </AuthGuard>
            }
          />
          <Route
            path="/search-result"
            element={
              <AuthGuard>
                <SearchResult />
              </AuthGuard>
            }
          />
          <Route
            path={ROUTES_NAME.TERMS_N_CONDITION}
            element={
              <AuthGuard>
                <TermsAndConditions />
              </AuthGuard>
            }
          />
        </Routes>
      </main>
      <NotificationDialog
        openDialog={openNotificationDialog}
        closeHandler={closeHandler}
      />
      {/* <Footer /> */}
    </>
  );
}

export default Routing;
