import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  Marker,
  useLoadScript,
  InfoBox,
} from "@react-google-maps/api";
import Geocode from "react-geocode";

const mapStyles = {
  height: "275px",
  width: "100%",
};
function MapToReview(props: any) {
  const { coordinates } = props;
  const [libraries] = useState<
    ["places" | "drawing" | "geometry" | "localContext" | "visualization"]
  >(["places"]);
  const REACT_APP_GOOGLE_MAPS_API_KEY =
    "AIzaSyDbc6vdnB49ZMPq1RjxfsbMFrOLihSH5zw";

  Geocode.setApiKey(REACT_APP_GOOGLE_MAPS_API_KEY);

  const { isLoaded } = useLoadScript({
    region: "Ken",
    googleMapsApiKey: REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries, // ,
  });

  const [marker, setMarker] = useState("");

  useEffect(() => {
    if (coordinates) {
      Geocode.fromLatLng(
        coordinates?.lat?.toString(),
        coordinates?.lng?.toString()
      ).then(
        (response) => {
          const { address, city, formattedAddress } = retriveAddress(
            response.results[0].formatted_address,
            response.results[0].address_components
          );
          setMarker(response.results[0].formatted_address);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, [coordinates]);
  const retriveAddress = (formattedAddress: any, addressComponent: any) => {
    const addressArray = formattedAddress?.split(", ") || [];
    const addressLength = addressArray.length;
    let address = "";
    if (addressLength === 3) {
      address = addressArray[0];
    }
    if (addressLength > 3) {
      for (let i = 0; i <= addressLength - 3; i++) {
        if (i < addressLength - 3) address += `${addressArray[i]}, `;

        if (i === addressLength - 3) address += addressArray[i];
      }
    }
    const city = addressComponent?.find(
      (component: any) => component?.types[0] === "locality"
    )?.long_name;
    return { address, city, formattedAddress };
  };
  const options = { closeBoxURL: "", enableEventPropagation: true };
  const onLoad = () => {};
  return (
    <div>
      {isLoaded ? (
        <div style={{ boxShadow: "0px 1px 2px 1px rgba(0,0,0,0.2)" }}>
          <GoogleMap
            mapContainerStyle={mapStyles}
            zoom={13}
            center={{ lat: coordinates?.lat, lng: coordinates?.lng }}
            options={{ gestureHandling: "greedy" }}
          >
            <InfoBox onLoad={onLoad} options={options} position={coordinates}>
              <div className="info-box">{marker}</div>
            </InfoBox>
            {
              <Marker
                key={"h"}
                position={{ lat: coordinates?.lat, lng: coordinates?.lng }}
                animation={google.maps.Animation.BOUNCE}
              />
            }
          </GoogleMap>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default MapToReview;
