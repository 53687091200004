import { combineReducers } from "redux";
import cartReducer from "./cartReducer";
import { CountryDetail } from "./countryDetailReducer";
import { SearchMedicine } from "./searchMedicineReducer";
import {
  profileReducer,
  userOrderReducer,
  userOrderDetailReducer,
} from "../../pages/Profile/reducer";
import { globalLoaderReducer } from "./globalReducer";
import { loginReducer } from "./loginReducer";
import { addressDetailReducer } from "./addressReducer";
import prescriptionReducer from "./prescriptionReducer";
import notificationReducer from "./notificationReducer";
import { TopSellingMedicineReducer } from "./topSellingMedicineReducer";

export const rootReducer = combineReducers({
  SearchMedicine,
  CountryDetail,
  cart: cartReducer,
  globalLoaderReducer,
  profileReducer,
  loginReducer,
  addressDetailReducer,
  prescriptionReducer,
  userOrderReducer,
  userOrderDetailReducer,
  notificationReducer,
  TopSellingMedicineReducer,
});
