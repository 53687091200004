import { DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCountriesDetail } from "../../redux/actions/countryAction";
import {
  getOtpAction,
  loginWithOtpAction,
  showLogin,
} from "../../redux/actions/loginAction";
import {
  globalLoaderEnd,
  globalLoaderStart,
} from "../../redux/reducers/globalReducer";
import showAlert from "../../utility/alert";
import { UserStatus } from "../../utility/staticData";
import {
  setAccessToken,
  setPhoneCodeLS,
  setPhoneNumberLS,
  setRefreshToken,
  setUserCity,
  setUserLogggedIn,
  setUserStatus,
} from "../../utility/util";

import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: "flex-start",
  },
  topPaperScrollBody: {
    verticalAlign: "top",
  },
});

function LoginDialog(props: any) {
  const classes = useStyles();
  const { openDialog, onClose, from } = props;
  const dispatch = useDispatch();
  const apiData = useSelector((state: any) => state.CountryDetail.apiData);
  const isApiLoading = useSelector(
    (state: any) => state.CountryDetail.isApiLoading
  );
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneCode, setPhoneCode] = useState("254");
  const [otpScreen, setOtpScreen] = useState(false);
  const [errorState, setErrorState] = useState({
    cc: false,
    phone: false,
    otp: false,
  });
  const [allowedLength, setAllowedLength] = useState(9);
  const [timerInterval, settimerInterval] = useState<any>(null);
  const [timer, settimer] = useState<any>(45);
  const [errorMessage, setErrorMessage] = useState({
    cc: "",
    phone: "",
    otp: "",
  });
  const [logCode, setlogCode] = useState("");
  const [isLoading, setLoading] = useState(true);
  const handleClose = () => {
    setOtpScreen(false);
    setPhoneNumber("");
    setlogCode("");
    onClose();
    setErrorState((prev) => ({ ...prev, phone: false }));
    setErrorMessage((prev) => ({ ...prev, phone: "" }));
  };

  useEffect(() => {
    // setLoading(true);
    dispatch(getCountriesDetail() as any);
  }, []);

  useEffect(() => {
    setLoading(isApiLoading);
  }, [isApiLoading]);

  const phoneNoCheck = (phoneNumber: any) => {
    const pattern = new RegExp("^0?[0-9]{0,10}$");
    if (phoneNumber[0] === "0") setAllowedLength(10);
    else setAllowedLength(9);
    if (
      phoneNumber[0] === "0" &&
      phoneNumber.substring(1).length === 9 &&
      pattern.test(phoneNumber)
    ) {
      setPhoneNumber(phoneNumber);
    }
    var response = false;
    if (pattern.test(phoneNumber) && phoneNumber.length <= allowedLength) {
      response = true;
    }
    return response;
  };
  const onChangeHandler = (event: any) => {
    const { value } = event.target;
    if (phoneNoCheck(value)) setPhoneNumber(value);
    if (phoneNumber.length !== allowedLength) {
      setErrorState((prev: any) => ({ ...prev, phone: false }));
      setErrorMessage((prev: any) => ({ ...prev, phone: "" }));
    }
    if (phoneNumber.length === 0) setAllowedLength(9);
  };
  function apiHandler(event: any) {
    const countryObj = apiData.list_of_countries.find(
      (obj: any) => obj.phone_country_code === phoneCode
    );
    if (event === "Generate OTP" && phoneNumber.length === 0) {
      setErrorState((prev: any) => ({ ...prev, phone: true }));
      setErrorMessage((prev: any) => ({
        ...prev,
        phone: "Please enter phone number",
      }));
      return false;
    }
    if (
      event === "Generate OTP" &&
      phoneCode &&
      phoneNumber[1] === "0" &&
      allowedLength === 10
    ) {
      setErrorState((prev: any) => ({ ...prev, phone: true }));
      setErrorMessage((prev: any) => ({
        ...prev,
        phone: "Invalid Phone number",
      }));
      return false;
    }
    if (event === "Generate OTP" && phoneCode && phoneNumber) {
      if (phoneNumber.length !== allowedLength) {
        setErrorState((prev: any) => ({ ...prev, phone: true }));
        setErrorMessage((prev: any) => ({
          ...prev,
          phone: `Phone Number should contain ${allowedLength} characters`,
        }));
        return false;
      }
      dispatch(globalLoaderStart());
      dispatch(
        getOtpAction(
          allowedLength === 10 ? phoneNumber.substring(1) : phoneNumber,
          phoneCode,
          countryObj?.country_code
        ) as any
      )
        .then((res: any) => {
          dispatch(globalLoaderEnd());
          setOtpScreen(true);
        })
        .catch(() => {
          dispatch(globalLoaderEnd());
        });
    }
    if (event === "Submit" && /^\d{4}$/.test(logCode)) {
      if (logCode.length !== 4) return false;
      console.log(logCode);
      dispatch(globalLoaderStart());
      dispatch(
        loginWithOtpAction(
          logCode,
          phoneCode,
          allowedLength === 10 ? phoneNumber.substring(1) : phoneNumber,
          countryObj.country_code
        ) as any
      )
        .then((res: any) => {
          dispatch(globalLoaderEnd());
          console.log(res);

          if (UserStatus.IN_REGISTRATION_PROCESS === res?.user_status) {
            showAlert(2, "User is not registered!");
            onClose();
            return false;
          }
          if (UserStatus.ACTIVE === res?.user_status) {
            setAccessToken(res.access_token);
            setRefreshToken(res.refresh_token);
            setUserStatus(res.user_status);
            setPhoneNumberLS(
              allowedLength === 10 ? phoneNumber.substring(1) : phoneNumber
            );
            setPhoneCodeLS(phoneCode);
            setUserCity(res.city_id);
            setUserLogggedIn();
            dispatch(showLogin());
            if (from === "prescription") navigate("/select-address");
            else navigate("/checkout");
            handleClose();
          }
        })
        .catch((error: any) => {
          dispatch(globalLoaderEnd());
          if (error && error.response) {
            showAlert(2, error.response.data.messages[0]);
            setErrorState((prev: any) => ({ ...prev, otp: true }));
            return false;
          }
        });
    }
  }

  const resendOTPHandler = () => {
    clearTimer();

    const countryObj = apiData.list_of_countries.find(
      (obj: any) => obj.phone_country_code === phoneCode
    );
    dispatch(
      getOtpAction(phoneNumber, phoneCode, countryObj?.country_code) as any
    )
      .then((res: any) => {
        showAlert(1, "A Code has been sent to you via SMS");
        startTimer();
      })
      .catch((err: any) => {
        showAlert(2, err?.response?.data?.messages[0]);
      });
  };

  const startTimer = () => {
    settimerInterval(null);
    settimer(45);
    settimerInterval(
      setInterval(() => {
        settimer((time: any) => {
          return time - 1;
        });
      }, 1000)
    );
  };

  const clearTimer = () => {
    clearInterval(timerInterval);
    settimer(45);
  };
  useEffect(() => {
    if (otpScreen) startTimer();

    // return () => {
    if (!otpScreen) clearTimer();
    // };
  }, [otpScreen]);
  return (
    <Dialog open={openDialog} fullWidth onClose={handleClose}
    classes={{
      scrollPaper: classes.topScrollPaper,
      paperScrollBody: classes.topPaperScrollBody,
    }}>
      <h1 className="bold ml-20 mt-20">Sign In</h1>
      <DialogContent>
        <div>
          {!otpScreen && (
            <h3 className="subtext mb-20">Using your mobile number</h3>
          )}

          <Grid container spacing={3}>
            {!otpScreen && (
              <Grid item xs={4}>
                <h4 className="subtext mb-5">Code</h4>
                <TextField
                  name="code"
                  onChange={onChangeHandler}
                  variant="outlined"
                  placeholder=""
                  value={`+${phoneCode}`}
                  disabled
                />
                {/* <Select
                  // onChange={handleChange}
                  style={{ width: "100%" }}
                  displayEmpty
                  autoWidth
                  defaultValue={phoneCode}
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={(event) => {
                    console.log(event?.target.value);
                  }}
                  readOnly
                >
                  {countryList?.map((country: any) => (
                    <MenuItem
                      key={country.id}
                      value={country.phone_country_code}
                    >
                      {`+${country?.phone_country_code}`}
                    </MenuItem>
                  ))}
                </Select> */}
              </Grid>
            )}
            {!otpScreen && (
              <Grid item xs={8}>
                <h4 className="subtext mb-5">Mobile Number</h4>
                <TextField
                  name="mobile"
                  onChange={onChangeHandler}
                  variant="outlined"
                  placeholder=""
                  value={phoneNumber}
                  error={errorState.phone}
                  helperText={errorMessage.phone}
                />
              </Grid>
            )}
            {otpScreen && (
              <Grid item xs={12}>
                <h3 className="subtext mb-20">{`Please enter the 4 digit code sent to you via SMS on +${phoneCode} ${
                  allowedLength === 10 ? phoneNumber.substring(1) : phoneNumber
                }`}</h3>
                <OtpInput
                  value={logCode}
                  onChange={(value: any) => {
                    setlogCode(value);
                  }}
                  className="single-otp-input-dialog"
                  numInputs={4}
                  isInputNum={true}
                  // separator={<span>-</span>}
                  hasErrored={errorState.otp}
                />
              </Grid>
            )}
          </Grid>
          {otpScreen && (
            <div className="space-between mt-20">
              <a
                onClick={() => {
                  setOtpScreen(false);
                  setlogCode("");
                }}
                className="semi-bold color-primary pointer hover-underline"
              >
                Go Back
              </a>

              {timer <= 0 ? (
                <a
                  className="semi-bold pointer hover-underline"
                  onClick={resendOTPHandler}
                >
                  Resend
                </a>
              ) : (
                <span>Resend OTP in {timer} sec</span>
              )}
            </div>
          )}
          <button
            className="btn btn-primary w-100 mb-30 mt-30"
            onClick={() => {
              apiHandler(otpScreen ? "Submit" : "Generate OTP");
            }}
          >
            {otpScreen ? "Submit" : "Generate OTP"}
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default LoginDialog;
